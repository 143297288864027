import React, { useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { postChapter } from "../../services/chaptersService";
import "./ChapterCreate.css";
import { defineMessages, FormattedMessage } from 'react-intl';

const ChapterCreate = (props) => {
    const { parentChapters } = props.location.state;
    const [name, setName] = useState("Name");
    const [sectionNumber, setSectionNumber] = useState("Section number");
    const user = useSelector((state) => state.user);
    const guid = useSelector(
        (state) => state.router.location.pathname.split("/")[2]
    );
    const [parentChapterId, setParentChapterId] = useState(null);
    const history = useHistory();

    const message = defineMessages({
        a: {
            id: "chapter.none",
            defaultMessage: "None"
        }
    });

    let handleNameChange = (event) => {
        setName(event.target.value);
    };

    let handleSectionNumberChange = (event) => {
        setSectionNumber(event.target.value);
    };

    let handleParentChapterIdChange = (event) => {
        setParentChapterId(event.target.value);
    };

    let handleSubmit = () => {
        const state = {
            name: name,
            sectionNumber: sectionNumber,
            projectId: guid ? guid : props.project,
        };
        if (parentChapterId) {
            state.parentChapterId = parentChapterId;
        }
        postChapter(user, JSON.stringify(state)).then((res) => {
            if (props.history) {
                history.push(`/project/${guid}`);
            } else {
                props.handleSetNewChapter(res.id);
                props.handleCloseModal();
            }
        });
    };

    return (
        <div className={props.history ? "chapterCreate-container" : ""}>
            <div>
                <Form className={props.history ? "input" : "input-small"}>
                    <FormGroup>
                        <Label for="name"><FormattedMessage id="chapter.name" defaultMessage="Name:" /></Label>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder=""
                            value={name}
                            onChange={handleNameChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="sectionNumber"><FormattedMessage id="chapter.sectionnumber" defaultMessage="Section number" /></Label>
                        <Input
                            type="text"
                            name="sectionNumber"
                            id="sectionNumber"
                            placeholder=""
                            value={sectionNumber}
                            onChange={handleSectionNumberChange}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="parentChapterId">
                            <FormattedMessage id="chapter.parentsection" defaultMessage="Choose parent section:" />

                        </Label>
                        <Input
                            type="select"
                            name="parentChapterId"
                            id="parentChapterId"
                            onChange={handleParentChapterIdChange}
                        >
                            <FormattedMessage id={message.a.id} message={message.a} >
                                {(message) => <option value="null">{message}</option>}
                            </FormattedMessage>

                            {parentChapters.map((chapter) => {
                                if (chapter.depth < 5)
                                    return (
                                        <option
                                            key={chapter.id}
                                            value={chapter.id}
                                        >
                                            {" "}
                                            {chapter.name}{" "}
                                        </option>
                                    );
                                return null;
                            })}
                        </Input>
                    </FormGroup>
                </Form>
            </div>
            <div className="buttons">
                <Button
                    onClick={
                        props.history
                            ? () => history.push(`/project/${guid}`)
                            : props.handleCloseModal
                    }
                >
                    <FormattedMessage id="chapter.back" defaultMessage="Back" />

                </Button>
                <Button onClick={handleSubmit}> <FormattedMessage id="chapter.submit" defaultMessage="Submit" /></Button>
            </div>
        </div>
    );
};
export default ChapterCreate;
