import React, { useState } from "react";
import "./Explanation.css";
import { Collapse } from "reactstrap";
import { ReactComponent as Up } from "./Up.svg";
import { ReactComponent as Down } from "./Down.svg";

const Explanation = (props) => {
    const [show, setShow] = useState(true);
    console.log(props)
    return (
        <div

            className={`explanation-container-${props.explanationsContainer} ${props.display ? "" : "hidden"
                } ${show ? "open" : "closed"}`}
        >
            <div
                className="list-toggle noselect"
                onClick={() => setShow(!show)}
            >
                <p id="explanation-title-${props.explanationsContainer}">{props.name}</p> {show ? <Up /> : <Down />}
            </div>
            <Collapse isOpen={show}>
                <div
                    className="list-input-fields-container border-bottom"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                ></div>
            </Collapse>
        </div>
    );
};

export default Explanation;
