export function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
    };

    return fetch(`users/authenticate`, requestOptions)
        .then(handleResponse)
        .then((user) => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in session storage to keep user logged in between page refreshes
                sessionStorage.setItem("user", JSON.stringify(user));
                sessionStorage.setItem("loggedIn", JSON.stringify(true));
            }
            return user;
        });
}

export function logout() {
    // remove user from session storage to log user out
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("activeProject");
    sessionStorage.setItem("loggedIn", JSON.stringify(false));
}

export function register(user) {
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(user),
    };

    return fetch(`users/register`, requestOptions).then(handleResponse);
}

export function registerCompany(data) {
    console.log(data);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
    };

    return fetch(`users/register/company`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data || response.ok;
    });
}

export default {
    login,
    logout,
    register,
    registerCompany,
};
