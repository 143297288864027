import React, { useEffect, useState } from "react";
import "./Home.css";
import { useSelector } from "react-redux";
import { getInfo, getEvents, getEventCount } from "../../services/dashboardService";
import { EventsList } from "../../components/EventsList/EventsList";
import InfoIcon from "../../components/Tooltip/info.svg";
import Modal from "../EurocodeModal/EurocodeModal";
import CompanyUserItem from "../EditCompany/CompanyUserItem";
import { FormattedMessage } from 'react-intl';


const Home = () => {
    const user = useSelector(state => state.user);
    const [dashboardInfo, setDashboardInfo] = useState({});
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [events, setEvents] = useState([]);
    const [showInfoIcon, setShowInfoIcon] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getInfo(user).then(res => setDashboardInfo(res));
        getEventCount(user).then(c => setCount(c));
        getEvents(user, page).then(e => setEvents(e));
        const interval = setInterval(() => {
            getInfo(user).then(res => setDashboardInfo(res));
            getEventCount(user).then(c => {
                if (c > count) {
                    setCount(c);
                    getEvents(user, page).then(e => setEvents(e));
                }
            });
        }, 30000);
        return () => clearInterval(interval);
    }, [user, page, count]);

    const loadMore = () => {
        setPage(page + 1);
    };

    return (
        <div className="home-container">
            <div className="dashboard-cards">
                <div className="dashboard-card">
                    <h1>{dashboardInfo.projects ? dashboardInfo.projects : 0}</h1>
                    <h4>projects</h4>
                </div>
                <div className="dashboard-card">
                    <h1>{dashboardInfo.sections ? dashboardInfo.sections : 0}</h1>
                    <h4>sections</h4>
                </div>
                <div className="dashboard-card">
                    <h1>{dashboardInfo.calculations ? dashboardInfo.calculations : 0}</h1>
                    <h4>calculations</h4>
                </div>
                <div
                    className="dashboard-card"
                    onMouseOver={() => setShowInfoIcon(true)}
                    onMouseLeave={() => setShowInfoIcon(false)}
                >
                    <h1>{dashboardInfo.employeesOnline ? dashboardInfo.employeesOnline.length : 0}</h1>
                    <h4>employees online</h4>
                    {showInfoIcon &&
                        dashboardInfo.employeesOnline &&
                        dashboardInfo.employeesOnline.length > 0 && (
                            <img
                                id="dashboard-info-icon"
                                width="25px"
                                src={InfoIcon}
                                alt="Info icon"
                                onClick={() => setModalOpen(!modalOpen)}
                            />
                        )}
                </div>
            </div>
            {user.role !== "Admin" && (
                <EventsList events={events} count={count} loadMore={loadMore} page={page} />
            )}
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(!modalOpen)}
                modalTitle={<FormattedMessage id="home.employeesonline" defaultMessage="Employees online" />}
            >
                {dashboardInfo.employeesOnline &&
                    dashboardInfo.employeesOnline.map(employee => (
                        <div key={employee.guid}>
                            <CompanyUserItem {...employee} />
                            <br />
                        </div>
                    ))}
            </Modal>
        </div>
    );
};

export default Home;
