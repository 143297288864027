import React from "react";
import { useSelector } from "react-redux";
import "./UserAvatar.css";
import avatar from "./user-image.jpg";
import { Link } from "react-router-dom";
import Image from "../EurocodeImage/Image";

function UserAvatar(props) {
    const user = useSelector((state) => state.user);

    return (
        <Link to="/user" onClick={props.onClick}>
            <div className="user-avatar-container">
                <Image
                    id="user-avatar"
                    placeholder={avatar}
                    imageId={user.guid}
                />
                <h5 className="hide_on_collapse">
                    {user.firstName} {user.lastName}
                </h5>
                <p className="hide_on_collapse">{user.position}</p>
            </div>
        </Link>
    );
}

export default UserAvatar;
