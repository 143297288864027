import React from "react";
import "./Tooltip.css";

const Tooltip = ({ text, placement }) => {
    const className = "tooltiptext tooltiptext-" + placement;

    return (
        <div
            className={className}
            style={text && text.length > 30 ? { width: "170px" } : null}
        >
            {text}
        </div>
    );
};

export default Tooltip;
