import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { getProjects } from "../../services/projectsService";
import { getCompanies, getCompanyProjects } from "../../services/companyService";
import ProjectAvatar from "../../components/ProjectAvatar/ProjectAvatar";
import { ReactComponent as IAddProject } from "./Icons/Ikona_add project.svg";
import "./Projects.css";
import Loading from "../../components/Loading/Loading";
import { defineMessage, FormattedMessage } from 'react-intl';

function Projects() {
    const [projectsData, setProjectsData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const [chosenCompany, setChosenCompany] = useState(null);
    const user = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const messages = defineMessage({
        a: {
            id: "projects.selectcompany",
            defaultMessage: "Select company"
        }

    });
    let fetchProjects = () => {
        getProjects(user).then(p => {
            setProjectsData(p);
        });
    };

    useEffect(() => {
        if (user.role === "Admin") {
            setLoading(true);
            getCompanies(user).then(p => {
                setCompanyData(p);
                setLoading(false);
            });
        }
        if (user.role !== "Admin") {
            setLoading(true);
            getProjects(user).then(p => {
                setProjectsData(p);
                setLoading(false);
            });
        }
        if (chosenCompany) {
            setLoading(true);
            getCompanyProjects(user, chosenCompany).then(p => {
                setProjectsData(p);
                setLoading(false);
            });
        }
    }, [user, chosenCompany]);

    return (
        <div className="projects-container">
            <div className="projects-container-header">
                <br />
                {user.role === "CompanyAdmin" ? (
                    <Link to="/projectNew">
                        <Button className="addProject-button">
                            <IAddProject className="icon" />
                            <FormattedMessage id="projects.addproject" defaultMessage="Add project" />

                        </Button>
                    </Link>
                ) : user.role === "Admin" ? (
                    <div>
                        <Label> <FormattedMessage id="projects.selectcompany12" defaultMessage="Choose company:" /></Label>
                        <Input
                            type="select"
                            name="company"
                            id="company"
                            onChange={e => {
                                setChosenCompany(e.target.value);
                            }}
                        >

                            <FormattedMessage id={messages.a.id} message={messages.a} >
                                {(message) => <option value="none">{message}</option>}
                            </FormattedMessage>



                            {companyData.map(item => (
                                <option {...item} key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </Input>
                    </div>
                ) : (
                    ""
                )}
            </div>
            <div className="flex-container">
                {loading ? (
                    <Loading />
                ) : (
                    projectsData.map(item => (
                        <ProjectAvatar
                            {...item}
                            name={item.name}
                            key={item.id}
                            desc={item.description}
                            id={item.id}
                            refresh={fetchProjects}
                            projectId={item.id}
                        />
                    ))
                )}
            </div>
        </div>
    );
}

export default Projects;
