import names from "./SidebarTranslate";

const hierarchy = [
    {

        text: names.project,
        icon: "Projects",
        link: "/Projects"
    },
    {
        text: names.generaldata,
        icon: "GeneralData",
        children: [
            {
                text: names.mainpage,
                link: "/MainPage",
                icon: "MainPage"
            },
            {
                text: names.Audit,
                link: "/Audit",
                icon: "Audit"
            },
            {
                text: names.Designersassociates,
                link: "/DesignersAssociates",
                icon: "DesignersAssociates"
            },
            {
                text: names.Listofbooks,
                link: "/ListOfBooks",
                icon: "ListOfBooks"
            },
            {
                text: names.Bookcontent,
                link: "/BookContent",
                icon: "MapContent"
            },
            {
                text: names.Designersstatement,
                link: "/DesignerStatement",
                icon: "DesignerStatement"
            },
            {
                text: names.Maindesignersstatement,
                link: "/MainDesignerStatement",
                icon: "MainDesignerStatement"
            },
            {
                text: names.Attachments,
                link: "/Attachments",
                icon: "Attachments"
            }
        ]

    },
    {
        text: names.loadanalysis,
        icon: "LoadAnalysis",
        children: [
            {
                text: names.DensitiesSelfweight,
                link: "/DensitiesSelfWeight",
                icon: "DensitiesSelfWeight"
            },
            {
                text: names.Loadsforbuildings,
                link: "/LoadsForBuildings",
                icon: "LoadsForBuildings"
            },
            {
                text: names.Fireactions,
                link: "/FireActions",
                icon: "FireActions"
            },
            {
                text: names.Snowloads,
                link: "/SnowLoads",
                icon: "SnowLoads"
            },
            {
                text: names.Windactions,
                link: "/WindActions",
                icon: "WindActions"
            },
            {
                text: names.Thermalactions,
                link: "/ThermalActions",
                icon: "ThermalActions"
            },
            {
                text: names.Actionsduringexecution,
                link: "/ActionsDuringExecution",
                icon: "ActionsDuringExecution"
            },
            {
                text: names.Extraordinaryactions,
                link: "/ExtraordinaryActions",
                icon: "ExtraordinaryActions"
            },
            {
                text: names.Actionsinducedbycranes,
                link: "/ActionsInducedByCranes",
                icon: "ActionsInducedByCranes"
            },
            {
                text: names.Earthloads,
                link: "/EarthLoads",
                icon: "EarthLoads"
            },
            {
                text: names.Seismicactions,
                link: "/SeismicActions",
                icon: "SeismicActions"
            }
        ]

    },
    {
        text: names.qualitycontrol,
        icon: "QualityControl",
        children: [
            {
                text: names.Executionofconcretestructures,
                link: "/ExecutionOfConcreteStructures",
                icon: "ExecutionOfConcreteStructures"
            },
            {
                text: names.Concrete,
                link: "/Concrete",
                icon: "Concrete"
            },
            {
                text: names.Executionofsteel,
                link: "/ExecutionOfSteelAndAluminumStructures",
                icon: "ExecutionOfSteelAndAluminumStructures"
            },
            {
                text: names.Designconsiderations,
                link: "/DesignConsiderations",
                icon: "DesignConsiderations"
            },
            {
                text: names.Listofnorms,
                link: "/ListOfNorms",
                icon: "ListOfNorms"
            }
        ]

    },
    {
        text: names.structuralanalysis,
        icon: "StructuralAnalysis",
        children: [
            {
                text: names.designbasis,
                link: "/DesignBasis",
                icon: "DesignBasis"
            },
            {
                text: names.geometry,
                link: "/Geometry",
                icon: "Geometry"
            },
            {
                text: names.loadcombinations,
                link: "/LoadCombinations",
                icon: "LoadCombinations"
            },
            {
                text: names.material,
                link: "/Material",
                icon: "Material"
            },
            {
                text: names.staticalcalculation,
                link: "/StaticalCalculation",
                icon: "StaticalCalculation"
            }
        ]
    },
    {
        text: names.structuraldesign,
        icon: "StructuralDesign",
        children: [
            {
                text: names.Detaildesign,
                link: "/DetailDesign",
                icon: "DetailDesign"
            },
            {
                text: names.Servicelimitstate,
                link: "/ServiceLimitState",
                icon: "ServiceLimitState"
            },
            {
                text: names.Ultimatelimitstate,
                link: "/UltimateLimitState",
                icon: "UltimateLimitState"
            }
        ]

    },
    {
        text: names.technicaldescription,
        icon: "TechnicalDescription",
        children: [
            {
                text: names.Beams,
                link: "/Beams",
                icon: "Beams"
            },
            {
                text: names.Floorplate,
                link: "/FloorPlate",
                icon: "FloorPlate"
            },
            {
                text: names.Foundations,
                link: "/Foundations",
                icon: "Foundations"
            },
            {
                text: names.General,
                link: "/General",
                icon: "General"
            },
            {
                text: names.Roof,
                link: "/Roof",
                icon: "Roof"
            },
            {
                text: names.Walls,
                link: "/Walls",
                icon: "Walls"
            }
        ]

    }
];

export default hierarchy;
