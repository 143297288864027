import React from "react";
import { ReactComponent as Back } from "./Icons/Back.svg";
import { ReactComponent as Delete } from "./Icons/Delete.svg";
import { ReactComponent as Edit } from "./Icons/Edit.svg";
import { ReactComponent as MoveUp } from "./Icons/Move Up.svg";
import { ReactComponent as MoveDown } from "./Icons/Move Down.svg";
import "./ActionsBar.css";

function ActionsBar(props) {
    return (
        <div className={`${props.active ? "active " : ""} actions-container`}>
            {props.onMoveUp ? (
                <div
                    title="Move Up"
                    className="action-container"
                    onClick={props.onMoveUp}
                >
                    <MoveUp />
                </div>
            ) : null}
            {props.onMoveDown ? (
                <div
                    title="Move Down"
                    className="action-container"
                    onClick={props.onMoveDown}
                >
                    <MoveDown />
                </div>
            ) : null}
            {props.onBack ? (
                <div
                    title="Back"
                    className="action-container"
                    onClick={props.onBack}
                >
                    <Back />
                </div>
            ) : null}
            {props.onEdit ? (
                <div
                    title="Edit"
                    className="action-container"
                    onClick={props.onEdit}
                >
                    <Edit />
                </div>
            ) : null}
            {props.onDelete ? (
                <div
                    title="Delete"
                    className="action-container"
                    onClick={props.onDelete}
                >
                    <Delete />
                </div>
            ) : null}
        </div>
    );
}
export default ActionsBar;
