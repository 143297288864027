import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import {
    getCountries,
    postProject,
    addProjectUsers,
} from "../../services/projectsService";
import { getCompany, getCompanyUsers } from "../../services/companyService";
import "./Projects.css";
import { FormattedMessage } from 'react-intl';

function NewProject() {
    const user = useSelector((state) => state.user);
    const [projectName, setProjectName] = useState("Project name");
    const [projectDescription, setProjectDescription] = useState(
        "Project description"
    );
    const [country, setCountry] = useState("");
    const [countriesData, setCountriesData] = useState({});
    const [employees, setEmployees] = useState([]);
    const [employeesToAdd, setEmployeesToAdd] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getCompany(user, user.companyId).then((res) => setCountry(res.country));
        getCountries().then((countries) => setCountriesData(countries));
        getCompanyUsers(user, user.companyId).then((emps) => {
            emps = emps.filter((e) => e.guid !== user.guid);
            setEmployees(emps);
        });
    }, [user]);

    let handleProjectNameChange = (event) => {
        setProjectName(event.target.value);
    };

    let handleProjectDescriptionChange = (event) => {
        setProjectDescription(event.target.value);
    };

    let handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    let handleSubmit = () => {
        const state = {
            name: projectName,
            description: projectDescription,
            countryCode: country,
        };
        postProject(user, JSON.stringify(state)).then((res) => {
            if (employeesToAdd.length > 0) {
                addProjectUsers(user, res.id, employeesToAdd).then(() => {
                    history.push({
                        pathname: "/projects",
                    });
                });
            } else {
                history.push({
                    pathname: "/projects",
                });
            }
        });
    };

    let handleEmployeesToAdd = (employee) => {
        if (employeesToAdd.includes(employee)) {
            setEmployeesToAdd(employeesToAdd.filter((e) => e !== employee));
        } else {
            setEmployeesToAdd([...employeesToAdd, employee]);
        }
    };

    return (
        <div className="newProject-container">
            <h3><FormattedMessage id="newproject.createnewproject" defaultMessage ="Create new project" /></h3>
            <br />
            <Form className="input">
                <FormGroup>
                    <Label for="projectName"><FormattedMessage id="newproject.projectname" defaultMessage="Project name" /></Label>
                    <Input
                        type="text"
                        name="projectName"
                        id="projectName"
                        value={projectName}
                        onChange={handleProjectNameChange}
                        placeholder=""
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="projectDescription"><FormattedMessage id="newproject.projectdescription" defaultMessage="Project description" /></Label>
                    <Input
                        className="projectDescription-container"
                        type="textarea"
                        name="projectDescription"
                        value={projectDescription}
                        onChange={handleProjectDescriptionChange}
                        id="projectDescription"
                        placeholder=""
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="country"><FormattedMessage id="newproject.country" defaultMessage="Country" /></Label>
                    <Input
                        type="select"
                        name="country"
                        id="country"
                        value={country}
                        onChange={handleCountryChange}
                    >
                        {Object.entries(countriesData).map((item) => (
                            <option key={item[0]} value={item[0]}>
                                {item[1]}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="employees"><FormattedMessage id="newproject.employees" defaultMessage="Add employees to project" /></Label>
                    <FormGroup check>
                        <Input
                            type="checkbox"
                            name="select-all"
                            checked={employeesToAdd === employees}
                            readOnly={true}
                            onClick={() => setEmployeesToAdd(employees)}
                        />
                        <p><FormattedMessage id="newproject.selectall" defaultMessage="Select all" /></p>
                    </FormGroup>
                    {employees.map((employee) => (
                        <FormGroup key={employee.guid} check>
                            <Input
                                type="checkbox"
                                name={employee.guid}
                                id={employee.guid}
                                checked={employeesToAdd.includes(employee)}
                                readOnly={true}
                                onClick={() => handleEmployeesToAdd(employee)}
                            />{" "}
                            <p style={{ marginBottom: "10px" }}>
                                {employee.firstName} {employee.lastName}
                            </p>
                        </FormGroup>
                    ))}
                </FormGroup>
            </Form>
            <div>
                <Button
                    style={{ marginRight: "15px", width: "140px" }}
                    onClick={handleSubmit}
                >
                    <FormattedMessage id="newproject.create" defaultMessage="Create" />
                </Button>
                <Link to="/projects">
                    <Button style={{ width: "140px" }}><FormattedMessage id="newproject.back" defaultMessage="Back" /></Button>
                </Link>
            </div>
        </div>
    );
}

export default NewProject;
