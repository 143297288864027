import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RedirectIfLoggedIn from "../../components/RedirectIfLoggedIn";
import {
    loginRequest,
    clearLoginError,
} from "../../actions/authentificationActions";
import { FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import "./Login.css";
import { ReactComponent as LockIcon } from "./lock.svg";
import { ReactComponent as LoginLogo } from "./loginLogo.svg";
import { EurocodeButton } from "../../components/EurocodeButton/EurocodeButton";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.keyPressed = this.keyPressed.bind(this);
    }

    handleChange(field, event) {
        let change = {};
        change[field] = event.target.value;
        this.setState(change);
    }

    componentDidMount() {
        this.props.actions.clearLoginError();
        this.setState({ loading: false });
        window.addEventListener("keypress", this.keyPressed);
    }

    componentDidUpdate(prevProps) {
        if (this.props.error !== prevProps.error) {
            this.setState({ loading: false });
        }
    }

    keyPressed(e) {
        if (e.key === "Enter") this.handleLogin();
    }

    handleLogin() {
        this.setState({ loading: true });
        this.props.actions.clearLoginError();
        this.props.actions.loginRequest.bind(null, this.state)();
    }

    render() {
        return (
            <div className="container-center">
                <RedirectIfLoggedIn />
                <div id="login-form-container">
                    <div id="title-container">
                        <h5>EUROCODE</h5>
                        <h6>HUB</h6>
                    </div>
                    <div className="logo-container">
                        <LoginLogo />
                    </div>
                    <hr />
                    <FormGroup>
                        <div className="right">
                            <p>
                                <LockIcon /> Secured connection
                            </p>
                        </div>
                        <input
                            type="email"
                            name="username"
                            placeholder="Email"
                            value={this.state.username}
                            onChange={this.handleChange.bind(null, "username")}
                            onKeyDown={this.keyPressed}
                        />
                    </FormGroup>
                    <FormGroup>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={this.handleChange.bind(null, "password")}
                            onKeyDown={this.keyPressed}
                        />
                    </FormGroup>
                    {this.props.error && (
                        <p className="login-error">{this.props.error}</p>
                    )}
                    <EurocodeButton
                        className="login-button"
                        isloading={this.state.loading.toString()}
                        type="submit"
                        onClick={this.handleLogin}
                        disabled={this.state.loading}
                    >
                        Log in
                    </EurocodeButton>
                    <p>
                        Or <Link to={"/Register"}>register now!</Link>
                    </p>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { error } = state.login;
    return { error };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ loginRequest, clearLoginError }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
