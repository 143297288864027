import React, { useState, useEffect, Fragment } from "react";
import { Input } from "reactstrap";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";
import '../StructureTerrainField/StructureTerrainField.css';
import { defineMessages, FormattedMessage } from 'react-intl';



export const StructureTerrainField = (props) => {
    const [values, setValues] = useState({
        Building_height: 0,
        Building_width: 0,
        Building_depth: 0,
        Structure_n: 0,
        LargeAndConsiderablyHigherNeighbouringStructures: "no",
        CloselySpacedBuildingsAndObstacles: "no",
        HEffectiveHeightOfHill: 0,
        LdDownwindSlopeLenght: 0,
        luUpwindSlopeLenght: 0,
        XHorizontalDistanceFromCrest: 0,
        Have: 0,
        XDistance: 0,
        Hhigh: 0,
        Dlarge: 0,
        co: "c",
        type_of_roof_on_building: 0,
        Monopitch_alpha: 0,
        Duopitch_alpha: 0,


    });


    const [closly, setClosly] = useState(false);
    const [large, setLarge] = useState(false);
    const [IsCo, setIsCo] = useState(false);
    const [IsMonoRoof, setIsMonoRoof] = useState(false);
    const [IsDuoRoof, setIsDuoRoof] = useState(false);

    const TypeOfBuildingMessage = defineMessages({
        a1: {
            id: "strelement.flat1",
            defaultMessage: "Flat or hipped roof"
        },
        b1: {
            id: "strelement.monopitch1",
            defaultMessage: "Monopitch roof"
        },
        c1: {
            id: "strelement.duopitch1",
            defaultMessage: "Duopitch roof"
        }
    });
    const typeOfTerrain = defineMessages({
        a: {
            id: "strelement.a",
            defaultMessage: "a - Cliffs and escarpments"
        },
        b: {
            id: "strelement.b",
            defaultMessage: "b - Hills and ridges"
        },
        c: {
            id: "strelement.c",
            defaultMessage: "c - Structure is not positioned in cliffs, escarpments, hills or ridges"
        }
    });

    useEffect(() => {
        if (props.startValue) {
            setValues(parseInputField(props.startValue));
            console.log(props.startValue)
            if (props.startValue.type_of_roof_on_building == "2") {
                setIsDuoRoof(true);
            }
            if (props.startValue.type_of_roof_on_building == "1") {
                setIsMonoRoof(true);
            }
            if (props.startValue.LargeAndConsiderablyHigherNeighbouringStructures == "yes") {
                setLarge(true);
            }
            if (props.startValue.CloselySpacedBuildingsAndObstacles == "yes") {
                setClosly(true);
            }
            if (props.startValue.co == "a" || props.startValue.co == "b") {
                setIsCo(true);
            }
        }
    }, [props.startValue]);

    //useEffect(() => {
    //    if (values.CloselySpacedBuildingsAndObstacles) {
    //        setClosly(closly)
    //    }
    //}, []);

    //useEffect(() => {
    //    if (values.LargeAndConsiderablyHigherNeighbouringStructures) {
    //        setLarge(large)
    //    }
    //}, []);
    const handleChangeLarge = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (value == "yes") setLarge(true);
        else setLarge(false);
        setValues({ ...values, LargeAndConsiderablyHigherNeighbouringStructures: value })
    }

    const handleChangeClosly = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (value == "yes") setClosly(true);
        else setClosly(false);
        setValues({ ...values, CloselySpacedBuildingsAndObstacles: value })
    }
    const handleChangeCo = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (value == "a" || value == "b") setIsCo(true);
        else setIsCo(false);
        setValues({ ...values, co: value });
    }
    const handleChangeTypeOfRoof = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (value == "1") setIsMonoRoof(true);
        else setIsMonoRoof(false);
        if (value == "2") {
            setIsDuoRoof(true);
        }
        else {
            setIsDuoRoof(false);
        }
        setValues({ ...values, type_of_roof_on_building: value });
    }

    const handleChangeSetValues = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (name == "Building_height") setValues({ ...values, Building_height: value })
        if (name == "Building_width") setValues({ ...values, Building_width: value })
        if (name == "Building_depth") setValues({ ...values, Building_depth: value })
        if (name == "Structure_n") setValues({ ...values, Structure_n: value })
        if (name == "HEffectiveHeightOfHill") setValues({ ...values, HEffectiveHeightOfHill: value })
        if (name == "LdDownwindSlopeLenght") setValues({ ...values, LdDownwindSlopeLenght: value })
        if (name == "luUpwindSlopeLenght") setValues({ ...values, luUpwindSlopeLenght: value })
        if (name == "XHorizontalDistanceFromCrest") setValues({ ...values, XHorizontalDistanceFromCrest: value })
        if (name == "Have") setValues({ ...values, Have: value })
        if (name == "XDistance") setValues({ ...values, XDistance: value })
        if (name == "Hhigh") setValues({ ...values, Hhigh: value })
        if (name == "Dlarge") setValues({ ...values, Dlarge: value })
        if (name == "Monopitch_alpha") setValues({ ...values, Monopitch_alpha: value })
        if (name == "Duopitch_alpha") setValues({ ...values, Duopitch_alpha: value })

    }

    return (

        <Fragment>

            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(values)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />
            <label><FormattedMessage id="strelement.height" defaultMessage="h - Building height (m)" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 1 : 0}
                type="number"
                placeholder=""
                name="Building_height"
                value={values.Building_height}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={handleChangeSetValues}


            />
            <label><FormattedMessage id="strelement.width" defaultMessage="b - Building width (m)" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="number"
                placeholder=""
                value={values.Building_width}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Building_width"
                onChange={handleChangeSetValues}
                style={{ margin: "2px 0" }}
            />
            <label><FormattedMessage id="strelement.depth" defaultMessage="d - Building depth (m)" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 3 : 0}
                type="number"
                placeholder=""
                value={values.Building_depth}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Building_depth"
                onChange={handleChangeSetValues}
                style={{ margin: "2px 0" }}
            />
            <label><FormattedMessage id="strelement.strips" defaultMessage="n - Number of strips" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 4 : 0}
                type="number"
                placeholder=""
                value={values.Structure_n}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Structure_n"
                onChange={handleChangeSetValues}
                style={{ margin: "2px 0" }}
            />

            <br />
            <label><FormattedMessage id="strelement.Large" defaultMessage="Large and considerably higher neighbouring structures" /> </label>
            <div>
                <label><FormattedMessage id="strelement.yes" defaultMessage="Yes" /></label>
                <input
                    key={props.jsonKey ? props.jsonKey + 5 : 0}
                    type="radio"
                    name="large"
                    value="yes"
                    onChange={handleChangeLarge}
                    style={{ margin: "2px 0" }}
                    checked={values.LargeAndConsiderablyHigherNeighbouringStructures == "yes"}
                />

                <label><FormattedMessage id="strelement.No" defaultMessage="No" /></label>
                <input
                    key={props.jsonKey ? props.jsonKey + 6 : 0}
                    type="radio"
                    name="large"
                    value="no"
                    onChange={handleChangeLarge}
                    style={{ margin: "2px 0" }}
                    checked={values.LargeAndConsiderablyHigherNeighbouringStructures == "no"}
                />

            </div>

            <label><FormattedMessage id="strelement.Closely" defaultMessage="Closely spaced buildings and obstacles" /> </label>
            <div>
                <label><FormattedMessage id="strelement.closelyYes" defaultMessage="Yes" /></label>
                <input
                    key={props.jsonKey ? props.jsonKey + 7 : 0}
                    type="radio"
                    name="closely"
                    value="yes"
                    onChange={handleChangeClosly}
                    checked={values.CloselySpacedBuildingsAndObstacles == "yes"}
                />

                <label><FormattedMessage id="strelement.closelyNo" defaultMessage="No" /></label>
                <input
                    key={props.jsonKey ? props.jsonKey + 8 : 0}
                    type="radio"
                    name="closely"
                    value="no"
                    onChange={handleChangeClosly}
                    checked={values.CloselySpacedBuildingsAndObstacles == "no"}
                />

            </div>

            <br hidden={!(closly || (closly && large)) ? true : false} />
            <label hidden={!(closly || (closly && large)) ? true : false}><FormattedMessage id="strelement.Have" defaultMessage="Have - Obstruction height" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 9 : 0}
                type="number"
                hidden={!(closly || (closly && large)) ? true : false}
                value={values.Have}
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Have"
                onChange={handleChangeSetValues}
            />
            <br hidden={!(closly || large)} />
            <label hidden={!(closly || large)} ><FormattedMessage id="strelement.xdistance" defaultMessage="X - Distance from higher neighbouring structure" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 10 : 0}
                type="number"
                placeholder=""
                hidden={!(closly || large)}
                value={values.XDistance}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="XDistance"
                onChange={handleChangeSetValues}
            />
            <br hidden={!(large || (large && closly)) ? true : false} />
            <label hidden={!(large || (large && closly)) ? true : false}><FormattedMessage id="strelement.Hhigh" defaultMessage="Hhigh - Height of higher neighbouring structure" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 11 : 0}
                type="number"
                placeholder=""
                hidden={!(large || (large && closly)) ? true : false}
                value={values.Hhigh}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Hhigh"
                onChange={handleChangeSetValues}
            />
            <br hidden={!(large || (large && closly)) ? true : false} />
            <label hidden={!(large || (large && closly)) ? true : false}><FormattedMessage id="strelement.dlarge" defaultMessage="dlarge - Length of higher neighbouring structure" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 12 : 0}
                type="number"
                placeholder=""
                hidden={!(large || (large && closly)) ? true : false}
                value={values.Dlarge}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Dlarge"
                onChange={handleChangeSetValues}
            />

            <br />
            <label><FormattedMessage id="strelement.position" defaultMessage="Structure position" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 13 : 0}
                type="select"
                value={values.co}
                name="co"
                onChange={handleChangeCo}

            >
                <FormattedMessage id={typeOfTerrain.a.id} message={typeOfTerrain.a} >
                    {(message) => <option key="a1" value="a">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={typeOfTerrain.b.id} message={typeOfTerrain.b} >
                    {(message) => <option key="b1" value="b">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={typeOfTerrain.c.id} message={typeOfTerrain.c} >
                    {(message) => <option key="c1" value="c">{message}</option>}
                </FormattedMessage>

            </Input>
            <br hidden={!IsCo ? true : false} />
            <label hidden={!IsCo ? true : false}><FormattedMessage id="strelement.Effective" defaultMessage="H - Effective height of the feature (m)" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 14 : 0}
                type="number"
                placeholder=""
                hidden={!IsCo ? true : false}
                value={values.HEffectiveHeightOfHill}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="HEffectiveHeightOfHill"
                onChange={handleChangeSetValues}
            />

            <br hidden={!IsCo ? true : false} />
            <label hidden={!IsCo ? true : false}><FormattedMessage id="strelement.Upwind" defaultMessage="Lu - Upwind slope in the wind direction (m)" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 15 : 0}
                type="number"
                placeholder=""
                hidden={!IsCo ? true : false}
                value={values.luUpwindSlopeLenght}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="luUpwindSlopeLenght"
                onChange={handleChangeSetValues}
            />

            <br hidden={!IsCo ? true : false} />
            <label hidden={!IsCo ? true : false}><FormattedMessage id="strelement.lddown" defaultMessage="Ld - Down wind slope lenght (m)" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 16 : 0}
                type="number"
                placeholder=""
                hidden={!IsCo ? true : false}
                value={values.LdDownwindSlopeLenght}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="LdDownwindSlopeLenght"
                onChange={handleChangeSetValues}
            />

            <br hidden={!IsCo ? true : false} />
            <label hidden={!IsCo ? true : false}><FormattedMessage id="strelement.xHorizontal" defaultMessage="X - Horizontal distance from crest (m)" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 17 : 0}
                type="number"
                placeholder=""
                hidden={!IsCo ? true : false}
                value={values.XHorizontalDistanceFromCrest}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="XHorizontalDistanceFromCrest"
                onChange={handleChangeSetValues}
            />
            <br />
            <label><FormattedMessage id="strelement.chooseroof" defaultMessage="Choose roof" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 18 : 0}
                type="select"
                value={values.type_of_roof_on_building}
                name="type_of_roof_on_building"
                onChange={handleChangeTypeOfRoof}


            >
                <FormattedMessage id={TypeOfBuildingMessage.a1.id} message={TypeOfBuildingMessage.a1} >
                    {(message) => <option key="a1" value="0">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={TypeOfBuildingMessage.b1.id} message={TypeOfBuildingMessage.b1} >
                    {(message) => <option key="b1" value="1">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={TypeOfBuildingMessage.c1.id} message={TypeOfBuildingMessage.c1} >
                    {(message) => <option key="c1" value="2">{message}</option>}
                </FormattedMessage>



            </Input>
            <br hidden={!IsMonoRoof ? true : false} />
            <label hidden={!IsMonoRoof ? true : false}>Roof angle α (°)</label>
            <Input
                key={props.jsonKey ? props.jsonKey + 19 : 0}
                type="number"
                placeholder=""
                hidden={!IsMonoRoof ? true : false}
                value={values.Monopitch_alpha}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Monopitch_alpha"
                onChange={handleChangeSetValues}
            />
            <br hidden={!IsDuoRoof ? true : false} />
            <label hidden={!IsDuoRoof ? true : false}>Roof angle α (°)</label>
            <Input
                key={props.jsonKey ? props.jsonKey + 20 : 0}
                type="number"
                placeholder=""
                hidden={!IsDuoRoof ? true : false}
                value={values.Duopitch_alpha}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="Duopitch_alpha"
                onChange={handleChangeSetValues}
            />
        </Fragment>
    );
};

export default StructureTerrainField;
