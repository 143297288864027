import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

class RedirectIfHasCompany extends Component {
    render() {
        if (this.props.token && this.props.loggedIn && (this.props.companyId || this.props.role === 'Admin') ){
            return <Redirect to={{ pathname: "/" }} />
        } else if (!this.props.token || !this.props.loggedIn) {
            return <Redirect to={{ pathname: "/Login" }} />
        } else {
            return null;
        }
    }
}

function mapStateToProps(state) {
  const { token, companyId, role } = state.user;
  const { loggedIn } = state.login;
  return { token, loggedIn, companyId, role };
}

export default connect(mapStateToProps)(RedirectIfHasCompany);
