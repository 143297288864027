import React from "react";
import { ReactComponent as ActionsDuringExecution } from "./Icons/Actions during Execution.svg";
import { ReactComponent as ActionsInducedByCranes } from "./Icons/Actions induced by cranes.svg";
import { ReactComponent as Attachments } from "./Icons/Attachments.svg";
import { ReactComponent as Audit } from "./Icons/Audit.svg";
import { ReactComponent as Beams } from "./Icons/Beams.svg";
import { ReactComponent as Concrete } from "./Icons/Concrete.svg";
import { ReactComponent as DensitiesSelfWeight } from "./Icons/Densities, Self-weight.svg";
import { ReactComponent as DesignBasis } from "./Icons/Design Basis.svg";
import { ReactComponent as DesignConsiderations } from "./Icons/Design Considerations, selection of Materials and Execution of Masonry.svg";
import { ReactComponent as DesignerStatement } from "./Icons/Designer Statement.svg";
import { ReactComponent as DesignersAssociates } from "./Icons/Designers & Associates.svg";
import { ReactComponent as DetailDesign } from "./Icons/Detail Design.svg";
import { ReactComponent as EarthLoads } from "./Icons/Earth Loads.svg";
import { ReactComponent as ExecutionOfConcreteStructures } from "./Icons/Execution of concrete structures.svg";
import { ReactComponent as ExecutionOfSteelAndAluminumStructures } from "./Icons/Execution of steel and aluminum structures.svg";
import { ReactComponent as FireActions } from "./Icons/Fire Actions.svg";
import { ReactComponent as FloorPlate } from "./Icons/Floor Plate.svg";
import { ReactComponent as Foundations } from "./Icons/Foundations.svg";
import { ReactComponent as GeneralData } from "./Icons/General data.svg";
import { ReactComponent as General } from "./Icons/General.svg";
import { ReactComponent as Geometry } from "./Icons/Geometry.svg";
import { ReactComponent as ListOfBooks } from "./Icons/List of Books.svg";
import { ReactComponent as ListOfNorms } from "./Icons/List of Norms.svg";
import { ReactComponent as LoadAnalysis } from "./Icons/Load analysis.svg";
import { ReactComponent as LoadCombinations } from "./Icons/Load Combinations.svg";
import { ReactComponent as LoadsForBuildings } from "./Icons/Loads for Buildings.svg";
import { ReactComponent as MainDesignerStatement } from "./Icons/Main Designer Statement.svg";
import { ReactComponent as MainPage } from "./Icons/Main Page.svg";
import { ReactComponent as MapContent } from "./Icons/Map Content.svg";
import { ReactComponent as Material } from "./Icons/Material.svg";
import { ReactComponent as Projects } from "./Icons/Projects.svg";
import { ReactComponent as QualityControl } from "./Icons/Quality control.svg";
import { ReactComponent as Roof } from "./Icons/Roof.svg";
import { ReactComponent as SeismicActions } from "./Icons/Seismic Actions.svg";
import { ReactComponent as ServiceLimitState } from "./Icons/Service Limit State.svg";
import { ReactComponent as SnowLoads } from "./Icons/Snow Loads.svg";
import { ReactComponent as StaticalCalculation } from "./Icons/Statical Calculation.svg";
import { ReactComponent as StructuralAnalysis } from "./Icons/Structural analysis.svg";
import { ReactComponent as StructuralDesign } from "./Icons/Structural design.svg";
import { ReactComponent as TechnicalDescription } from "./Icons/Technical description.svg";
import { ReactComponent as ThermalActions } from "./Icons/Thermal Actions.svg";
import { ReactComponent as ExtraordinaryActions } from "./Icons/Extraordinary Actions.svg";
import { ReactComponent as UltimateLimitState } from "./Icons/Ultimate Limit State.svg";
import { ReactComponent as Walls } from "./Icons/Walls.svg";
import { ReactComponent as WindActions } from "./Icons/Wind Actions.svg";

const components = {
    ActionsDuringExecution,
    ActionsInducedByCranes,
    Attachments,
    Audit,
    Beams,
    Concrete,
    DensitiesSelfWeight,
    DesignBasis,
    DesignConsiderations,
    DesignerStatement,
    DesignersAssociates,
    DetailDesign,
    EarthLoads,
    ExecutionOfConcreteStructures,
    ExecutionOfSteelAndAluminumStructures,
    FireActions,
    FloorPlate,
    Foundations,
    GeneralData,
    General,
    Geometry,
    ListOfBooks,
    ListOfNorms,
    LoadAnalysis,
    LoadCombinations,
    LoadsForBuildings,
    Projects,
    MainDesignerStatement,
    MainPage,
    MapContent,
    Material,
    QualityControl,
    Roof,
    SeismicActions,
    ServiceLimitState,
    SnowLoads,
    StaticalCalculation,
    StructuralAnalysis,
    StructuralDesign,
    TechnicalDescription,
    ThermalActions,
    ExtraordinaryActions,
    UltimateLimitState,
    Walls,
    WindActions,
};

export default function Icons({ name }) {
    const Component = components[name];
    return <Component />;
}
