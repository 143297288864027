import React from "react";
import { compose, withStateHandlers } from "recompose";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
} from "react-google-maps";

/* api key: AIzaSyCYIOA7SLNBw4sL4M8y1IfmEuGsf2s5pd4 */

const Map = compose(
    withStateHandlers(
        (props) => ({
            isMarkerShown: true,
            markerPosition: props.startPos,
            mapClicked: false,
        }),
        {
            onMapClick: () => (e) => ({
                markerPosition: e.latLng,
                isMarkerShown: true,
                mapClicked: true,
            }),
        }
    ),
    withScriptjs,
    withGoogleMap
)((props) => {
    let latitude = props.mapClicked
        ? props.markerPosition.lat()
        : props.markerPosition.lat;
    let longitude = props.mapClicked
        ? props.markerPosition.lng()
        : props.markerPosition.lng;

    return props.editClick ? (
        <div>
            <GoogleMap
                zoom={!props.startPos.lat && !props.startPos.lng ? 3 : 13}
                center={props.startPos}
                onClick={props.onMapClick}
            >
                {props.isMarkerShown && (
                    <Marker position={props.markerPosition} />
                )}
            </GoogleMap>
            <br />
            <p id="coordinates" data-lat={latitude} data-lng={longitude}>
                Position:{" "}
                {" " + latitude.toFixed(4) + " " + longitude.toFixed(4)}
            </p>
        </div>
    ) : (
        <div>
            <GoogleMap
                zoom={!props.startPos.lat && !props.startPos.lng ? 3 : 13}
                center={props.markerPosition}
            >
                {props.isMarkerShown && (
                    <Marker position={props.markerPosition} />
                )}
            </GoogleMap>
            <br />
            <p>
                Position:{" "}
                {" " + latitude.toFixed(4) + " " + longitude.toFixed(4)}
            </p>
        </div>
    );
});

export default class MapComp extends React.Component {
    render() {
        return (
            <div style={{ height: "100%" }}>
                <Map
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCYIOA7SLNBw4sL4M8y1IfmEuGsf2s5pd4"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    startPos={{
                        lat: this.props.startLat,
                        lng: this.props.startLng,
                    }}
                    editClick={this.props.editClick}
                    setLatLng={(a, b) => this.props.setLocation(a, b)}
                />
            </div>
        );
    }
}
