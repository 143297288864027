import React, { useState, useEffect, Fragment } from "react";
import { Input } from "reactstrap";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";
import InfoTooltip from "../../../Tooltip/InfoTooltip";


export const CanopyFi = (props) => {
    const [values, setValues] = useState({

        canopy_fi: 0
    });


    useEffect(() => {
        if (props.startValue) {
            setValues(parseInputField(props.startValue));
        }
    }, [props.startValue]);



    const handleChangeSetValues = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        if (name == "canopy_fi") setValues({ ...values, canopy_fi: value })


    }
    return (
        <Fragment>
            <label> </label>
            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(values)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />

            <label>ϕ</label>
            <div style={{ display: "flex" }}>
                <Input
                    key={props.jsonKey ? props.jsonKey + 2 : 0}
                    type="number"
                    placeholder=""
                    value={values.Building_width}
                    lang="en"
                    autoComplete="off"
                    onBlur={props.onBlur}
                    invalid={props.invalid}
                    name="canopy_fi"
                    onChange={handleChangeSetValues}
                    style={{ margin: "2px 0" }}
                />
                <InfoTooltip
                    placement="right"
                    content={`ϕ [0-1]  Ratio of the area of feasible, actual obstructions under the canopy divided by the cross sectional area under the canopy, both areas being normal to the wind direction. ϕ=0 represents an empty canopy, and ϕ=1 represents the canopy fully blocked with contents to the down wind eaves only (this is not a closed building).`}
                />
            </div>
        </Fragment>
    );
};

export default CanopyFi