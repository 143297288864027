import { getAuthorizationProperties } from "./authorizationHelper";

export function deleteCalculation(user, guid) {
    return fetch(`api/calculations/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}

export function updateCalculation(user, guid, form, jsonString) {
    return fetch(`${form.apiPath}/${guid}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: jsonString,
    });
}

export function postCalculation(user, jsonString) {
    return fetch("api/calculations", {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: jsonString,
    });
}

export function getDefaultCalculation(user, form, project) {
    return fetch(`api/calculations/default?projectId=${project}&formId=${form}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => {
        if (!response.ok) {
            return null
        } else {
            return response.json();
        }
    });
}


export function calculate(jsonString, form, user) {
    return fetch(`${form.apiPath}/${form.id}`, {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: jsonString,
    });
}

export function validate(jsonString, form, user) {
    return fetch(`${form.apiPath}/validate/${form.id}`, {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: jsonString,
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response;
        }
    });
}
