import React from "react";
import { Input } from "reactstrap";
import SpecialInputField from "./SpecialInputField";
import { ImageSelector } from "./ImageSelector";
import { FormattedMessage } from 'react-intl';

export function parseInputField(value) {
    if ((typeof value === 'string' || value instanceof String) && value.startsWith("JSONSERIALIZEDSTRING")) {
        var ret = value.replace('JSONSERIALIZEDSTRING', '');
        return JSON.parse(ret);
    } else {
        return value;
    }
}

export function saveJsonInputField(values) {
    return `JSONSERIALIZEDSTRING${JSON.stringify(values)}`
}

export function makeInputFields(
    input,
    startValue = null,
    validationError = null,
    handlers = {}
) {
    let fields = [];

    if (input.type === "Int" || input.type === "Float") {
        fields[0] = (
            <Input
                type="number"
                invalid={validationError ? true : false}
                step="any"
                lang="en"
                name={input.key}
                key={input.key}
                id={input.key}
                defaultValue={startValue}
                autoComplete="off"
                {...handlers}
                onWheelCapture={(e) => e.currentTarget.blur()}
            />
        );
    } else if (input.type === "Text") {
        fields[0] = (
            <Input
                type="text"
                invalid={validationError ? true : false}
                name={input.key}
                key={input.key}
                id={input.key}
                defaultValue={startValue}
                autoComplete="off"
                {...handlers}
            />
        );
    } else if (input.type === "Dropdown") {
        fields[0] = (
            <Input
                type="select"
                name={input.key}
                key={input.key}
                id={input.key}
                defaultValue={startValue}
                {...handlers}
            >
                {input.inputOptions.map((io) => (
                    <option key={input.key + io.key} value={io.key}>
                        {io.text}
                    </option>
                ))}
            </Input>
        );
    } else if (input.type === "Bool") {
        fields[0] = (
            <div className="bool-option" key={`${input.key}.yes`}>
                <Input
                    value={true}
                    type="radio"
                    name={input.key}
                    defaultChecked={startValue === "true" ? "checked" : null}
                    {...handlers}
                />
                <span><FormattedMessage id="select.yes" defaultMessage="Yes" /></span>
            </div>
        );
        fields[1] = (
            <div className="bool-option" key={`${input.key}.no`}>
                <Input
                    value={false}
                    type="radio"
                    name={input.key}
                    defaultChecked={
                        startValue === "false" || !startValue ? "checked" : null
                    }
                    {...handlers}
                />
                <span><FormattedMessage id="select.No" defaultMessage="No" /></span>
            </div>
        );
    } else if (input.type === "Hidden") {
        fields[0] = (
            <Input
                value={input.inputOptions[0].key}
                type="hidden"
                name={input.key}
                key={input.key}
                {...handlers}
            />
        );
    } else if (input.type === "Special") {
        fields[0] = (
            <SpecialInputField
                {...input}
                jsonKey={input.key}
                startValue={startValue}
                invalid={validationError ? true : false}
                changeHandler={handlers.onChange}
                onBlur={handlers.onBlur}
            />
        );
    } else if (input.type === "Divider") {
        fields[0] = <hr name={input.key} key={input.key} className="divider" />;
    } else if (input.type === "ImageSelector") {
        fields[0] = (
            <ImageSelector
                input={input}
                changeHandler={handlers.onChange}
                startValue={startValue}
                key={input.key}
            />
        );
    }

    return fields;
}
