import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import RichTextEditor from 'react-rte';
import ToolbarConfig from './toolbarConfig.json';
import "./ParagraphEditor.css"

function ParagraphEditor(props) {
    const [newName, setNewName] = useState(props.name);
    const [newContent, setNewContent] = useState(RichTextEditor.createValueFromString(props.content, 'html'));

    let saveChanges = () => {
        let data = { name: newName, content: newContent.toString('html') };
        props.onSave(data);
        props.close();
    }

    let onNameChange = (event) => {
        setNewName(event.target.value);
    }

    let onContentChange = (value) => {
        setNewContent(value);
    }

    return (
        <Form id="paragraph-form">
            <FormGroup>
                <Label for="paragraph-name">Name:</Label>
                <Input type="text" name="name" key="name" id="paragraph-name" value={newName} onChange={onNameChange} />
            </FormGroup>
            <FormGroup>
                <Label for="paragraph-content">Content:</Label>
                <RichTextEditor
                    id="paragraph-content"
                    value={newContent}
                    onChange={onContentChange}
                    toolbarConfig={ToolbarConfig}
                />
            </FormGroup>
            <div className="buttons-container">
                <Button onClick={saveChanges}>Save</Button>
                <Button onClick={props.close}>Cancel</Button>
            </div>
        </Form>
    );
}
export default ParagraphEditor;