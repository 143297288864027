import React, { useState, useEffect } from "react";
import { Input, Button, Row, Col } from "reactstrap";
import { getCase } from "../../../../services/recommendedValuesService";
import InfoTooltip from "../../../Tooltip/InfoTooltip";
import { useSelector } from "react-redux";
import Modal from "../../../../containers/EurocodeModal/EurocodeModal";
import { SnowLoadCalculationCaseTable } from "./SnowLoadCalculationCaseTable";
import { FormattedMessage } from 'react-intl';

const SnowLoadCalculationCase = ({
    jsonKey,
    inputOptions,
    startValue,
    changeHandler,
}) => {
    const [recommendedCase, setRecommendedCase] = useState();
    const activeProjectId = useSelector((state) => state.activeProject.id);
    const user = useSelector((state) => state.user);
    const [caseKey, setCaseKey] = useState(
        startValue ? startValue : inputOptions[0].key
    );
    const [caseText, setCaseText] = useState(
        startValue
            ? inputOptions.filter((x) => x.key === startValue)[0].text
            : inputOptions.filter((x) => x.key === inputOptions[0].key)[0].text
    );
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setRecommendedCase(undefined);
        if (activeProjectId) {
            getCase(activeProjectId, user).then((value) => {
                if (value.length > 0) {
                    setRecommendedCase(
                        inputOptions.filter((x) => x.key === value)[0]
                    );
                    if (value && !startValue) {
                        setCaseKey(value);
                        setCaseText(
                            inputOptions.filter((x) => x.key === value)[0].text
                        );
                    }
                }
                if (changeHandler && !startValue) {
                    changeHandler({
                        target: { value: value },
                    });
                }
            });
        }
    }, [activeProjectId, inputOptions, startValue, user]); // eslint-disable-line react-hooks/exhaustive-deps

    const selectCase = (c) => {
        setCaseKey(c.key);
        setCaseText(c.text);
        setModalOpen(false);
    };

    return (
        <div style={{ display: "flex" }}>
            <Input
                key={jsonKey}
                type="text"
                hidden
                name={jsonKey}
                value={caseKey}
                readOnly={true}
            />
            <Input
                type="text"
                className={jsonKey + "-text"}
                disabled
                value={caseText}
                readOnly={true}
            />
            <Button
                style={{ marginLeft: "20px" }}
                onClick={() => setModalOpen(true)}
            >
                <FormattedMessage id="snowload.change" defaultMessage="Change" />

            </Button>{" "}
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                modalTitle={<FormattedMessage id="snow.modeltitle" defaultMessage="Select case" />}
            >
                <Row sm={2} md={4} className="modal-images">
                    {inputOptions
                        .sort((a, b) =>
                            a.key > b.key ? 1 : b.key > a.key ? -1 : 0
                        )
                        .map((io) => (
                            <Col
                                key={jsonKey + io.key}
                                onClick={() => {
                                    selectCase(io);
                                    changeHandler({
                                        target: { value: io.key },
                                    });
                                }}
                                className="modal-table"
                            >
                                <SnowLoadCalculationCaseTable
                                    snowLoadCase={io.key}
                                />
                            </Col>
                        ))}
                </Row>
                <p>
                    <strong><FormattedMessage id="snow.note1" defaultMessage="NOTE 1" /></strong><FormattedMessage id="snow.Exceptionalconditions" defaultMessage=": Exceptional conditions are defined
                    according to the National Annex." />
                </p>
                <p>
                    <strong><FormattedMessage id="snow.note2" defaultMessage="NOTE 2" /></strong><FormattedMessage id="snow.B1andB3" defaultMessage=": For cases B1 and B3 the National
                    Annex may define design situations which apply for the
                    particular local effects described in section 6." />
                </p>
            </Modal>
            {recommendedCase && (
                <InfoTooltip
                    placement="right"
                    content={`${recommendedCase.text} is recommended based on your project's location, country, and elevation`}
                />
            )}
        </div>
    );
};

export default SnowLoadCalculationCase;
