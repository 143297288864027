import React from "react";
import Modal from "react-modal";
import customStyles from "./EurocodeModal.css";

Modal.setAppElement("#root");

class EurocodeModal extends React.Component {
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpen}
                    style={customStyles}
                    contentLabel={this.props.modalTitle}
                    className="modal"
                    overlayClassName="overlay"
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.props.closeModal}
                >
                    <div className="content-wrapper">
                        {this.props.modalTitle ? (
                            <h3>{this.props.modalTitle}</h3>
                        ) : null}
                        {this.props.modalTitle ? <hr /> : null}
                        {this.props.children}
                    </div>
                </Modal>
            </div>
        );
    }
}

export default EurocodeModal;
