import { getAuthorizationProperties } from "./authorizationHelper";

export function imageExist(guid) {
    return fetch(`/api/Images/check/${guid}`);
}

export function putImage(user, data, guid) {
    if (guid === null) {
        guid = user.guid;
    }
    return fetch(`/api/Images/${guid}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token),
        body: data,
    });
}

export function postImage(user, data) {
    return fetch("/api/Images/", {
        method: "POST",
        ...getAuthorizationProperties(user.token),
        body: data,
    }).then((res) => res.json());
}

export function deleteImage(user, guid) {
    return fetch(`/api/Images/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}
