import React, { Fragment } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ChangeUserInfo } from "../../actions/userActions";
import { RefreshImage } from "../../actions/imageAction";
import { edit } from "../../services/usersService";
import { putImage } from "../../services/imageService";
import { getCompany } from "../../services/companyService";
import avatar from "./user-image.jpg";
import Dropzone from "../Dropzone/Dropzone";
import { FormattedMessage } from 'react-intl';
import "./EditUser.css";
import Image from "../../components/EurocodeImage/Image";
import Loading from "../../components/Loading/Loading";

function getCroppedImg(crop, fileName) {
    let image = document.querySelector("img.ReactCrop__image");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image",
            1
        );
    });
}

class User extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            firstNameValue: this.props.user.firstName,
            lastNameValue: this.props.user.lastName,
            position: this.props.user.position,
            company: {},
            image: `/api/Images/${this.props.user.guid}`,
            imageDropped: false,
            refreshThumb: false,
            crop: false,
            editMode: false,
            src: "",
            loading: true,
        };

        getCompany(this.props.user, this.props.user.companyId).then(
            (company) => {
                this.setState({ company: company });
                this.setState({ loading: false });
            }
        );

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
    }

    handleChangeFirstName(event) {
        this.setState({
            firstNameValue: event.target.value,
        });
    }

    handleChangeLastName(event) {
        this.setState({
            lastNameValue: event.target.value,
        });
    }

    handleCropChange(newCrop) {
        this.setState({ crop: newCrop });
    }

    handleDrop() {
        this.setState({ imageDropped: true });
    }

    setRefreshThumb(refreshThumb) {
        this.setState({ refreshThumb });
    }

    updateUserInfo() {
        edit(
            this.props.user,
            this.state.firstNameValue,
            this.state.lastNameValue
        ).then(() => {
            this.props.actions.ChangeUserInfo({
                firstName: this.state.firstNameValue,
                lastName: this.state.lastNameValue,
            });
        });
        let obj = JSON.parse(sessionStorage.getItem("user"));
        obj.firstName = this.state.firstNameValue;
        obj.lastName = this.state.lastNameValue;
        sessionStorage.setItem("user", JSON.stringify(obj));
        if (this.state.imageDropped) {
            getCroppedImg(this.state.crop, this.props.user.guid).then(
                (imageData) => {
                    putImage(this.props.user, imageData, null).then(() => {
                        this.props.actions.RefreshImage(this.props.user.guid);
                    });
                }
            );
        }
        this.setState({ editMode: false });
    }

    handleEditMode(editMode) {
        this.setState({ editMode });
    }

    render() {
        if (!this.state.editMode)
            return (
                <div className="user-container">
                    <h3><FormattedMessage id="userInfo" defaultMessage="User info"/></h3>
                    {this.state.loading ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            <div className="img-container">
                                <Image
                                    placeholder={avatar}
                                    imageId={this.props.user.guid}
                                    src={this.state.src}
                                />
                            </div>
                            <h4>
                                {this.state.firstNameValue}{" "}
                                {this.state.lastNameValue}
                            </h4>
                            <h5>
                                {this.state.position} at{" "}
                                {this.state.company.name}
                            </h5>
                            <div className="buttons-container">
                                <Button
                                    onClick={this.handleEditMode.bind(
                                        this,
                                        true
                                    )}
                                >
                                    <FormattedMessage id="edit" defaultMessage="Edit"/>
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </div>
            );
        else
            return (
                <div className="user-container">
                    <h3><FormattedMessage id="editUserInfo" defaultMessage="Edit user info"/></h3>
                    <Dropzone
                        text="Drag 'n' drop new profile picture, or click to select it"
                        imagePlaceholder={avatar}
                        crop={this.state.crop}
                        setCrop={this.handleCropChange.bind(this)}
                        onDrop={this.handleDrop.bind(this)}
                        refreshThumb={this.state.refreshThumb}
                        clearRefreshThumb={this.setRefreshThumb.bind(
                            this,
                            false
                        )}
                        image={this.props.user.guid}
                    />
                    <Form>
                        <FormGroup>
                            <Label for="firstName"><FormattedMessage id="firstName" defaultMessage="First name"/>:</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder=""
                                value={this.state.firstNameValue}
                                onChange={this.handleChangeFirstName}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="lastName"><FormattedMessage id="lastName" defaultMessage="Last name"/>:</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="lastName"
                                placeholder=""
                                value={this.state.lastNameValue}
                                onChange={this.handleChangeLastName}
                            />
                        </FormGroup>
                        <div className="buttons-container">
                            <Button onClick={this.updateUserInfo.bind(this)}>
                                <FormattedMessage id="update" defaultMessage="Update"/>
                            </Button>
                            <Button
                                onClick={this.handleEditMode.bind(this, false)}
                            >
                                <FormattedMessage id="cancel" defaultMessage="Cancel"/>
                            </Button>
                        </div>
                    </Form>
                </div>
            );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    const timestamps = state.images.timestamps;
    return { user, timestamps };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ ChangeUserInfo, RefreshImage }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
