import React from "react";
import Icons from "../../containers/Sidebar/SidebarIcons.js";
import { Link } from "react-router-dom";
import Tooltip from "../Tooltip/Tooltip.js";
import { useIntl } from "react-intl";
import "./SidebarItem.css";

function SidebarItem({ text, icon, active, onClick, link, expanded }) {
    const intl = useIntl();

    let className = "sidebar-item-container tooltips" + (active ? " active" : "");

    let content = (
        <div className="sidebar-item">
            <div className="sidebar-item-icon">
                <Icons name={icon} />
            </div>
            <div className="sidebar-item-text">
                <h5>{intl.formatMessage(text)}</h5>
            </div>
        </div>
    );

    if (link) {
        return (
            <Link to={link} className={className} onClick={() => onClick(icon)}>
                {content}
                <hr />
                {!expanded && <Tooltip text={intl.formatMessage(text)} placement="right" />}
            </Link>
        );
    } else {
        return (
            <div className={className} onClick={() => onClick(icon)}>
                {content}
                <hr />
                {!expanded && <Tooltip text={intl.formatMessage(text)} placement="right" />}
            </div>
        );
    }
}

export default SidebarItem;
