import React from "react";
import { Button, Input, Label, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import { previewProject } from "../../services/previewService";
import { downloadWordDocument } from "../../services/wordDocumentService";
import {
    getProject,
    getCountries,
    saveProjectLocation,
} from "../../services/projectsService";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import ProjectEdit from "../ProjectEdit/ProjectEdit";
import Sections from "../Sections/Sections";
import { ReactComponent as IInfo } from "./Icons/Ikona_project info.svg";
import { ReactComponent as ISections } from "./Icons/Ikona_sections.svg";
import { ReactComponent as ILocation } from "./Icons/Ikona_location.svg";
import { ReactComponent as IUpdate } from "./Icons/ikona_update.svg";
import { ReactComponent as IPreview } from "./Icons/Ikona_preview.svg";
import { ReactComponent as IDownload } from "./Icons/Ikona_download.svg";
import Tooltip from "../../components/Tooltip/Tooltip";
import MapComp from "../Map/MapComp";
import "./Project.css";
import { FormattedMessage } from 'react-intl';

class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            id: "",
            companyId: "",
            latitude: "",
            longitude: "",
            countryCode: "",
            language: "",
            selectedCountry: "",
            component: "sections",
            countriesData: {},
            editClicked: false,
        };

        this.getDetails = this.setComponent.bind(this, "details");
        this.getSections = this.setComponent.bind(this, "sections");
        this.getEdit = this.setComponent.bind(this, "edit");
        this.getMap = this.setComponent.bind(this, "map");
        this.getEditMap = this.setComponent.bind(this, "edit-map");
        this.setProjectInfo = this.setProjectInfo.bind(this);
        // this.updateLocation = this.updateLocation.bind(this);

        this.refresh = () =>
            getProject(this.props.user, this.props.projectGuid).then((p) =>
                this.setState({
                    ...p,
                    component: "sections",
                    selectedCountry: p.countryCode,
                })
            );

        this.refresh();

        getCountries().then((data) => {
            this.setState({ countriesData: data });
        });
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.projectGuid !== prevProps.projectGuid &&
            this.props.projectGuid
        ) {
            this.refresh();
        }
    }

    updateLocation = (lat, lng) => {
        this.setState({
            latitude: lat,
            longitude: lng,
        });
        console.log(this.state);
    };

    handleLocationEditClick(editClicked) {
        this.setState({ editClicked });
        this.getEditMap();
    }

    handleCountryChange(e) {
        this.setState({ selectedCountry: e.target.value });
    }

    handleElevationChange(e) {
        this.setState({ elevation: e.target.value });
    }

    handleSaveLocation() {
        let coordinatesParagraph = document.getElementById("coordinates");
        let lat = coordinatesParagraph.dataset.lat;
        let lng = coordinatesParagraph.dataset.lng;
        saveProjectLocation(
            this.props.user,
            this.state.name,
            this.state.description,
            this.state.id,
            this.state.companyId,
            Number(lat),
            Number(lng),
            Number(this.state.elevation),
            this.state.selectedCountry,
            this.props.projectGuid
        ).then(this.refresh);
        this.setState({ editClicked: false });
        this.getDetails();
    }

    setComponent(component) {
        this.setState({ component });
    }

    setProjectInfo(name, description, language) {
        console.log(language);
        this.setState({ name, description, language });
    }

    handleBack() {
        this.handleLocationEditClick(false);
        this.getDetails();
        this.setState({
            selectedCountry: this.state.countryCode,
        });
    }

    render() {
        const component = this.state.component;
        let showComponent;

        if (component === "details") {
            showComponent = <ProjectDetails language={this.state.language} />;
        } else if (component === "sections") {
            showComponent = <Sections />;
        } else if (component === "map" || component === "edit-map") {
            showComponent = (
                <MapComp
                    startLat={this.state.latitude}
                    startLng={this.state.longitude}
                    editClick={this.state.editClicked}
                />
            );
        } else {
            showComponent = (
                <ProjectEdit
                    editProject={this.setProjectInfo}
                    goToDetails={this.getDetails}
                />
            );
        }
        return (
            <div className="project-container">
                <h3>{this.state.name}</h3>

                <div className="project-tabs">
                    <Nav tabs fill={true}>
                        <NavItem>
                            <NavLink
                                active={component === "sections"}
                                onClick={() =>
                                    this.setState({ component: "sections" })
                                }
                            >
                                <ISections className="icon" />
                                <FormattedMessage id="project.id" defaultMessage="Sections" />
                                
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={component === "details"}
                                onClick={() =>
                                    this.setState({ component: "details" })
                                }
                            >
                                <IInfo className="icon" />
                                <FormattedMessage id="project.info" defaultMessage="Project info" />
                                
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                active={component === "map"}
                                onClick={() =>
                                    this.setState({ component: "map" })
                                }
                            >
                                <ILocation className="icon" />
                                <FormattedMessage id="project.Location" defaultMessage="Location" />
                                
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>

                {component === "map" || component === "edit-map" ? (
                    <div>
                        {this.state.editClicked ? (
                            <div style={{ paddingBottom: "20px" }}>
                                <Label for="country">Choose country:</Label>
                                <Input
                                    value={this.state.selectedCountry}
                                    type="select"
                                    name="country"
                                    id="country"
                                    onChange={this.handleCountryChange.bind(
                                        this
                                    )}
                                >
                                    {Object.entries(
                                        this.state.countriesData
                                    ).map((item) => (
                                        <option key={item[0]} value={item[0]}>
                                            {" "}
                                            {item[1]}{" "}
                                        </option>
                                    ))}
                                </Input>
                            </div>
                        ) : (
                            <p>
                                {" "}
                                Project country:{" "}
                                {
                                    this.state.countriesData[
                                        this.state.countryCode
                                    ]
                                }{" "}
                            </p>
                        )}
                    </div>
                ) : null}
                <div className="component-container">{showComponent}</div>

                <div>
                    {component === "details" ? (
                        <div className="project-buttons">
                            <Button
                                className="project-button"
                                onClick={this.getEdit}
                            >
                                <IUpdate className="icon" />
                                <FormattedMessage id="project.Edit" defaultMessage="Edit" />
                                
                            </Button>
                            <Button
                                className="project-button"
                                onClick={previewProject.bind(
                                    this,
                                    this.props.user,
                                    this.props.projectGuid
                                )}
                            >
                                <IPreview className="icon" />
                                <FormattedMessage id="project.Preview" defaultMessage="Preview" />
                                
                            </Button>
                            <div className="project-button-container tooltips">
                                <Button
                                    className="project-button"
                                    onClick={downloadWordDocument.bind(
                                        this,
                                        this.props.user,
                                        this.props.projectGuid
                                    )}
                                    disabled={
                                        !this.props.user ||
                                        !this.props.user.hasFullAccess
                                    }
                                >
                                    <IDownload className="icon" />
                                    <FormattedMessage id="project.Download" defaultMessage="Download" />
                                    
                                </Button>

                                {(!this.props.user ||
                                    !this.props.user.hasFullAccess) && (
                                    <Tooltip
                                        text="Full access required in order to download docx file"
                                        placement="right"
                                    />
                                )}
                            </div>
                        </div>
                    ) : null}

                    {component === "map" || component === "edit-map" ? (
                        <div>
                            {this.state.editClicked && (
                                <div
                                    style={
                                        (this.state.editClicked
                                            ? null
                                            : { display: "None" },
                                        {
                                            paddingTop: "20px",
                                            paddingBottom: "20px",
                                        })
                                    }
                                >
                                    <Label
                                        for="elevation"
                                        style={{ marginTop: "30px" }}
                                    >
                                        <FormattedMessage id="project.Elevation" defaultMessage="Elevation:" />
                                        
                                    </Label>
                                    <Input
                                        type="number"
                                        name="elevation"
                                        key="elevation"
                                        value={this.state.elevation}
                                        onChange={this.handleElevationChange.bind(
                                            this
                                        )}
                                        style={{ marginBottom: "10px" }}
                                    />
                                    <Button
                                        onClick={this.handleSaveLocation.bind(
                                            this
                                        )}
                                        style={{ marginRight: "15px" }}
                                    >
                                        {" "}
                                        <FormattedMessage id="project.Save" defaultMessage="Save" />
                                        
                                    </Button>
                                    <Button
                                        onClick={this.handleBack.bind(this)}
                                        style={{ marginRight: "15px" }}
                                    >
                                        <FormattedMessage id="project.Cancel" defaultMessage="Cancel" />
                                        
                                    </Button>
                                </div>
                            )}
                            <div
                                style={
                                    !this.state.editClicked
                                        ? null
                                        : { display: "None" }
                                }
                            >
                                <p style={{ marginTop: "50px" }}>
                                    <FormattedMessage id="project.Elevationnew" defaultMessage="Elevation" /> : {this.state.elevation}m
                                </p>
                                <Button
                                    onClick={this.handleLocationEditClick.bind(
                                        this,
                                        true
                                    )}
                                >
                                    {" "}
                                    <FormattedMessage id="project.edit" defaultMessage="Edit" />{" "}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    let projectGuid = state.router.location.pathname.split("/")[2];
    const activeProject = state.activeProject;
    return { user, projectGuid, activeProject };
}

export default connect(mapStateToProps)(Project);
