import React from "react";
import "./LandingPage.css";
import { ReactComponent as ForumIcon } from "./Icons/Eurocode Forum_icon.svg";
import { ReactComponent as DesigningIcon } from "./Icons/Eurocode Designing_icon.svg";
import { ReactComponent as EducationIcon } from "./Icons/Eurocode Education_icon.svg";
import { ReactComponent as DevelopmentIcon } from "./Icons/Eurocode Development_icon.svg";
import { ReactComponent as LibraryIcon } from "./Icons/Eurocode Library_icon.svg";
import { Card } from "reactstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from 'react-intl';

export const LandingPage = () => {
    const user = useSelector(state => state.user);
    const history = useHistory();

    return (
        <div className="landing-page-container">
            <h3>
                <FormattedMessage id="landingpage.welcome" defaultMessage="Welcome" />, {user.firstName} {user.lastName}!
            </h3>
            <section className="landing-page-icons">
                <Card className="project-card" onClick={() => history.push("/calculations")}>
                    <DesigningIcon />
                    <h5><FormattedMessage id="landingpage.designing" defaultMessage="Designing" /></h5>
                </Card>
                <Card className="project-card">
                    <ForumIcon />
                    <h5><FormattedMessage id="landingpage.forum" defaultMessage="Forum" /></h5>
                </Card>
                <Card className="project-card">
                    <LibraryIcon />
                    <h5><FormattedMessage id="landingpage.library" defaultMessage="Library" /></h5>
                </Card>
                <Card className="project-card">
                    <EducationIcon />
                    <h5><FormattedMessage id="landingpage.education" defaultMessage="Education" /></h5>
                </Card>
                <Card className="project-card">
                    <DevelopmentIcon />
                    <h5><FormattedMessage id="landingpage.development" defaultMessage="Development" /></h5>
                </Card>
            </section>
        </div>
    );
};
