export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SELECT_NAVIGATION_ITEM = 'SELECT_NAVIGATION_ITEM';
export const SET_AUTH = 'SET_AUTH';

export function selectNavigationItem (item) {
    return { type: SET_AUTH, item}
}

export function toggleSidebar () {
    return { type: TOGGLE_SIDEBAR}
}
