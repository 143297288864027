import { SET_ALERT_MSG, CLEAR_ALERT_MSG } from "../actions/alertMessageActions";

const initialState = "";

function alertMessageReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ALERT_MSG:
            return action.message;
        case CLEAR_ALERT_MSG:
            return initialState;
        default:
            return state;
    }
}

export default alertMessageReducer;
