import React from "react";
import "./CalculationResult.css";

function camelCaseToWords(text) {
    let withSpaces = text.replace(/([A-Z])/g, " $1");
    withSpaces = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

    let words = withSpaces.split(" ");
    let result = "";

    words.forEach((w, i) => {
        if (i > 1) {
            w = w.toLowerCase();
        }
        result = result + w + " ";
    });

    return result.slice(0, -1) + ":";
}

function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

function possibleNumberToString(obj) {
    if (isNumber(obj)) {
        return Math.round(obj * 100) / 100;
    } else {
        return obj.toString();
    }
}

const CalculationResult = ({ data }) => {
    let maxArrayLength = 0;
    for (let key in data) {
        if (data[key] == null) continue;
        if (data[key].length > maxArrayLength)
            maxArrayLength = data[key].length;
    }

    return (
        <table className="results-table">
            <tbody>
                {Object.keys(data).map((k, i) => {
                    if (data[k] != null) {
                        const val = data[k];
                        let columns = [];

                        if (Array.isArray(val)) {
                            columns = val;
                        } else {
                            columns.push(val);
                        }

                        return (
                            <tr key={i}>
                                <td>{camelCaseToWords(k)}</td>
                                {columns.map((v, j) => (
                                    <td key={j}>{possibleNumberToString(v)}</td>
                                ))}
                            </tr>
                        );
                    }
                    return null;
                })}
            </tbody>
        </table>
    );
};
export default CalculationResult;
