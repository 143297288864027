export const USER_INFO = "USER_INFO";
export const REFRESH_USER = "REFRESH_USER";

export function ChangeUserInfo(userInfo) {
    return { type: USER_INFO, userInfo };
}

export function refreshUser() {
    return { type: REFRESH_USER };
}
