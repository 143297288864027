import React, { useState } from "react";
import Modal from "../EurocodeModal/EurocodeModal";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { defineMessages, FormattedMessage } from 'react-intl';

export const CompanyAssociateForm = ({
    modalOpen,
    handleCloseModal,
    handleAddAssociate,
    selectedRole,
}) => {
    const associateInit = {
        fullName: "",
        title: "",
        role: selectedRole ? selectedRole : "Auditor",
    };
    const [associate, setAssociate] = useState(associateInit);
    const title = selectedRole
        ? `Add a new ${selectedRole
            .replace(/([A-Z])/g, " $1")
            .trim()} to your company`
        : "Add a new associate to your company";

    const messages = defineMessages({
        a: {
            id: "associateform.Auditor",
            defaultMessage: "Auditor"
        },
        b: {
            id: "associateform.MainDesigner",
            defaultMessage: "Main Designer"
        }
    });
    return (
        <Modal
            isOpen={modalOpen}
            modalTitle={title}
            closeModal={handleCloseModal}
        >
            <Form>
                <FormGroup>
                    <Label><FormattedMessage id="companyAssociate.associate" defaultMessage="Associate's full name:" /></Label>
                    <Input
                        type="text"
                        name="associateName"
                        id="associateName"
                        placeholder=""
                        value={associate.fullName}
                        onChange={(e) =>
                            setAssociate({
                                ...associate,
                                fullName: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <Label><FormattedMessage id="companyAssociate.title" defaultMessage="Associate's title:" /></Label>
                    <Input
                        type="text"
                        name="associateTitle"
                        id="associateTitle"
                        placeholder=""
                        value={associate.title}
                        onChange={(e) =>
                            setAssociate({
                                ...associate,
                                title: e.target.value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <Label><FormattedMessage id="companyAssociate.role" defaultMessage="Associate's role:" /></Label>
                    <Input
                        type="select"
                        name="associateRole"
                        id="associateRole"
                        placeholder=""
                        value={associate.role}
                        disabled={selectedRole ? true : false}
                        style={
                            selectedRole
                                ? { backgroundColor: "LightGrey" }
                                : null
                        }
                        onChange={(e) =>
                            setAssociate({
                                ...associate,
                                role: e.target.value,
                            })
                        }
                    >
                        <FormattedMessage id={messages.a.id} message={messages.a} >
                            {(message) => <option value="Auditor">{message}</option>}
                        </FormattedMessage>
                        <FormattedMessage id={messages.b.id} message={messages.b} >
                            {(message) => <option value="MainDesigner">{message}</option>}
                        </FormattedMessage>

                    </Input>
                </FormGroup>
                <div className="buttons-container">
                    <Button onClick={() => handleAddAssociate(associate)}>
                        <FormattedMessage id="companyAssociate.add" defaultMessage="Add" />

                    </Button>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                </div>
            </Form>
        </Modal>
    );
};
