import React, { useState } from "react";
import Image from "../../components/EurocodeImage/Image";
import avatar from "../../components/UserAvatar/user-image.jpg";
import "./CompanyUserItem.css";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import { useSelector } from "react-redux";

export default function CompanyUserItem(props) {
    const [hover, setHover] = useState(false);
    const user = useSelector((state) => state.user);

    return (
        <div
            className="company-user-item-container"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Image imageId={props.guid} placeholder={avatar} />
            <p align="center" style={{ overflowWrap: "normal" }}>
                {props.firstName} {props.lastName}
            </p>
            {props.editMode &&
                user.role === "CompanyAdmin" &&
                props.guid !== user.guid && (
                    <ActionsBar
                        active={hover}
                        onDelete={() => props.handleRemoveUser(props.guid)}
                    />
                )}
        </div>
    );
}
