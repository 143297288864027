import { defineMessages } from "react-intl";

let names = defineMessages({
    project: { id: "sidebar.projects", defaultMessage: "Projects" },
    generaldata: { id: "sidebar.generaldata", defaultMessage: "General data" },
    loadanalysis: { id: "sidebar.loadanalysis", defaultMessage: "Load analysis" },
    qualitycontrol: { id: "sidebar.qualitycontrol", defaultMessage: "Quality control" },
    structuralanalysis: { id: "sidebar.structuralanalysis", defaultMessage: "Structural analysis" },
    structuraldesign: { id: "sidebar.structuraldesign", defaultMessage: "Structural design" },
    technicaldescription: { id: "sidebar.structuraldescription", defaultMessage: "Structural description" },

    designbasis: { id: "sidebar.designbasis", defaultMessage: "Design basis" },
    geometry: { id: "sidebar.geometry", defaultMessage: "Geometry" },
    loadcombinations: { id: "sidebar.loadcombinations", defaultMessage: "Load combinations" },
    material: { id: "sidebar.material", defaultMessage: "Material" },
    staticalcalculation: { id: "sidebar.staticalcalculation", defaultMessage: "Statical calculation" },


    //Generaldata 
    mainpage: { id: "sidebar.mainpage", defaultMessage: "Main page" },
    Audit: { id: "sidebar.Audit", defaultMessage: "Audit" },
    Designersassociates: { id: "sidebar.Designersassociates", defaultMessage: "Designers & associates" },
    Listofbooks: { id: "sidebar.Listofbooks", defaultMessage: "List of books" },
    Bookcontent: { id: "sidebar.Bookcontent", defaultMessage: "Book content" },
    Designersstatement: { id: "sidebar.Designersstatement", defaultMessage: "Designer's statement" },
    Maindesignersstatement: { id: "sidebar.Maindesignersstatement", defaultMessage: "Main designer's statement" },
    Attachments: { id: "sidebar.Attachments", defaultMessage: "Attachments" },


    //LoadAnalysis
    DensitiesSelfweight: { id: "sidebar.DensitiesSelfweight", defaultMessage: "Densities, Self-weight" },
    Loadsforbuildings: { id: "sidebar.Loadsforbuildings", defaultMessage: "Loads for buildings" },
    Fireactions: { id: "sidebar.Fireactions", defaultMessage: "Fire actions" },
    Snowloads: { id: "sidebar.Snowloads", defaultMessage: "Snow loads" },
    Windactions: { id: "sidebar.Windactions", defaultMessage: "Wind actions" },
    Thermalactions: { id: "sidebar.Thermalactions", defaultMessage: "Thermal actions" },
    Actionsduringexecution: { id: "sidebar.Actionsduringexecution", defaultMessage: "Actions during execution" },
    Extraordinaryactions: { id: "sidebar.Extraordinaryactions", defaultMessage: "Extraordinary actions" },
    Actionsinducedbycranes: { id: "sidebar.Actionsinducedbycranes", defaultMessage: "Actions induced by cranes" },
    Earthloads: { id: "sidebar.Earthloads", defaultMessage: "Earth loads" },
    Seismicactions: { id: "sidebar.Seismicactions", defaultMessage: "Seismic actions" },

    //QualityControl
    Executionofconcretestructures: { id: "sidebar.Executionofconcretestructures", defaultMessage: "Execution of concrete structures" },
    Concrete: { id: "sidebar.Concrete", defaultMessage: "Concrete" },
    Executionofsteel: { id: "sidebar.Executionofsteel", defaultMessage: "Execution of steel and aluminum structures" },
    Designconsiderations: { id: "sidebar.Designconsiderations", defaultMessage: "Design considerations, selection of materials and execution of masonry" },
    Listofnorms: { id: "sidebar.Listofnorms", defaultMessage: "List of norms" },

    //StructuralDesign
    Detaildesign: { id: "sidebar.Detaildesign", defaultMessage: "Detail design" },
    Servicelimitstate: { id: "sidebar.Servicelimitstate", defaultMessage: "Service limit state" },
    Ultimatelimitstate: { id: "sidebar.Ultimatelimitstate", defaultMessage: "Ultimate limit state" },

    //TechnicalDescription
    Beams: { id: "sidebar.Beams", defaultMessage: "Beams" },
    Floorplate: { id: "sidebar.Floorplate", defaultMessage: "Floor plate" },
    Foundations: { id: "sidebar.Foundations", defaultMessage: "Foundations" },
    General: { id: "sidebar.General", defaultMessage: "General" },
    Roof: { id: "sidebar.Roof", defaultMessage: "Roof" },
    Walls: { id: "sidebar.Walls", defaultMessage: "Walls" },

});


export default names;