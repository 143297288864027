import React, { useState, useEffect, Fragment } from "react";
import { Input } from "reactstrap";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";

export const PersonField = (props) => {
    const [values, setValues] = useState({
        name: "",
        pin: "",
        address: "",
    });

    useEffect(() => {
        if (props.startValue) {
            setValues(parseInputField(props.startValue));
        }
    }, [props.startValue]);

    return (
        <Fragment>
            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(values)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 1 : 0}
                type="text"
                placeholder="Full name"
                value={values.name}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) => setValues({ ...values, name: e.target.value })}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="text"
                placeholder="PIN"
                value={values.pin}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) => setValues({ ...values, pin: e.target.value })}
                style={{ margin: "5px 0" }}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 3 : 0}
                type="text"
                placeholder="Address"
                value={values.address}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) =>
                    setValues({ ...values, address: e.target.value })
                }
            />
        </Fragment>
    );
};
