import React, { useState, useEffect, Fragment } from "react";
import { Input } from "reactstrap";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";
import { defineMessages, FormattedMessage } from 'react-intl';


export const AnnexF = (props) => {
    const [values, setValues] = useState({
        type_of_structure_delta: "",
        type_of_structure_child_delta: "",
        maximum_displacement: 0,

    });

    const [IsChildCables, setIsChildCables] = useState(false);
    const [IsChildConcretebridges, setIsChildConcretebridges] = useState(false);
    const [IsChildSteelTower, setIsChildSteelTower] = useState(false);
    useEffect(() => {
        if (props.startValue) {
            setValues(parseInputField(props.startValue));
        }
    }, [props.startValue]);

    const handleChange = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (value == "steel_bridges_lattice_steel_towers")
            setIsChildSteelTower(true);
        else setIsChildSteelTower(false);

        if (value == "concrete_bridges")
            setIsChildConcretebridges(true);
        else setIsChildConcretebridges(false);

        if (value == "cables")
            setIsChildCables(true);
        else setIsChildCables(false);
        setValues({ ...values, type_of_structure_delta: value });
    }

    const handleChangeChild = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        setValues({ ...values, type_of_structure_child_delta: value });
    }

    const handleChangeSetValues = event => {
        const target = event.target;
        const name = target.name;
        const value = target.value;
        if (name == "maximum_displacement") setValues({ ...values, maximum_displacement: value })


    }

    const messagesTranslate = defineMessages({
        a1: {
            id: "annexF.reinforced_concrete_buildings",
            defaultMessage: "Reinforced concrete buildings"
        },
        a2: {
            id: "annexF.steel_buildings",
            defaultMessage: "Steel buildings"
        },
        a3: {
            id: "annexF.mixed_structures_concrete_plus_steel",
            defaultMessage: "Mixed structures concrete plus steel"
        },
        a4: {
            id: "annexF.reinforced_concrete_towers_and_chimneys",
            defaultMessage: "Reinforced concrete towers and chimneys"
        },
        a5: {
            id: "annexF.unlined_wieded_steel_stacks_without_external_thermal_insulation",
            defaultMessage: "Unlined wieded steel stacks without external thermal insulation"
        },
        a6: {
            id: "annexF.unlined_wieded_steel_stacks_with_external_thermal_insulation",
            defaultMessage: "Unlined wieded steel stacks with external thermal insulation"
        },
        a7: {
            id: "annexF.steel_stack_with_one_liner_with_external_thermal_insulation",
            defaultMessage: "Steel stack with one liner with external thermal insulation"
        },
        a8: {
            id: "annexF.steel_stack_with_two_or_more_liner_with_external_thermal_insulation",
            defaultMessage: "Steel stack with two or more liner with external thermal insulation"
        },
        a9: {
            id: "annexF.steel_stack_with_internal_brick_liner",
            defaultMessage: "Steel stack with internal brick liner"
        },
        a10: {
            id: "annexF.steel_stack_with_internal_gunite",
            defaultMessage: "Steel stack with internal gunite"

        },
        a11: {
            id: "annexF.coupled_stacks_without_liner",
            defaultMessage: "Coupled stacks without liner"
        },
        a12: {
            id: "annexF.guyed_steel_stack_without_liner",
            defaultMessage: "Guyed steel stack without liner"
        },
        a13: {
            id: "annexF.steel_bridges_lattice_steel_towers",
            defaultMessage: "Steel bridges lattice steel towers"
        },
        a14: {
            id: "annexF.composite_bridges",
            defaultMessage: "Composite bridges"
        },
        a15: {
            id: "annexF.concrete_bridges",
            defaultMessage: "Concrete bridges"
        },
        a16: {
            id: "annexF.timber_bridges",
            defaultMessage: "Timber bridges"
        },
        a17: {
            id: "annexF.bridges_aluminium_alloys",
            defaultMessage: "Bridges aluminium alloys"
        },
        a18: {
            id: "annexF.bridges_glass_or_fibre_reinforced_plastic",
            defaultMessage: "Bridges glass or fibre reinforced plastic"
        },
        a19: {
            id: "annexF.cables",
            defaultMessage: "Cables"
        }

    });
    const messagesSteel = defineMessages({
        a1: {
            id: "annexF.welded",
            defaultMessage: "Welded"
        },
        a2: {
            id: "annexF.high_resistance_bolts",
            defaultMessage: "High resistance bolts"
        },
        a3: {
            id: "annexF.ordinary_bolts",
            defaultMessage: "Ordinary bolts"
        }

    });

    const messagesbridges = defineMessages({
        a1: {
            id: "annexF.prestressed_without_cracks",
            defaultMessage: "Prestressed without cracks"
        },
        a2: {
            id: "annexF.with_cracks",
            defaultMessage: "With cracks"
        }

    });

    const messagesCables = defineMessages({
        a1: {
            id: "annexF.parallel_cables",
            defaultMessage: "Parallel cables"
        },
        a2: {
            id: "annexF.spiral_cables",
            defaultMessage: "Spiral cables"
        }

    });
    return (
        <Fragment>

            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(values)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />

            <label>Structure type</label>
            <Input
                key={props.jsonKey ? props.jsonKey + 18 : 0}
                type="select"
                value={values.type_of_structure_delta}
                name="type_of_structure_delta"
                onChange={handleChange}

            >
                <FormattedMessage id={messagesTranslate.a1.id} message={messagesTranslate.a1} >
                    {(message) => <option key="reinforced_concrete_buildings" value="reinforced_concrete_buildings">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a2.id} message={messagesTranslate.a2} >
                    {(message) => <option key="steel_buildings" value="steel_buildings">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a3.id} message={messagesTranslate.a3} >
                    {(message) => <option key="mixed_structures_concrete_plus_steel" value="mixed_structures_concrete_plus_steel">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a4.id} message={messagesTranslate.a4} >
                    {(message) => <option key="reinforced_concrete_towers_and_chimneys" value="reinforced_concrete_towers_and_chimneys">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={messagesTranslate.a5.id} message={messagesTranslate.a5} >
                    {(message) => <option key="unlined_wieded_steel_stacks_without_external_thermal_insulation" value="unlined_wieded_steel_stacks_without_external_thermal_insulation">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={messagesTranslate.a6.id} message={messagesTranslate.a6} >
                    {(message) => <option key="unlined_wieded" value="unlined_wieded_steel_stacks_with_external_thermal_insulation">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a7.id} message={messagesTranslate.a7} >
                    {(message) => <option key="steel_stack_with_o" value="steel_stack_with_one_liner_with_external_thermal_insulation">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a8.id} message={messagesTranslate.a8} >
                    {(message) => <option key="steel_stack_with_tw" value="steel_stack_with_two_or_more_liner_with_external_thermal_insulation">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={messagesTranslate.a9.id} message={messagesTranslate.a9} >
                    {(message) => <option key="steel_stack" value="steel_stack_with_internal_brick_liner">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a10.id} message={messagesTranslate.a10} >
                    {(message) => <option key="steel_stack_with" value="steel_stack_with_internal_gunite">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a11.id} message={messagesTranslate.a11} >
                    {(message) => <option key="coupled_stacks_without_liner" value="coupled_stacks_without_liner">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a12.id} message={messagesTranslate.a12} >
                    {(message) => <option key="guyed_steel_stack_without_liner" value="guyed_steel_stack_without_liner">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a13.id} message={messagesTranslate.a13} >
                    {(message) => <option key="steel_bridges_lattice_steel_towers" value="steel_bridges_lattice_steel_towers">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a14.id} message={messagesTranslate.a14} >
                    {(message) => <option key="composite_bridges" value="composite_bridges">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a15.id} message={messagesTranslate.a15} >
                    {(message) => <option key="concrete_bridges" value="concrete_bridges">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a16.id} message={messagesTranslate.a16} >
                    {(message) => <option key="timber_bridges" value="timber_bridges">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a17.id} message={messagesTranslate.a17} >
                    {(message) => <option key="bridges_aluminium_alloys" value="bridges_aluminium_alloys">{message}</option>}
                </FormattedMessage>

                <FormattedMessage id={messagesTranslate.a18.id} message={messagesTranslate.a18} >
                    {(message) => <option key="bridges_glass_or_fibre_reinforced_plastic" value="bridges_glass_or_fibre_reinforced_plastic">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesTranslate.a19.id} message={messagesTranslate.a19} >
                    {(message) => <option key="cables" value="cables">{message}</option>}
                </FormattedMessage>







            </Input>

            <br hidden={!IsChildSteelTower ? true : false} />
            <label hidden={!IsChildSteelTower ? true : false}><FormattedMessage id="annexF.stell" defaultMessage="Steel bridges lattice steel towers type" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 19 : 0}
                type="select"
                hidden={!IsChildSteelTower ? true : false}
                value={values.type_of_structure_child_delta}
                name="type_of_structure_child_delta"
                onChange={handleChangeChild}

            >
                <FormattedMessage id={messagesSteel.a1.id} message={messagesSteel.a1} >
                    {(message) => <option key="welded" value="welded">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesSteel.a2.id} message={messagesSteel.a2} >
                    {(message) => <option key="high_resistance_bolts" value="high_resistance_bolts">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesSteel.a3.id} message={messagesSteel.a3} >
                    {(message) => <option key="ordinary_bolts" value="ordinary_bolts">{message}</option>}
                </FormattedMessage>



            </Input>

            <br hidden={!IsChildConcretebridges ? true : false} />
            <label hidden={!IsChildConcretebridges ? true : false}><FormattedMessage id="annexF.Concrete" defaultMessage="Concrete bridges type" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 20 : 0}
                type="select"
                hidden={!IsChildConcretebridges ? true : false}
                value={values.type_of_structure_child_delta}
                name="type_of_structure_child_delta"
                onChange={handleChangeChild}

            >
                <FormattedMessage id={messagesbridges.a1.id} message={messagesbridges.a1} >
                    {(message) => <option key="prestressed_without_cracks" value="prestressed_without_cracks">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesbridges.a2.id} message={messagesbridges.a2} >
                    {(message) => <option key="with_cracks" value="with_cracks">{message}</option>}
                </FormattedMessage>

            </Input>

            <br hidden={!IsChildCables ? true : false} />
            <label hidden={!IsChildCables ? true : false}><FormattedMessage id="annexF.Cables" defaultMessage="Cables type" /></label>
            <Input
                key={props.jsonKey ? props.jsonKey + 21 : 0}
                type="select"
                hidden={!IsChildCables ? true : false}
                value={values.type_of_structure_child_delta}
                name="type_of_structure_child_delta"
                onChange={handleChangeChild}

            >
                <FormattedMessage id={messagesCables.a1.id} message={messagesCables.a1} >
                    {(message) => <option key="parallel_cables" value="parallel_cables">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id={messagesCables.a2.id} message={messagesCables.a2} >
                    {(message) => <option key="spiral_cables" value="spiral_cables">{message}</option>}
                </FormattedMessage>



            </Input>
            <br />
            <label><FormattedMessage id="annexb.x1" defaultMessage="x1 - maximum displacement due to self-weight applied in the vibration direction (m)" /> </label>
            <Input
                key={props.jsonKey ? props.jsonKey + 22 : 0}
                type="number"
                placeholder=""
                value={values.maximum_displacement}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                name="maximum_displacement"
                onChange={handleChangeSetValues}
                style={{ margin: "2px 0" }}
            />

        </Fragment>
    );
};

export default AnnexF