import React, { useState, useRef, useEffect } from "react";
import { Button, Collapse } from "reactstrap";
import { makeInputFields } from "./InputFieldsHelper";
import { ReactComponent as Delete } from "./Delete.svg";
import { ReactComponent as Up } from "./Up.svg";
import { ReactComponent as Down } from "./Down.svg";

function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

function newGuid() {
    return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-4" +
        S4().substr(0, 3) +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
    ).toLowerCase();
}

const ListInputField = ({ input, savedValues }) => {
    const [fields, setFields] = useState([]);
    const [showFields, setShowFields] = useState(false);
    const [formGuid, setFormGuid] = useState(newGuid());
    const containerRef = useRef();

    let refreshFields = () => setFormGuid(newGuid());

    let addField = () => {
        let count = fields.length;
        let newInput = {
            ...input,
            key: `${input.key}.[${count}]`,
            id: newGuid(),
        };
        setFields(fields.concat(newInput));
    };

    let getInputValues = () => {
        let result = {};

        let inputs = containerRef.current.getElementsByTagName("input");

        for (let input of inputs) {
            result[input.name] = input.value;
        }
        return result;
    };

    let reIndex = (array) => {
        let newArray = array.map((f, i) => {
            return {
                ...f,
                key: `${input.key}.[${i}]`,
            };
        });
        return newArray;
    };

    let removeField = (target) => {
        let newFields = fields.filter((f) => f.id !== target);
        if (newFields.length !== fields.length) {
            let values = getInputValues();
            newFields = newFields.map((f) => {
                return { ...f, startValue: values[f.key] };
            });
            newFields = reIndex(newFields);
            setFields(newFields);
            refreshFields();
        }
    };

    useEffect(() => {
        if (savedValues) {
            let startValues = [];
            for (let i = 0; i < savedValues.length; i++) {
                let newInput = {
                    ...input,
                    key: `${input.key}.[${i}]`,
                    id: newGuid(),
                    startValue: savedValues[i],
                };
                startValues.push(newInput);
            }
            setFields(fields.concat(startValues));
        }
    }, [savedValues]);

    return (
        <div className="list-input-container">
            <div
                className="list-toggle noselect"
                onClick={() => setShowFields(!showFields)}
            >
                {`List with ${fields.length} elements`}
                {showFields ? <Up /> : <Down />}
            </div>
            <Collapse isOpen={showFields}>
                <div
                    className="list-input-fields-container"
                    ref={containerRef}
                    key={formGuid}
                >
                    {fields.map((f) => (
                        <div
                            key={f.key}
                            className={
                                "list-input-item" +
                                (f.type === "Bool" ? " check" : "")
                            }
                        >
                            {makeInputFields(f, f.startValue)}
                            <Delete onClick={removeField.bind(this, f.id)} />
                        </div>
                    ))}
                    <div className="add-button-container">
                        <Button onClick={addField}>Add</Button>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default ListInputField;
