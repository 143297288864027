import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import "./Layout.css";

export default (props) => (
    <div className="layout-container">
        <Sidebar />
        <div id="body">
            <header>
                <Header />
            </header>
            <main className="main">{props.children}</main>
        </div>
    </div>
);
