import React from "react";
import { Route, Switch } from "react-router";
import Home from "./containers/Home/Home";
import Layout from "./containers/Layout/Layout";
import User from "./containers/EditUser/EditUser";
import Projects from "./containers/Projects/Projects";
import Project from "./containers/Project/Project";
import ProjectDetails from "./containers/ProjectDetails/ProjectDetails";
import ProjectEdit from "./containers/ProjectEdit/ProjectEdit";
import NewProject from "./containers/Projects/NewProject";
import Sections from "./containers/Sections/Sections";
import ChapterCreate from "./containers/Chapter/ChapterCreate";
import Section from "./containers/Section/Section";
import DynamicFormContainer from "./containers/DynamicFormContainer/DynamicFormContainer";
import Company from "./containers/Company/Company";
import "./App.css";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { LandingPage } from "./containers/LandingPage/LandingPage";

export default ({ location }) => (
    <Layout>
        <TransitionGroup>
            <CSSTransition
                key={location.pathname}
                timeout={{ enter: 500, exit: 0 }}
                classNames="slide"
                unmountOnExit
            >
                <Switch location={location}>
                    <Route exact path="/calculations" component={Home} />
                    <Route exact path="/user" component={User} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/" component={LandingPage} />
                    <Route path="/project" component={Project} />
                    <Route path="/projectDetails" component={ProjectDetails} />
                    <Route path="/projectEdit" component={ProjectEdit} />
                    <Route path="/projectNew" component={NewProject} />
                    <Route path="/sections" component={Sections} />
                    <Route path="/addChapters" component={ChapterCreate} />
                    <Route path="/section" component={Section} />
                    <Route path="/company" component={Company} />
                    <Route component={DynamicFormContainer} />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    </Layout>
);
