import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import { useHistory } from "react-router-dom";
import Image from "../EurocodeImage/Image";
import defaultPicture from "./project.png";
import { deleteProject, getProjectUsers } from "../../services/projectsService";
import ActionsBar from "../ActionsBar/ActionsBar";
import { SetActiveProject } from "../../actions/projectActions";
import "./ProjectAvatar.css";
import Tooltip from "../Tooltip/Tooltip";
import avatar from "../UserAvatar/user-image.jpg";

const ProjectAvatar = props => {
    const user = useSelector(state => state.user);
    const [actionsBarOpen, setActionsBarOpen] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activeId, setActiveId] = useState();
    const dispatch = useDispatch();
    const history = useHistory();
    const [projectUsers, setProjectUsers] = useState([]);

    useEffect(() => {
        getProjectUsers(user, props.id).then(u => setProjectUsers(u));
    }, [user, props.id]);

    let onProjectClick = props => {
        dispatch(SetActiveProject(props));
        sessionStorage.setItem("activeProject", JSON.stringify(props));
        history.push(`/project/${props.id}`);
    };

    let onDeleteProject = id => {
        deleteProject(user, id).then(closeDeleteDialog).then(props.refresh);
    };

    let openDeleteDialog = id => {
        setActiveId(id);
        setDeleteDialogOpen(true);
    };

    let closeDeleteDialog = setDeleteDialogOpen.bind(this, false);

    return (
        <Fragment>
            <Card
                className="project-card"
                onMouseOver={() => setActionsBarOpen(props.id)}
                onMouseLeave={setActionsBarOpen.bind(this, null)}
                onClick={() => onProjectClick(props)}
            >
                <CardHeader tag="h5">{props.name}</CardHeader>
                <Image imageId={props.projectId} placeholder={defaultPicture} />
                <hr />
                <CardBody>
                    <p>{props.desc}</p>
                </CardBody>
                <CardFooter>
                    {projectUsers.map(appUser => (
                        <div key={appUser.guid} className="tooltips">
                            <Image imageId={appUser.guid} placeholder={avatar} />
                            <Tooltip
                                text={`${appUser.firstName} ${appUser.lastName}`}
                                placement="top"
                            />
                        </div>
                    ))}
                </CardFooter>
                <div className="project-card-actions">
                    {user.role === ("CompanyAdmin" || "Admin") && (
                        <ActionsBar
                            active={actionsBarOpen === props.id}
                            onDelete={e => {
                                e.stopPropagation();
                                openDeleteDialog(props.id);
                            }}
                        />
                    )}
                </div>
            </Card>
            <Modal isOpen={deleteDialogOpen} closeModal={closeDeleteDialog}>
                <p>Are you sure you want to delete this project?</p>
                <div className="buttons-modal">
                    <Button onClick={() => onDeleteProject(activeId)}>Yes</Button>
                    <Button onClick={closeDeleteDialog}>No</Button>
                </div>
            </Modal>
        </Fragment>
    );
};

export default ProjectAvatar;
