import { getAuthorizationProperties } from './authorizationHelper';

export function downloadWordDocument(user, guid) {
    return fetch(`wordDocument/project/${guid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token),
    }).then(response => response.text())
        .then(html => {
            var tab = window.open('about:blank', '_blank');
            tab.document.write(html);
            tab.document.close();
        })
        .catch(c => { console.error('Error loading word document!') })
}