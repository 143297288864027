import React from "react";
import Icons from "../../containers/Sidebar/SidebarIcons.js";
import { ReactComponent as ChevronRight } from "./ChevronRight.svg";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import "./SubnavItem.css";

function isEllipsisActive(element) {
    return element.offsetWidth < element.scrollWidth;
}

function calculateTransition(element) {
    let transition = element.textContent.length > 30 ? "left 4s, width 4s" : null;
    transition = element.textContent.length > 50 ? "left 6s, width 6s" : transition;
    return transition;
}

function scrollIfOverflow(event) {
    if (isEllipsisActive(event.target)) {
        event.target.style.transition = calculateTransition(event.target);
        let distance = -(event.target.scrollWidth - event.target.offsetWidth) - 10;
        event.target.style.left = `${distance}px`;
        event.target.style.width = "1000px";
    }
}

function returnOverflow(event) {
    if (event.target.style.width === "1000px") {
        event.target.style.transition = "left 0s, width 0s";
        event.target.style.left = "0px";
        event.target.style.width = "145px";
    }
}

function SidebarItem({ text, link, icon, active, onClick }) {
    const intl = useIntl();
    let className = "subnav-item-container" + (active ? " active" : "");

    return (
        <Link to={link} className={className} onClick={onClick}>
            <div className="subnav-item">
                <div className="subnav-item-icon">
                    <Icons name={icon} />
                </div>
                <div
                    className="subnav-item-text"
                    onMouseEnter={scrollIfOverflow.bind(this)}
                    onMouseLeave={returnOverflow.bind(this)}
                >
                    <p>{intl.formatMessage(text)}</p>
                </div>
                <ChevronRight className="subnav-chevron" />
            </div>
            <hr className="subnav-divider" />
        </Link>
    );
}

export default SidebarItem;
