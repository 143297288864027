import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import login from "./loginReducer";
import user from "./userReducer";
import sidebar from "./sidebarReducer";
import rootSaga from "../sagas/root";
import forms from "./formsReducer";
import activeProject from "./projectReducer";
import images from "./imageReducer";
import alertMessage from "./alertMessageReducer";

export default function configureStore(history, initialState) {
    const reducers = {
        login,
        user,
        sidebar,
        forms,
        router: connectRouter(history),
        activeProject,
        images,
        alertMessage,
    };

    const sagaMiddleware = createSagaMiddleware();

    const middleware = [sagaMiddleware, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === "development";
    if (
        isDevelopment &&
        typeof window !== "undefined" &&
        window.devToolsExtension
    ) {
        enhancers.push(window.devToolsExtension());
    }

    const rootReducer = combineReducers({
        ...reducers,
    });

    const result = createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );

    sagaMiddleware.run(rootSaga);

    return result;
}
