import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import enMessages from '../i18n/locales/en.json'
import hrMessages from '../i18n/locales/hr.json'

const DEFAULT_LOCALE = 'en'

let langMessages = {
  'en': enMessages,
  'hr': hrMessages
}

const mapStateToProps = (state) => {
    let locale, messages;

    locale = state.user.language ? state.user.language: DEFAULT_LOCALE;
    messages = langMessages[locale];

    return { locale: locale, messages: messages };
}

export default connect(mapStateToProps)(IntlProvider);
