export const CLEAR_ALERT_MSG = "CLEAR_ALERT_MSG";
export const SET_ALERT_MSG = "SET_ALERT_MSG";

export function ClearAlertMessage() {
    return { type: CLEAR_ALERT_MSG };
}

export function SetAlertMessage(message) {
    return { type: SET_ALERT_MSG, message };
}
