import { SET_FORMS } from '../actions/formActions';

const initialState = [];

function formsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_FORMS:
            return action.forms 
        default:
            return state
    }
}

export default formsReducer;
