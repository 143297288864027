import { getAuthorizationProperties } from "./authorizationHelper";

export function edit(user, firstName, lastName) {
    const requestOptions = {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({ firstName, lastName }),
    };

    return fetch("users/" + user.guid, requestOptions);
}

export function refresh(user) {
    const requestOptions = {
        method: "GET",
        ...getAuthorizationProperties(user.token),
    };

    return fetch("users/refresh", requestOptions).then((response) =>
        response.json()
    );
}

export default {
    edit,
    refresh,
};
