import React from "react";
import { ReactComponent as Delete } from "./Delete.svg";
import "./AlertMessage.css";

const AlertMessage = ({ text, close }) => {
    return (
        <div className="alert-msg-container noselect">
            <p title={text}>{text}</p>
            <Delete onClick={close ? close : null} />
        </div>
    );
};

export default AlertMessage;
