import React from "react";
import "./Loading.css";
import { ReactComponent as SpinnerGray } from "./Spinner_gray.svg";
import { ReactComponent as SpinnerLoading } from "./Spinner_loading.svg";

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="centered-element">
                <SpinnerGray />
                <SpinnerLoading className="loader" />
            </div>
        </div>
    );
};

export default Loading;
