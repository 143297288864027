import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import DynamicForm from "../../components/DynamicForm/DynamicForm";
import { setForms } from "../../actions/formActions";
import { getAll } from "../../services/formsService";
import { getDefaultCalculation } from "../../services/calculationService";
import { Redirect } from "react-router";
import "./DynamicFormContainer.css";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { DisableProjectDropdown } from "../../components/DisableProjectDropdown/DisableProjectDropdown";
import {
    SetAlertMessage,
    ClearAlertMessage,
} from "../../actions/alertMessageActions";

function DynamicFormContainer(props) {
    const [formInfo, setFormInfo] = useState(null);
    const [calculation, setCalculation] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const forms = useSelector((state) => state.forms);
    const user = useSelector((state) => state.user);
    const activeProject = useSelector((state) => state.activeProject.id);
    const alertMessage = useSelector((state) => state.alertMessage);

    useEffect(() => {
        if (forms.length === 0) {
            getAll(user).then(result => dispatch(setForms(result)));
        } else {
            let currentPath = location.pathname.replace("/", "");
            let fi = forms.find(
                (f) => f.formId === currentPath
            );
            setFormInfo(fi);
        }
    }, [forms, user, location]);

    useEffect(() => {
        if (formInfo && formInfo.hasDefaultCalculation && !calculation && !location.state) {
            getDefaultCalculation(user, formInfo.formId, activeProject)
                .then(setCalculation);
        } else if (!formInfo || !formInfo.hasDefaultCalculation) {
            setCalculation(null);
        }
    }, [formInfo, calculation, location, user, activeProject]);


    let getCalculation = () => {
        if (location.state && location.state.calculation) {
            return location.state.calculation;
        } else {
            return calculation;
        }
    }

    let getCurrentPath = () => {
        return location.pathname.replace("/", "");
    }

    let content = null;

    if (formInfo) {
        let allowed = user.hasFullAccess || formInfo.isFree;
        if (!allowed) {
            dispatch(SetAlertMessage(`You need full access in order to use ${formInfo.formId} calculations!`));
        }

        let targetCalculation = getCalculation();

        if (formInfo.hasDefaultCalculation && !targetCalculation) {
            content = <p>Loading!</p>
        } else if (allowed) {
            content = <DynamicForm
                id={getCurrentPath()}
                calculation={targetCalculation}
                targetChapterId={location.state ? location.state.targetChapterId : null}
            />
        } else {
            content = <div>
                <AlertMessage
                    text={alertMessage}
                    close={() => dispatch(ClearAlertMessage())}
                />{" "}
                <Redirect to={{ pathname: "/Home" }} />
            </div>
        }
    } else {
        content = <p>Form for { getCurrentPath() } not yet implemented!</p>
    }

    return (
        <div className="dynamic-form-container">
            <DisableProjectDropdown />
            {content}
        </div>
    );
}

export default DynamicFormContainer;
