import React from "react";
import Image from "../../components/EurocodeImage/Image";
import CompanyUserItem from "./CompanyUserItem";
import { FormattedMessage } from 'react-intl';
export const CompanyDetails = ({
    imageId,
    placeholder,
    hasFullAccess,
    company,
    employees,
    associates,
}) => {
    return (
        <div className="company-content-container">
            <div className="company-info-container">
                <div className="company-image-container">
                    <Image imageId={imageId} placeholder={placeholder} />
                </div>
                <h4><FormattedMessage id="companydetails.companyinfo" defaultMessage="Company info" /></h4>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.address" defaultMessage="Address:" /></p>
                            </td>
                            <td>
                                <p>{company.address}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.postalcode" defaultMessage="Postal Code:" /></p>
                            </td>
                            <td>
                                <p>{company.postalCode}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.city" defaultMessage="City:" /></p>
                            </td>
                            <td>
                                <p>{company.city}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.country" defaultMessage="Country:" /></p>
                            </td>
                            <td>
                                <p>{company.country}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.email" defaultMessage="Email:" /></p>
                            </td>
                            <td>
                                <p>{company.email}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.telephone" defaultMessage="Telephone:" /></p>
                            </td>
                            <td>
                                <p>{company.telephone}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.mobphone" defaultMessage="Mobile phone:" /></p>
                            </td>
                            <td>
                                <p>{company.mobilePhone}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.fax" defaultMessage="Fax:" /></p>
                            </td>
                            <td>
                                <p>{company.fax}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.website" defaultMessage="Website:" /></p>
                            </td>
                            <td>
                                <p>{company.website}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.vat" defaultMessage="VAT:" /></p>
                            </td>
                            <td>
                                <p>{company.vat}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.iban" defaultMessage="IBAN:" /></p>
                            </td>
                            <td>
                                <p>{company.iban}</p>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <p><FormattedMessage id="companydetails.has" defaultMessage="Has full access:" /></p>
                            </td>
                            <td>
                                <p>{hasFullAccess ? "✔" : "✖"}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <h4><FormattedMessage id="companydetails.employees" defaultMessage="Company employees" /></h4>
                <div className="company-users-container">
                    {employees.map((item) => (
                        <div style={{ marginRight: 35 }} key={item.guid}>
                            <CompanyUserItem {...item} />
                            <br />
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <h4><FormattedMessage id="companydetails.associates" defaultMessage="Company associates" /></h4>
                <div className="company-users-container">
                    {associates.map((item) => (
                        <div key={item.guid}>
                            <p>
                                {item.fullName}, <em>{item.title}</em> -{" "}
                                {item.role.replace(/([A-Z])/g, " $1").trim()}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
