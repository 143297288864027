import React, { useState } from "react";
import Tooltip from "./Tooltip.js";
import InfoIcon from "./info.svg";
import Modal from "../../containers/EurocodeModal/EurocodeModal";

const InfoTooltip = ({ placement, content }) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className="tooltips" style={{ marginLeft: "15px" }}>
            <img
                width="35px"
                src={InfoIcon}
                alt="Info icon"
                onClick={() => setModalOpen(!modalOpen)}
            />
            <Tooltip text={content} placement={placement} />
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
            >
                <div className="tooltips-modal">
                    <img
                        width="35px"
                        src={InfoIcon}
                        alt="Info icon"
                        style={{ marginRight: "10px" }}
                    />
                    {content}
                </div>
            </Modal>
        </div>
    );
};

export default InfoTooltip;
