import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import {
    updateParagraph,
    deleteParagraph,
} from "../../services/paragraphsService";
import ParagraphEditor from "../ParagraphEditor/ParagraphEditor";
import ActionsBar from "../ActionsBar/ActionsBar";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import { moveChapter } from "../../services/chaptersService";
import "./Paragraph.css";

function Paragraph(props) {
    const [modalOpen, setModalOpen] = useState(false);
    const [actionsBarOpen, setActionsBarOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const user = useSelector((state) => state.user);

    let closeModal = setModalOpen.bind(this, false);
    let openModal = setModalOpen.bind(this, true);

    let showActions = setActionsBarOpen.bind(this, true);
    let hideActions = setActionsBarOpen.bind(this, false);

    let openDeleteDialog = setDeleteDialogOpen.bind(this, true);
    let closeDeleteDialog = setDeleteDialogOpen.bind(this, false);

    let saveChanges = (newData) => {
        let data = { ...props, name: newData.name, content: newData.content };
        updateParagraph(user, props.id, data).then(props.refresh);
    };

    let removeParagraph = () => {
        deleteParagraph(user, props.id).then(props.refresh);
    };

    let moveChapterUp = () => {
        moveChapter(user, { id: props.id, type: 0, content: props }, "up").then(
            props.refresh
        );
    };

    let moveChapterDown = () => {
        moveChapter(
            user,
            { id: props.id, type: 0, content: props },
            "down"
        ).then(props.refresh);
    };

    return (
        <div
            className="paragraph-container"
            onMouseOver={showActions}
            onMouseLeave={hideActions}
        >
            <p>{props.name}:</p>
            <div
                className="paragraph-content-container remove-all-styles"
                dangerouslySetInnerHTML={{ __html: props.content }}
            />
            <div className="paragraph-actions-container">
                <ActionsBar
                    active={actionsBarOpen}
                    onEdit={openModal}
                    onDelete={openDeleteDialog}
                    onMoveUp={moveChapterUp}
                    onMoveDown={moveChapterDown}
                />
            </div>

            <Modal
                isOpen={modalOpen}
                modalTitle={`Edit ${props.name}`}
                closeModal={closeModal}
            >
                <ParagraphEditor
                    name={props.name}
                    content={props.content}
                    onSave={saveChanges}
                    close={closeModal}
                />
            </Modal>

            <Modal isOpen={deleteDialogOpen} closeModal={closeDeleteDialog}>
                <p>{`Are you sure you want to delete paragraph ${props.name}`}</p>
                <div className="buttons-container">
                    <Button onClick={removeParagraph}>Yes</Button>
                    <Button onClick={closeDeleteDialog}>No</Button>
                </div>
            </Modal>
        </div>
    );
}
export default Paragraph;
