import { SET_USER, CLEAR_USER, SET_LANGUAGE } from "../actions/authentificationActions";
import { USER_INFO } from "../actions/userActions";

var savedState = sessionStorage.getItem("user")
    ? JSON.parse(sessionStorage.getItem("user"))
    : null;

const initialState = {
    guid: null,
    username: null,
    email: null,
    token: null,
    language: "en",
    firstName: null,
    lastName: null,
};

function userReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case SET_USER:
            return { ...action.user };
        case CLEAR_USER:
            return { ...initialState };
        case USER_INFO:
            return {
                ...state,
                firstName: action.userInfo.firstName,
                lastName: action.userInfo.lastName,
            };
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language,
            };
        default:
            return state;
    }
}

export default userReducer;
