import React from "react";
import { Button, Spinner } from "reactstrap";

export const EurocodeButton = (props) => {
    return (
        <Button {...props}>
            {props.isloading === "true" ? <Spinner /> : props.children}
        </Button>
    );
};
