import { getAuthorizationProperties } from "./authorizationHelper";

export function previewSection(user, guid) {
    return fetch(`preview/section/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token),
    })
        .then((response) => response.text())
        .then((html) => {
            var tab = window.open("about:blank", "_blank");
            tab.document.write(html);
            tab.document.close();
        })
        .catch((c) => {
            console.error("Error loading preview!");
        });
}

export function previewProject(user, guid) {
    return fetch(`preview/project/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token),
    })
        .then((response) => response.text())
        .then((html) => {
            var tab = window.open("about:blank", "_blank");
            tab.document.write(html);
            tab.document.close();
        })
        .catch((c) => {
            console.error("Error loading preview!");
        });
}
