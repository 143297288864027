import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { imageExist } from "../../services/imageService";
import placeholder from "./placeholder.jpg";
import memoize from "memoize-one";
import { AddImageGuid } from "../../actions/imageAction";

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: props.placeholder ? props.placeholder : placeholder,
            imageExists: false,
            checked: false,
        };

        this.checkImageExists = this.checkImageExists.bind(this);

        this.getTimestapAddon = memoize((timestampsList, imageGuid) => {
            let timestampObject = timestampsList.find(
                (item) => item.guid === imageGuid
            );
            if (timestampObject) {
                return `?timestamp=${timestampObject.timestamp}`;
            }
            return "";
        });
    }

    checkImageExists() {
        let inRedux = this.props.images.some(
            (guid) => guid === this.props.imageId
        );
        if (!inRedux) {
            imageExist(this.props.imageId).then((response) => {
                if (response.ok) {
                    this.setState({
                        image: `/api/Images/${this.props.imageId}`,
                        imageExists: true,
                    });
                    this.props.actions.AddImageGuid(this.props.imageId);
                } else {
                    this.setState({
                        image: this.props.placeholder,
                        imageExists: false,
                    });
                }
            });
        } else {
            if (inRedux) {
                this.setState({
                    image: `/api/Images/${this.props.imageId}`,
                    imageExists: true,
                });
            } else {
                this.setState({
                    image: this.props.placeholder,
                    imageExists: false,
                });
            }
        }
    }

    handleCheck(val) {
        return this.props.images.some((item) => val === item);
    }

    componentDidMount() {
        if (!this.state.checked) {
            this.setState({
                checked: true,
            });
            this.checkImageExists();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.imageId !== this.props.imageId) {
            this.checkImageExists();
        }
        if (
            !this.state.imageExists &&
            this.getTimestapAddon(this.props.timestamps, this.props.imageId)
        ) {
            this.checkImageExists();
        }
    }

    render() {
        const timestampAddon = this.getTimestapAddon(
            this.props.timestamps,
            this.props.imageId
        );

        return (
            <img
                id={this.props.id}
                src={`${this.state.image}${timestampAddon}`}
                alt={placeholder}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const images = state.images.guids;
    const timestamps = state.images.timestamps;
    return { images, timestamps };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ AddImageGuid }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Image);
