import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Input, Table } from "reactstrap";
import { getCompany, addAddPaymentInfo, deleteCreditCardInfo, getCompanyPayments, getCompanyPaymentMethod } from "../../services/companyService";
import { subscribe, unsubscribe } from "../../actions/companyActions";
import { ClearAlertMessage, SetAlertMessage } from "../../actions/alertMessageActions";
import { refreshUser } from "../../actions/userActions";
import CardSection from "../../components/CardSection/CardSection";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import Tooltip from "../../components/Tooltip/Tooltip";
import './CompanyPayments.css';
import { FormattedMessage } from 'react-intl';


function capitalizeFirstLetter(string) {
    if (string)
        return string.charAt(0).toUpperCase() + string.slice(1);
    else 
        return '';
}

const CompanyPayments = () => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const isSubscribing = useSelector(state => state.login.currentlySubscribing);
    const [company, setCompany] = useState(null);
    const [creditCardTermsAccepted, setCreditCardTermsAccepted] = useState(false);
    const [subscriptionTermsAccepted, setSubscriptionTermsAccepted] = useState(false);
    const [creditCardError, setCreditCardError] = useState('');
    const [showCreditCardForm, setShowCreditCardForm] = useState(false);
    const [showActivateModal, setShowActivateModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [showDeleteCreditCardModal, setShowDeleteCreditCardModal] = useState(false);
    const [saveCardButtonDisabled, setSaveCardButtonDisabled] = useState(false);
    const [activateButtonDisabled, setActivateButtonDisabled] = useState(false);
    const [deactivateButtonDisabled, setDeactivateButtonDisabled] = useState(false);
    const [paymentsCountLimit, setPaymentsCountLimit] = useState(10);
    const [payments, setPayments] = useState([]);

    const openActivateModal = setShowActivateModal.bind(this, true);
    const openDectivateModal = setShowDeactivateModal.bind(this, true);
    const closeDectivateModal = setShowDeactivateModal.bind(this, false);
    const closeActivateModal = () => {
        setShowActivateModal(false);
        setSubscriptionTermsAccepted(false);
    }
    const openDeleteCreditCardModal = setShowDeleteCreditCardModal.bind(this, true);
    const closeDeleteCreditCardModal = setShowDeleteCreditCardModal.bind(this, false);

    useEffect(() => {
        if (user != null) {
            getCompanyPayments(user, paymentsCountLimit).then(setPayments);
        }
    }, [user, paymentsCountLimit]);

    useEffect(() => {
        if (!isSubscribing) {
            closeActivateModal();
            setShowDeactivateModal(false);
        }
    }, [isSubscribing]);

    let subscriptionStatus = user.subscriptionStatus;

    if (company === null) {
        getCompany(user, user.companyId).then(setCompany);
    } else {
        subscriptionStatus = (company.hasCreditCardInfo || user.hasFullAccess) ? subscriptionStatus : <FormattedMessage id="companyPayments.creditcard" defaultMessage="Need to setup credit card" />;

        if (!company.hasCreditCardInfo && !showCreditCardForm && !user.hasFullAccess) {
            setShowCreditCardForm(true);
        } else if (company.hasCreditCardInfo && showCreditCardForm) {
            setShowCreditCardForm(false);
        }
    }

    const handleCreditCardSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setSaveCardButtonDisabled(true);

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement)
        });

        if (result.error) {
            setCreditCardError(result.error);
            setSaveCardButtonDisabled(false);
        } else {
            addAddPaymentInfo(user, company.id, result.paymentMethod.id)
                .then(() => { return getCompany(user, user.companyId) })
                .then(setCompany)
                .then(() => {
                    setSaveCardButtonDisabled(false)
                    setCreditCardError('')
                })
        }
    };

    const handleCreditCardDelete = async (event) => {
        deleteCreditCardInfo(user, company.id)
            .then(() => { return getCompany(user, user.companyId) })
            .then(setCompany)
            .then(closeDeleteCreditCardModal)
    }

    const handleActivate = async (event) => {
        setActivateButtonDisabled(true);
        setDeactivateButtonDisabled(false);
        dispatch(subscribe(user));
        setPayments([]);
    }

    const handleDectivate = async (event) => {
        setDeactivateButtonDisabled(true);
        setActivateButtonDisabled(false);
        dispatch(unsubscribe(user));
        setPayments([]);
    }

    const togleCreditCardTermsAccepted = async (event) => {
        setCreditCardTermsAccepted(!creditCardTermsAccepted);
    }

    const togleSubscriptionTermsAccepted = async (event) => {
        setSubscriptionTermsAccepted(!subscriptionTermsAccepted);
    }

    const confirmPayment = async (clientSecret, status) => {
        dispatch(ClearAlertMessage());
        let data = undefined;
        if (status === "requires_payment_method") {
            let payment_method = await getCompanyPaymentMethod(user);
            data = { payment_method };
        }
        let result = await stripe.confirmCardPayment(clientSecret, data);
        if (!result.error) {
            dispatch(SetAlertMessage(<FormattedMessage id="companyPayments.paymentSucc" defaultMessage="Payment successful!" />));
        } else {
            dispatch(SetAlertMessage(result.error.message));
        }
        dispatch(refreshUser());
        setPayments([]);
    }

    const paymentsLoadMore = () => {
        setPaymentsCountLimit(paymentsCountLimit + 10);
    }

    return (
        <div className="company-payments-container">
            <h3><FormattedMessage id="companyPayments.companyPay" defaultMessage="Company payments" /></h3>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p><FormattedMessage id="companyPayments.subStatus" defaultMessage="Subscription status:" /></p>
                        </td>
                        <td>
                            <p><strong>{subscriptionStatus}</strong></p>
                        </td>
                        <td>
                            {user.nextPayment && <p><FormattedMessage id="companyPayments.nextpayment" defaultMessage="Next payment:" /></p>}
                        </td>
                        <td>
                            {user.nextPayment && <p><strong>
                                {(new Date(user.nextPayment)).toLocaleDateString('de-DE')}
                            </strong></p>}
                        </td>
                    </tr>
                </tbody>
            </table>

            { showCreditCardForm ? <form onSubmit={handleCreditCardSubmit}>
                <CardSection />
                <div className="checkbox-container">
                    <Input type='checkbox' checked={creditCardTermsAccepted} onChange={togleCreditCardTermsAccepted} />
                    <p><FormattedMessage id="companyPayments.accept" defaultMessage="I accept the terms and conditions, and allow Eurocode to store my credit card information." /></p>
                </div>
                {creditCardError && <p className="error-text">{creditCardError}</p>}
                <Button type="submit" disabled={!stripe || saveCardButtonDisabled || !creditCardTermsAccepted}>
                    <FormattedMessage id="companyPayments.savecard" defaultMessage="Save card" />
                    
                </Button>
            </form> : <div>
                    {(company && company.hasCreditCardInfo) &&
                        <p><FormattedMessage id="companyPayments.cardinfo" defaultMessage="Credit card info saved: " /> {`${company ? capitalizeFirstLetter(company.cardBrand) : null} card ending with ${company ? company.lastDigits : null}`}</p>
                    }
                    <div className="subscription-buttons">
                        {(subscriptionStatus === 'active' || subscriptionStatus === 'incomplete') && <Button onClick={openDectivateModal}>Cancel subscription</Button>}
                        {(!user.hasFullAccess && subscriptionStatus !== 'active' && subscriptionStatus !== 'incomplete') &&
                            <Button onClick={openActivateModal}><FormattedMessage id="companyPayments.activatesub" defaultMessage="Activate subscription" /></Button>
                        }
                        {(company && company.hasCreditCardInfo) &&
                            <div className="tooltips">
                            <Button onClick={openDeleteCreditCardModal} disabled={user.subscriptionStatus !== "no_subscription"}>
                                <FormattedMessage id="companyPayments.deletecard" defaultMessage="Delete credit card info" />
                                
                                </Button>
                            {(user.subscriptionStatus !== "no_subscription") && <Tooltip placement="right" text="Cancel subscription first"/>}
                            </div>
                        }
                    </div>
                </div>}

            <h5><FormattedMessage id="companyPayments.payments" defaultMessage="Payments:" /></h5>
            <div className="payments-table-container">
                {payments && <Table className="payments-table" size="sm">
                    <thead>
                        <tr>
                            <th><FormattedMessage id="companyPayments.description" defaultMessage="Description:" /></th>
                            <th><FormattedMessage id="companyPayments.date" defaultMessage="Date:" /></th>
                            <th><FormattedMessage id="companyPayments.ammount" defaultMessage="Ammount:" />Ammount</th>
                            <th><FormattedMessage id="companyPayments.status" defaultMessage="Status:" />Status</th>
                            <th><FormattedMessage id="companyPayments.action" defaultMessage="Action:" />Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map(p => {
                            let requiresAction = p.status.includes('requires');
                            return <tr key={p.id}>
                                <td>{p.description}</td>
                                <td>{(new Date(p.timestamp).toLocaleDateString('de-DE'))}</td>
                                <td>{p.ammount} {p.currency}</td>
                                <td className={requiresAction ? "action-required" : null }>
                                    {requiresAction ? 'requires action' : p.status}
                                </td>
                                <td>{requiresAction ?
                                    <span className="confirm-payment" onClick={confirmPayment.bind(null, p.clientSecret, p.status)}>
                                        <FormattedMessage id="companyPayments.confirm" defaultMessage="confirm" />
                                        
                                    </span> : null}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </Table>}
                {payments.length === paymentsCountLimit && <div className="more" onClick={paymentsLoadMore}><FormattedMessage id="companyPayments.loadmore" defaultMessage="Load more" /></div>}
            </div>

            <Modal
                isOpen={showActivateModal}
                modalTitle={"Activate subscription"}
                closeModal={closeActivateModal}
            >
                <div className="checkbox-container">
                    <Input type='checkbox' checked={subscriptionTermsAccepted} onChange={togleSubscriptionTermsAccepted} />
                    <p><FormattedMessage id="companyPayments.acceptterms" defaultMessage="I accept the terms and conditions, and allow Eurocode to to charge my credit card monthly" /></p>
                </div>
                <p><FormattedMessage id="companyPayments.confirmsub" defaultMessage="The subscription can later be canceled at any time." /></p>
                <div className="buttons-container">
                    <Button disabled={!stripe || !subscriptionTermsAccepted || activateButtonDisabled} onClick={handleActivate}>
                        <FormattedMessage id="companyPayments.activate" defaultMessage="Activate" />
                        
                    </Button>
                    <Button onClick={closeActivateModal}><FormattedMessage id="companyPayments.cancel" defaultMessage="Cancel" /></Button>
                </div>
            </Modal>

            <Modal
                isOpen={showDeactivateModal}
                modalTitle={"Cancel subscription"}
                closeModal={closeDectivateModal}
            >
                <p><FormattedMessage id="companyPayments.deleteeurocode" defaultMessage="Are you sure you want to cancel your subscription to Eurocode?" /></p>
                <div className="buttons-container">
                    <Button disabled={!stripe || deactivateButtonDisabled} onClick={handleDectivate}>
                        <FormattedMessage id="companyPayments.yes" defaultMessage="Yes" />
                        Yes
                    </Button>
                    <Button onClick={closeDectivateModal}>No</Button>
                </div>
            </Modal>

            <Modal
                isOpen={showDeleteCreditCardModal}
                modalTitle={"Delete credit card info"}
                closeModal={closeDeleteCreditCardModal}
            >
                <p><FormattedMessage id="companyPayments.sureDeletecard" defaultMessage="Are you sure you want to delete your credit card information?" /></p>
                <div className="buttons-container">
                    <Button onClick={handleCreditCardDelete}>Yes</Button>
                    <Button onClick={closeDeleteCreditCardModal}>No</Button>
                </div>
            </Modal>
        </div>
    )
};

export default CompanyPayments;
