import React, { useState, useEffect } from "react";
import { Icon3 } from "./HeaderIcons";
import { Link } from "react-router-dom";
import { getProjects } from "../../services/projectsService";
import { useSelector, useDispatch } from "react-redux";
import { SetActiveProject } from "../../actions/projectActions";
import { logout } from "../../actions/authentificationActions";
import Image from "../../components/EurocodeImage/Image";
import { LanguageSelector} from "../../components/LanguageSelector/LanguageSelector";
import defaultProjectPicture from "./project.png";
import defaultCompanyPicture from "../Company/Company.png";
import "./Header.css";
import Tooltip from "../../components/Tooltip/Tooltip";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import {
    SetAlertMessage,
    ClearAlertMessage,
} from "../../actions/alertMessageActions";
import { Spinner } from "reactstrap";

window.onclick = function (event) {
    if (!event.target.matches(".dropbtn")) {
        let dropdowns = document.getElementsByClassName(
            "dropdown-content-header"
        );
        let i;
        for (i = 0; i < dropdowns.length; i++) {
            var openDropdown = dropdowns[i];
            if (openDropdown.classList.contains("show")) {
                openDropdown.classList.remove("show");
            }
        }
    }
};

function Header() {
    const [projects, setProjects] = useState([]);
    const user = useSelector((state) => state.user);
    const alerMsg = useSelector((state) => state.alertMessage);
    const activeProject = useSelector((state) => state.activeProject);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [warningDisplayed, setWarningDisplayed] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!user || !user.hasFullAccess) {
            let msg = "Limited access only!";
            if (user.role === "CompanyAdmin")
                msg =
                    msg +
                    " Go to Company > Payments to check credit card and payments info.";

            if (!alerMsg && !warningDisplayed) {
                setWarningDisplayed(true);
                dispatch(SetAlertMessage(msg));
            }
        }
    }, [user, dispatch, alerMsg, warningDisplayed, setWarningDisplayed]);

    let onProjectClick = (item) => {
        dispatch(SetActiveProject(item));
        sessionStorage.setItem("activeProject", JSON.stringify(item));
        // history.push(`/project/${item.id}`);
    };

    let logOut = function (event) {
        dispatch(logout());
    };

    const projectsBtnClick = function (event) {
        setLoadingProjects(true);
        getProjects(user).then((result) => {
            setProjects(result);
            setLoadingProjects(false);
        });
        document.getElementById("projectsDropdown").classList.toggle("show");
    };

    if (!activeProject) {
        getProjects(user).then((projects) => {
            dispatch(SetActiveProject(projects[0]));
            sessionStorage.setItem(
                "activeProject",
                JSON.stringify(projects[0])
            );
        });
    }

    return (
        <div className="header-container">
            <div className="header-alert-container">
                {alerMsg && (
                    <AlertMessage
                        text={alerMsg}
                        close={() => dispatch(ClearAlertMessage())}
                    />
                )}
            </div>
            <div className="header-text-container">
                <div className="iconsContainer">
                    <div className="dropdown tooltips">
                        {activeProject && activeProject.name ? (
                            <button
                                onClick={projectsBtnClick.bind(this)}
                                className="dropbtn dropbtn-disabled"
                            >
                                {loadingProjects ? (
                                    <Spinner />
                                ) : (
                                    activeProject.name
                                )}
                            </button>
                        ) : null}
                        <div
                            id="projectsDropdown"
                            className="dropdown-content-header"
                        >
                            {projects.map((item) => (
                                <div key={item.id}>
                                    <div
                                        onClick={onProjectClick.bind(
                                            this,
                                            item
                                        )}
                                    >
                                        {item.name}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Tooltip text="Active project" placement="left" />
                    </div>

                    <div className="project-image-container">
                        <Link
                            className="tooltips"
                            to={
                                activeProject &&
                                Object.keys(activeProject).length > 0
                                    ? `/project/${activeProject.id}`
                                    : `/projects`
                            }
                        >
                            <Image
                                className="project-image-container"
                                imageId={
                                    activeProject ? activeProject.id : null
                                }
                                placeholder={defaultProjectPicture}
                                refresh={
                                    activeProject
                                        ? activeProject.refresh
                                        : false
                                }
                            />
                            <Tooltip
                                text={
                                    activeProject &&
                                    Object.keys(activeProject).length > 0
                                        ? activeProject.name
                                        : "Go to Projects to get started!"
                                }
                                placement="bottom"
                            />
                        </Link>
                    </div>

                    <div style={{ borderLeft: "1px solid #BFBEBD" }}></div>

                    {user.role !== "Admin" ? (
                        <div className="project-image-container">
                            <Link
                                className="tooltips"
                                id="edit-company-button"
                                to="/company"
                            >
                                <Image
                                    imageId={user.companyId}
                                    placeholder={defaultCompanyPicture}
                                    refresh={
                                        activeProject
                                            ? activeProject.refresh
                                            : false
                                    }
                                />
                                <Tooltip text="Company" placement="bottom" />
                            </Link>
                        </div>
                    ) : null}

                    <LanguageSelector/>

                    <div className="tooltips">
                        <Icon3
                            style={{
                                color: "#69797E",
                                marginLeft: 15,
                                cursor: "pointer",
                            }}
                            width={35}
                            onClick={logOut.bind(this)}
                        />
                        <Tooltip text="Logout" placement="bottom" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;
