import React from "react";
import { connect } from "react-redux";
import { getProject, getProjectUsers } from "../../services/projectsService";
import Image from "../../components/EurocodeImage/Image";
import defaultPicture from "./project.png";
import "./ProjectDetails.css";
import CompanyUserItem from "../EditCompany/CompanyUserItem";
import Loading from "../../components/Loading/Loading";
import { FormattedMessage } from 'react-intl';

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            language: "",
            id: "",
            companyId: "",
            projectUsers: [],
            loading: true,
        };

        getProject(this.props.user, this.props.projectGuid).then((p) => {
            this.setState(p);
        });
        getProjectUsers(this.props.user, this.props.projectGuid).then(
            (projectUsers) => {
                this.setState({ projectUsers: projectUsers });
                this.setState({ loading: false });
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.props.projectGuid !== prevProps.projectGuid &&
                this.props.projectGuid) ||
            this.state.projectUsers.length !== prevState.projectUsers.length
        ) {
            this.setState({ loading: true });
            getProject(this.props.user, this.props.projectGuid).then((p) =>
                this.setState(p)
            );
            getProjectUsers(this.props.user, this.props.projectGuid).then(
                (projectUsers) => {
                    this.setState({ projectUsers: projectUsers });
                    this.setState({ loading: false });
                }
            );
        }
    }

    render() {
        return this.state.loading ? (
            <Loading />
        ) : (
            <div className="projectDetails-container">
                <div className="image-container">
                    <div className="thumbsContainer">
                        <Image
                            imageId={this.props.projectGuid}
                            placeholder={defaultPicture}
                            refresh={this.props.activeProject.refresh}
                        />
                    </div>
                </div>
                    <h5><FormattedMessage id= "projectdetails.Description" defaultMessage ="Description:" /></h5>
                <p>{this.state.description}</p>
                    <h5><FormattedMessage id="projectdetails.Language" defaultMessage="Language:" /> {this.props.language}</h5>
                    <h5><FormattedMessage id="projectdetails.Users" defaultMessage="Users:" /></h5>
                <div className="company-users">
                    {this.state.projectUsers.map((user) => (
                        <div key={user.guid}>
                            <CompanyUserItem {...user} editMode={false} />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    let projectGuid = state.router.location.pathname.split("/")[2];
    const activeProject = state.activeProject;
    return { user, projectGuid, activeProject };
}

export default connect(mapStateToProps)(ProjectDetails);
