import {
    SET_ACTIVE_PROJECT,
    CLEAR_ACTIVE_PROJECT,
} from "../actions/projectActions";

let savedState = {};
try {
    savedState = JSON.parse(sessionStorage.getItem("activeProject"));
} catch (error) {
    savedState = null;
}

const initialState = null;

function projectReducer(state = savedState || initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_PROJECT:
            return { ...action.project };
        case CLEAR_ACTIVE_PROJECT:
            return initialState;
        default:
            return state;
    }
}

export default projectReducer;
