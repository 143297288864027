import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getProjects } from "../../services/projectsService";
import { getChapters } from "../../services/chaptersService";
import { postCalculation, validate } from "../../services/calculationService";
import "./DynamicForm.css";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import ChapterCreate from "../../containers/Chapter/ChapterCreate";
import { FormattedMessage } from 'react-intl';

function SaveAsForm(props) {
    const activeProject = useSelector((state) => state.activeProject);
    const [calculationName, setCalculationName] = useState("Calculation name");
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState(activeProject.id);
    const [chapters, setChapters] = useState([]);
    const [newChapter, setNewChapter] = useState(null);
    const [chapter, setChapter] = useState(null);
    const user = useSelector((state) => state.user);
    const history = useHistory();
    const [modalOpen, setModalOpen] = useState(false);

    let fetchChapters = (project) => {
        getChapters(user, project).then((chapters) => {
            setChapters(chapters);
            setChapter(
                newChapter
                    ? newChapter
                    : chapters.length !== 0
                        ? chapters[0].id
                        : null
            );
        });
    };

    useEffect(() => {
        getProjects(user).then((p) => setProjects(p));
        getChapters(user, project).then((chapters) => {
            setChapters(chapters);
            setChapter(
                newChapter
                    ? newChapter
                    : chapters.length !== 0
                        ? chapters[0].id
                        : null
            );
        });
    }, [project, newChapter, user]);

    let handleCalculationNameChange = (event) => {
        setCalculationName(event.target.value);
    };

    let handleProjectChange = (event) => {
        setProject(event.target.value);
        fetchChapters(event.target.value);
    };

    let handleChapterChange = (event) => {
        setChapter(event.target.value);
    };

    let handlePostCalculations = () => {
        let parametersString = props.getParams();

        if (calculationName) {
            let data = {
                name: calculationName,
                chapterId: chapter,
                formId: props.form.id,
                parameters: parametersString,
            };

            validate(parametersString, props.form, user)
                .then(() => postCalculation(user, JSON.stringify(data)))
                .then(() => {
                    history.push({ pathname: `/section/${chapter}` });
                })
                .catch((error) => {
                    try {
                        let validationError = JSON.parse(error.message);
                        props.setValidationError(validationError);
                        props.close();
                    } catch (err) { }
                });
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSetNewChapter = (c) => {
        setNewChapter(c);
    };

    return (
        <div className="saveAsForm-container">
            <Form className="input-saveAs">
                <FormGroup>
                    <Label for="calculationName"><FormattedMessage id="saveasform.calcname" defaultMessage="Calculation name" /></Label>
                    <Input
                        type="text"
                        name="calculationName"
                        id="calculationName"
                        value={calculationName}
                        onChange={handleCalculationNameChange}
                        invalid={!calculationName}
                        placeholder=""
                    />
                    {!calculationName && (
                        <p className="error-text">
                            Calculation name is required
                        </p>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label for="project">Project</Label>
                    <Input
                        type="select"
                        name="project"
                        id="project"
                        onChange={handleProjectChange}
                    >
                        <option key={activeProject.id} value={activeProject.id}>
                            {" "}
                            {activeProject.name}
                        </option>
                        {projects.map((p) => {
                            if (activeProject.id !== p.id)
                                return (
                                    <option key={p.id} value={p.id}>
                                        {" "}
                                        {p.name}{" "}
                                    </option>
                                );
                            return null;
                        })}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="chapter">Section</Label>
                    <Input
                        type="select"
                        name="chapter"
                        id="chapter"
                        onChange={handleChapterChange}
                        disabled={chapter === null ? true : false}
                        value={newChapter ? newChapter : chapter}
                    >
                        {chapters.map((c) => (
                            <option key={c.id} value={c.id}>
                                {c.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </Form>
            <div className="calculate-buttons-container">
                <Button onClick={props.close}><FormattedMessage id="saveasform.Back" defaultMessage="Back" /></Button>
                <Button onClick={() => setModalOpen(true)}>
                    Add new section
                </Button>
                <Button
                    disabled={!chapter || !calculationName ? true : false}
                    onClick={handlePostCalculations}
                >
                    <FormattedMessage id="saveasform.save" defaultMessage="Save" />

                </Button>
            </div>
            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                modalTitle={<FormattedMessage id="saveasform.addnewsection" defaultMessage="Add new section" />}
            >
                <ChapterCreate
                    location={{ state: { parentChapters: chapters } }}
                    handleCloseModal={handleCloseModal}
                    project={project}
                    handleSetNewChapter={handleSetNewChapter}
                />
            </Modal>
        </div>
    );
}

export default SaveAsForm;
