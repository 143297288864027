// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place
import {take, call, put, race} from 'redux-saga/effects'
import api from '../services/autentificationService';

import {
    SENDING_LOGIN_REQUEST,
    SENDING_REGISTER_REQUEST,
    REQUEST_LOGIN_ERROR,
    LOGIN_REQUEST,
    LOGOUT,
    SET_AUTH,
    REGISTER_REQUEST,
    CLEAR_LOGIN_ERROR,
    SET_USER,
    CLEAR_USER
} from '../actions/authentificationActions';
import { CLEAR_ACTIVE_PROJECT } from '../actions/projectActions';
import { CLEAR_ALERT_MSG } from '../actions/alertMessageActions';

export function* authorize({ username, password, language, position, firstName, lastName, company, email, isRegistering}) {
  // We send an action that tells Redux we're sending a request
    if (isRegistering) {
        yield put({type: SENDING_REGISTER_REQUEST, sending: true})
    } else {
        yield put({type: SENDING_LOGIN_REQUEST, sending: true})
    }
    // We then try to register or log in the user, depending on the request
    try {
        let response

        // For either log in or registering, we call the proper function in the `auth`
        // module, which is asynchronous. Because we're using generators, we can work
        // as if it's synchronous because we pause execution until the call is done
        // with `yield`!
        if (isRegistering) {
            if (company) {
                response = yield call(api.registerCompany, { email, password, language, position, firstName, lastName, company })
            } else {
                response = yield call(api.register, { email, password, language, position, firstName, lastName })
            }
        } else {
            response = yield call(api.login, username, password)
        }

        return response
    } catch (error) {
        // If we get an error we send Redux the appropiate action and return
        yield put({type: REQUEST_LOGIN_ERROR, error: error})

        return false
    } finally {
        // When done, we tell Redux we're not in the middle of a request any more
        if (isRegistering) {
            yield put({type: SENDING_REGISTER_REQUEST, sending: false})
        } else {
            yield put({type: SENDING_LOGIN_REQUEST, sending: false})
        }
    }
}

/**
 * Log in saga
 */
export function * loginFlow () {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
    while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
        const request = yield take(LOGIN_REQUEST)
        const { username, password } = request.data

        // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
        // lead to a race condition. This is unlikely, but just in case, we call `race` which
        // returns the "winner", i.e. the one that finished first
        const winner = yield race({
            auth: call(authorize, {username, password, isRegistering: false}),
            logout: take(LOGOUT)
        })

        // If `authorize` was the winner...
        if (winner.auth) {
            const user = winner.auth;
            // ...we send Redux appropiate actions
            yield put({type: SET_AUTH, newAuthState: true}); // User is logged in (authorized) after being registered
            yield put({type: CLEAR_LOGIN_ERROR});
            yield put({type: SET_USER, user});
        }
    }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export function * logoutFlow () {
    while (true) {
        yield take(LOGOUT)
        yield put({type: SET_AUTH, newAuthState: false})
        yield put({type: CLEAR_USER});
        yield put({ type: CLEAR_ACTIVE_PROJECT });
        yield put({ type: CLEAR_ALERT_MSG }); 
        yield call(api.logout);
    }
}

/**
 * Register saga
 * Very similar to log in saga!
 */
export function * registerFlow () {
    while (true) {
    // We always listen to `REGISTER_REQUEST` actions
        const request = yield take(REGISTER_REQUEST)
        const user = request.data

        // We call the `authorize` task with the data, telling it that we are registering a user
        // This returns `true` if the registering was successful, `false` if not
        const success = yield call(authorize, {...user, isRegistering: true})

        // If we could register a user, we send the appropiate actions
        if (success) {
            yield put({ type: CLEAR_LOGIN_ERROR });
            yield put({ type: LOGIN_REQUEST, data: { ...user, username: user.email } })
        }
    }
}
