import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import ActionsBar from "../ActionsBar/ActionsBar";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import { useHistory } from "react-router-dom";
import { deleteChapter, moveChapter } from "../../services/chaptersService";
import "./ChildChapterAvatar.css";

function ChildChapterAvatar(props) {
  const [actionsBarOpen, setActionsBarOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const user = useSelector(state => state.user);
  const history = useHistory();

  let showActions = setActionsBarOpen.bind(this, true);
  let hideActions = setActionsBarOpen.bind(this, false);
  let openDeleteDialog = setDeleteDialogOpen.bind(this, true);
  let closeDeleteDialog = setDeleteDialogOpen.bind(this, false);

  let onEdit = () => history.push(`/section/${props.id}`);

  let removeChapter = () => {
    deleteChapter(user, props.id).then(props.refresh);
  };

  let moveChapterUp = () => {
    moveChapter(user, { id: props.id, type: 2, content: props }, "up").then(
      props.refresh
    );
  };

  let moveChapterDown = () => {
    moveChapter(user, { id: props.id, type: 2, content: props }, "down").then(
      props.refresh
    );
  };

  return (
    <div
      className="child-chapter-container"
      onMouseOver={showActions}
      onMouseLeave={hideActions}
    >
      <h5>{`${props.sectionNumber} ${props.name}`}</h5>
      <div className="child-chapter-description">{`Subsection with ${props.paragraphs.length} paragraphs and ${props.calculations.length} calculations and ${props.childChapters.length} subsections`}</div>
      <div className="child-chapter-actions-container">
        <ActionsBar
          active={actionsBarOpen}
          onEdit={onEdit}
          onDelete={openDeleteDialog}
          onMoveUp={moveChapterUp}
          onMoveDown={moveChapterDown}
        />
      </div>

      <Modal isOpen={deleteDialogOpen} closeModal={closeDeleteDialog}>
        <p>{`Are you sure you want to delete section ${props.sectionNumber} ${props.name}`}</p>
        <div className="buttons-container">
          <Button onClick={removeChapter}>Yes</Button>
          <Button onClick={closeDeleteDialog}>No</Button>
        </div>
      </Modal>
    </div>
  );
}
export default ChildChapterAvatar;
