import { getAuthorizationProperties } from './authorizationHelper';

export function getForm(id, user) {
    return fetch(`api/forms/${id}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, { 'Content-Type': 'application/json' }),
    }).then(response => response.json())
}

export function getFormById(id, user) {
    return fetch(`api/forms/id/${id}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, { 'Content-Type': 'application/json' }),
    }).then(response => response.json())
}

export function getAll(user) {
    return fetch(`api/forms/all`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token, { 'Content-Type': 'application/json' }),
    }).then(response => response.json())
}