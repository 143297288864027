import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { connect } from "react-redux";
import { RefreshImage } from "../../actions/imageAction";
import {
    getProject,
    changeProjectInfo,
    getProjectUsers,
    addProjectUsers,
    removeProjectUsers,
} from "../../services/projectsService";
import { getCompanyUsers } from "../../services/companyService";
import { putImage } from "../../services/imageService";
import Dropzone from "../Dropzone/Dropzone";
import defaultPicture from "./project.png";
import "./ProjectEdit.css";
import { bindActionCreators } from "redux";
import CompanyUserItem from "../EditCompany/CompanyUserItem";
import Modal from "../EurocodeModal/EurocodeModal";
import { FormattedMessage } from 'react-intl';

function getCroppedImg(crop, fileName) {
    let image = document.querySelector("img.ReactCrop__image");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image",
            1
        );
    });
}

class ProjectEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            description: "",
            language: "",
            id: "",
            companyId: "",
            image: `/api/Images/${this.props.activeProject.id}`,
            imageDropped: false,
            refreshThumb: false,
            crop: false,
            projectUsers: [],
            companyUsers: [],
            notProjectUsers: [],
            modalOpen: false,
            usersToAdd: [],
            updatedProjectUsers: [],
        };

        this.handleChangeProjectDesc = this.handleChangeProjectDesc.bind(this);
        this.handleChangeProjectName = this.handleChangeProjectName.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.toggleUsersToAdd = this.toggleUsersToAdd.bind(this);
        this.handleAddUsers = this.handleAddUsers.bind(this);

        getProject(this.props.user, this.props.projectGuid).then((p) =>
            this.setState(p)
        );

        getProjectUsers(this.props.user, this.props.projectGuid)
            .then((projectUsers) => {
                this.setState({ projectUsers: projectUsers });
                this.setState({ updatedProjectUsers: projectUsers });
            })
            .then(() => {
                getCompanyUsers(
                    this.props.user,
                    this.props.user.companyId
                ).then((companyUsers) => {
                    this.setState({ companyUsers: companyUsers });
                    this.setState({
                        notProjectUsers: this.state.companyUsers.filter(
                            (user1) =>
                                !this.state.projectUsers.some(
                                    (user2) => user1.guid === user2.guid
                                )
                        ),
                    });
                });
            });
    }

    handleChangeProjectName(event) {
        this.setState({
            name: event.target.value,
        });
    }

    handleChangeProjectDesc(event) {
        this.setState({
            description: event.target.value,
        });
    }

    handleCropChange(newCrop) {
        this.setState({ crop: newCrop });
    }

    handleDrop() {
        this.setState({ imageDropped: true });
    }

    setRefreshThumb(refreshThumb) {
        this.setState({ refreshThumb });
    }

    toggleUsersToAdd = (user) => {
        if (this.state.usersToAdd.includes(user)) {
            this.setState({
                usersToAdd: this.state.usersToAdd.filter((u) => u !== user),
            });
        } else {
            this.state.usersToAdd.push(user);
            this.setState({ usersToAdd: this.state.usersToAdd });
        }
    };

    handleAddUsers = () => {
        this.setState({
            updatedProjectUsers: this.state.updatedProjectUsers.concat(
                this.state.usersToAdd
            ),
        });
        this.setState({
            notProjectUsers: this.state.notProjectUsers.filter(
                (u) => !this.state.usersToAdd.includes(u)
            ),
        });
        this.setState({ usersToAdd: [] });
        this.setState({ modalOpen: false });
    };

    handleRemoveUser = (guid) => {
        this.setState({
            updatedProjectUsers: this.state.updatedProjectUsers.filter(
                (u) => u.guid !== guid
            ),
        });
        this.setState({
            notProjectUsers: [
                ...this.state.notProjectUsers,
                this.state.companyUsers.filter((u) => u.guid === guid)[0],
            ],
        });
    };

    handleUpdateUsers = () => {
        let toAdd = this.state.updatedProjectUsers.filter(
            (u) => !this.state.projectUsers.includes(u)
        );
        if (toAdd.length > 0) {
            addProjectUsers(this.props.user, this.props.projectGuid, toAdd);
        }
        let toRemove = this.state.projectUsers.filter(
            (u) => !this.state.updatedProjectUsers.includes(u)
        );
        if (toRemove.length > 0) {
            removeProjectUsers(
                this.props.user,
                this.props.projectGuid,
                toRemove
            );
        }
    };

    handleUpdate() {
        changeProjectInfo(
            this.props.user,
            this.state,
            this.props.projectGuid
        ).then((res) => {
            if (res.ok === true) {
                this.handleUpdateUsers();
                this.props.editProject(
                    this.state.name,
                    this.state.description,
                    this.state.language
                );
            }
        });
        if (this.state.imageDropped) {
            getCroppedImg(this.state.crop, this.props.activeProject.id).then(
                (imageData) => {
                    putImage(
                        this.props.user,
                        imageData,
                        this.props.activeProject.id
                    ).then(() => {
                        this.props.actions.RefreshImage(
                            this.props.activeProject.id
                        );
                    });
                }
            );
        }
        this.props.goToDetails();
    }

    render() {
        return (
            <div className="projectEdit-container">
                <Dropzone
                    text="Drag 'n' drop project picture, or click to select it"
                    imagePlaceholder={defaultPicture}
                    crop={this.state.crop}
                    setCrop={this.handleCropChange.bind(this)}
                    onDrop={this.handleDrop.bind(this)}
                    refreshThumb={this.state.refreshThumb}
                    clearRefreshThumb={this.setRefreshThumb.bind(this, false)}
                    image={this.props.activeProject.id}
                />

                <Form className="input">
                    <FormGroup>
                        <Label for="projectName"><FormattedMessage id="projectedit.editproject" defaultMessage="Edit project name:" /></Label>
                        <Input
                            type="text"
                            name="projectName"
                            id="projectName"
                            placeholder=""
                            value={this.state.name}
                            onChange={this.handleChangeProjectName}
                            image={this.props.activeProject.id}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="projectedit.description" defaultMessage="Edit project description:" /></Label>
                        <Input
                            className="projectDesc-edit"
                            type="textarea"
                            name="projectDesc"
                            id="projectDesc"
                            placeholder=""
                            value={this.state.description}
                            onChange={this.handleChangeProjectDesc}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label><FormattedMessage id="projectedit.language" defaultMessage="Project language:" /></Label>
                        <Input
                            type="select"
                            name="projectLang"
                            id="projectLang"
                            value={this.state.language}
                            onChange={(e) =>
                                this.setState({
                                    language: e.target.value,
                                })
                            }
                        >
                            <option value="EN">English</option>
                            <option value="HR">Croatian</option>
                            <option value="DE">German</option>
                        </Input>
                    </FormGroup>
                    {this.props.user.role === "CompanyAdmin" && (
                        <div>
                            <Label for="addUsers"><FormattedMessage id="projectedit.addremove" defaultMessage="Add or remove users:" /></Label>
                            <div
                                className="users-add-new"
                                onClick={() =>
                                    this.setState({ modalOpen: true })
                                }
                            >
                                <FormattedMessage id="projectedit.adddmoreusers" defaultMessage="Add more users" />

                            </div>
                        </div>
                    )}
                    <div className="company-users">
                        {this.state.updatedProjectUsers
                            .sort((a, b) =>
                                a.lastName > b.lastName
                                    ? 1
                                    : b.lastName > a.lastName
                                        ? -1
                                        : 0
                            )
                            .map((user) => (
                                <div key={user.guid}>
                                    <CompanyUserItem
                                        {...user}
                                        handleRemoveUser={this.handleRemoveUser}
                                        editMode={true}
                                    />
                                </div>
                            ))}
                    </div>

                    <Modal
                        isOpen={this.state.modalOpen}
                        modalTitle={<FormattedMessage id="projectedit.addmoreusers" defaultMessage="Add more users to project" />}
                        closeModal={() => this.setState({ modalOpen: false })}
                    >
                        {this.state.notProjectUsers
                            .sort((a, b) =>
                                a.lastName > b.lastName
                                    ? 1
                                    : b.lastName > a.lastName
                                        ? -1
                                        : 0
                            )
                            .map((newUser) => (
                                <FormGroup key={newUser.guid} check>
                                    <Input
                                        type="checkbox"
                                        name={newUser.guid}
                                        id={newUser.guid}
                                        checked={this.state.usersToAdd.includes(
                                            newUser
                                        )}
                                        readOnly={true}
                                        onClick={() =>
                                            this.toggleUsersToAdd(newUser)
                                        }
                                    />{" "}
                                    <p style={{ marginBottom: "10px" }}>
                                        {newUser.firstName} {newUser.lastName}
                                    </p>
                                </FormGroup>
                            ))}
                        <div style={{ textAlign: "center" }}>
                            {this.state.notProjectUsers.length > 0 ? (
                                <Button onClick={this.handleAddUsers}>
                                    <FormattedMessage id="projectedit.addseluser" defaultMessage="Add selected users" />

                                </Button>
                            ) : (
                                <p><FormattedMessage id="projectedit.nomore" defaultMessage="No more users to add!" /></p>
                            )}
                        </div>
                    </Modal>
                    <div
                        className="projectEdit-buttons"
                        style={{ textAlign: "center" }}
                    >
                        <Button
                            className="projectEdit-button"
                            onClick={this.props.goToDetails}
                        >
                            <FormattedMessage id="projectedit.back" defaultMessage="Back" />

                        </Button>
                        <Button
                            className="projectEdit-button"
                            onClick={this.handleUpdate}
                        >
                            <FormattedMessage id="projectedit.Submit" defaultMessage="Submit" />

                        </Button>
                    </div>
                </Form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const user = state.user;
    let projectGuid = state.router.location.pathname.split("/")[2];
    const activeProject = state.activeProject;
    return { user, projectGuid, activeProject };
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ RefreshImage }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEdit);
