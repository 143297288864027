import { take, call, put } from 'redux-saga/effects';
import { loadStripe } from '@stripe/stripe-js';
import companyService from '../services/companyService';

import { SET_SUBSCRIBING, CLEAR_SUBSCRIBING } from '../actions/authentificationActions';
import { SUBSCRIBE, UNSUBSCRIBE } from '../actions/companyActions';
import { SET_ALERT_MSG } from '../actions/alertMessageActions';
import { REFRESH_USER } from '../actions/userActions';

export function* subscribeFlow() {
    while (true) {
        const request = yield take(SUBSCRIBE);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(companyService.subscribe, user, user.companyId);

            if (!response.success) {
                yield put({ type: SET_ALERT_MSG, message: response.errorMsg });
            } else {
                if (response.paymentStatus === "succeeded") {
                    yield put({ type: SET_ALERT_MSG, message: "Subscribed successfully!" });
                } else if (response.paymentStatus === "requires_action") {
                    let stripe = yield call(loadStripe, "pk_test_uzsIHqNQKl5QTMC5yT06qYPU00ZA8o6phW");
                    const stripeResponse = yield call(stripe.confirmCardPayment, response.paymentIntentSecret);

                    if (stripeResponse.error) {
                        yield put({ type: SET_ALERT_MSG, message: stripeResponse.error.message });
                    } else if (stripeResponse.paymentIntent && stripeResponse.paymentIntent.status === 'succeeded') {
                        yield put({ type: SET_ALERT_MSG, message: "Subscribed successfully!" });
                    } else {
                        yield put({ type: SET_ALERT_MSG, message: "Error confirming initial payment!" });
                    }
                }
            }
        } catch (error) {
            yield put({ type: SET_ALERT_MSG, message: error.message });
        } finally {
            yield put({ type: REFRESH_USER });
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}

export function* unsubscribeFlow() {
    while (true) {
        const request = yield take(UNSUBSCRIBE);
        const user = request.user;

        try {
            yield put({ type: SET_SUBSCRIBING });
            const response = yield call(companyService.unsubscribe, user, user.companyId);
            yield put({ type: REFRESH_USER });
            yield put({ type: SET_ALERT_MSG, message: response });
        } catch (error) {
            yield put({ type: SET_ALERT_MSG, message: error.message });
        } finally {
            yield put({ type: CLEAR_SUBSCRIBING });
        }
    }
}
