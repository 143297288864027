import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import {
    getChapters,
    deleteChapter,
    moveHeadChapter,
} from "../../services/chaptersService";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import Modal from "../EurocodeModal/EurocodeModal";
import "./Sections.css";
import Loading from "../../components/Loading/Loading";
import { FormattedMessage } from 'react-intl';

function Sections() {
    const [chapters, setChapters] = useState([]);
    const user = useSelector((state) => state.user);
    const guid = useSelector(
        (state) => state.router.location.pathname.split("/")[2]
    );
    const [actionsBarOpen, setActionsBarOpen] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [activeId, setActiveId] = useState();
    const [loading, setLoading] = useState(false);

    let refreshChapters = () => {
        getChapters(user, guid).then((p) => setChapters(p));
    };

    useEffect(() => {
        setLoading(true);
        getChapters(user, guid).then((p) => {
            setChapters(p);
            setLoading(false);
        });
    }, [user, guid]);

    let hideActions = setActionsBarOpen.bind(this, null);

    let openDeleteDialog = (id) => {
        setActiveId(id);
        setDeleteDialogOpen(true);
    };

    let closeDeleteDialog = setDeleteDialogOpen.bind(this, false);

    let onDeleteChapter = (activeId) => {
        deleteChapter(user, activeId)
            .then(refreshChapters)
            .then(closeDeleteDialog);
    };

    let moveChapterUp = (chapter) => {
        moveHeadChapter(user, chapter, "up").then(refreshChapters);
    };

    let moveChapterDown = (chapter) => {
        moveHeadChapter(user, chapter, "down").then(refreshChapters);
    };

    return (
        <div className="sections-container">
            {loading ? (
                <Loading />
            ) : (
                chapters.map((chapter) => (
                    <div
                        className="sections-content-container"
                        key={chapter.id}
                        onMouseOver={setActionsBarOpen.bind(this, chapter.id)}
                        onMouseLeave={hideActions}
                        style={{ paddingLeft: (chapter.depth - 1) * 20 }}
                    >
                        <Link
                            to={`/section/${chapter.id}`}
                            style={{ color: "#000" }}
                        >
                            <div className="chapters-container">
                                {chapter.sectionNumber} {chapter.name}
                            </div>
                        </Link>
                        {chapter.chapterType === 0 && (
                            <div className="sections-actions-container">
                                <ActionsBar
                                    active={actionsBarOpen === chapter.id}
                                    onDelete={openDeleteDialog.bind(
                                        this,
                                        chapter.id
                                    )}
                                    onMoveUp={moveChapterUp.bind(this, chapter)}
                                    onMoveDown={moveChapterDown.bind(
                                        this,
                                        chapter
                                    )}
                                />
                            </div>
                        )}
                    </div>
                ))
            )}
            <div className="sections-add-new">
                <Link
                    to={{
                        pathname: `/addChapters/${guid}`,
                        state: {
                            parentChapters: chapters.filter(
                                (x) => x.chapterType === 0
                            ),
                        },
                    }}
                >
                    <div><FormattedMessage id="sections.new" defaultMessage="Add new section" /></div>
                </Link>
            </div>

            <Modal isOpen={deleteDialogOpen} closeModal={closeDeleteDialog}>
                <p>Are you sure you want to delete this section?</p>
                <div className="buttons-modal">
                    <Button onClick={() => onDeleteChapter(activeId)}>
                        <FormattedMessage id="sections.Yes" defaultMessage="Yes" />

                    </Button>
                    <Button onClick={closeDeleteDialog}><FormattedMessage id="sections.No" defaultMessage="No" /></Button>
                </div>
            </Modal>
        </div>
    );
}
export default Sections;
