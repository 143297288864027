import { getAuthorizationProperties } from './authorizationHelper';

export function getParagraphs(user, guid) {
    return fetch(`api/paragraphs?chapter=${guid}`, {
        method: 'GET',
        ...getAuthorizationProperties(user.token),
    }).then(response => response.json())
}

export function updateParagraph(user, guid, paragraph) {
    return fetch(`api/paragraphs/${guid}`, {
        method: 'PUT',
        ...getAuthorizationProperties(user.token, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(paragraph)
    })
}

export function addParagraph(user, paragraph) {
    return fetch('api/paragraphs', {
        method: 'POST',
        ...getAuthorizationProperties(user.token, { 'Content-Type': 'application/json' }),
        body: JSON.stringify(paragraph)
    })
}

export function deleteParagraph(user, guid) {
    return fetch(`api/paragraphs/${guid}`, {
        method: 'DELETE',
        ...getAuthorizationProperties(user.token)
    })
}