import { getAuthorizationProperties } from "./authorizationHelper";

export function getProjects(user) {
    return fetch("api/Projects", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export async function getProject(user, guid) {
    const response = await fetch(`api/Projects/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
    return await response.json();
}

export function changeProjectInfo(user, state, guid) {
    const requestOptions = {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(state),
    };
    return fetch(`api/Projects/${guid}`, requestOptions);
}

export function getCountries() {
    return fetch("api/LoadZones/Countries", {
        method: "GET",
    }).then((response) => response.json());
}

export function postProject(user, state) {
    return fetch("api/Projects", {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: state,
    }).then((response) => response.json());
}

export function deleteProject(user, guid) {
    return fetch(`api/Projects/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}

export function saveProjectLocation(
    user,
    name,
    description,
    id,
    companyId,
    latitude,
    longitude,
    elevation,
    countryCode,
    guid
) {
    const requestOptions = {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify({
            name,
            description,
            id,
            companyId,
            latitude,
            longitude,
            elevation,
            countryCode,
        }),
    };

    return fetch(`api/Projects/${guid}`, requestOptions);
}

export async function getProjectUsers(user, guid) {
    const response = await fetch(`api/Projects/${guid}/users`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
    return await response.json();
}

export function addProjectUsers(user, projectGuid, users) {
    const searchParams = new URLSearchParams();
    users.forEach((user) => {
        searchParams.append("guid", user.guid);
    });
    return fetch(
        `api/Projects/${projectGuid}/addusers?${searchParams.toString()}`,
        {
            method: "POST",
            ...getAuthorizationProperties(user.token, {
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
        }
    );
}

export function removeProjectUsers(user, projectGuid, users) {
    const searchParams = new URLSearchParams();
    users.forEach((user) => {
        searchParams.append("guid", user.guid);
    });
    return fetch(
        `api/Projects/${projectGuid}/removeusers?${searchParams.toString()}`,
        {
            method: "DELETE",
            ...getAuthorizationProperties(user.token, {
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
        }
    );
}
