import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { setLanguage } from "../../actions/authentificationActions";
import "./LanguageSelector.css";
import {
    getLanguage
} from "../../services/languageService";


export const LanguageSelector = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);





    const handleChangeLanguage = (value) => {
        if (user && value !== user.language) {
            dispatch(setLanguage(value));
            getLanguage(value);
        }
    };

    return (
        <div className="laguage-selector">
            <Dropdown isOpen={dropdownOpen} toggle={() => { setDropdownOpen(!dropdownOpen) }}>
                <DropdownToggle>
                    {user && user.language}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem
                        active={user && user.language === 'en'}
                        onClick={handleChangeLanguage.bind(null, 'en')}>
                        en
                    </DropdownItem>
                    <DropdownItem
                        active={user && user.language === 'hr'}
                        onClick={handleChangeLanguage.bind(null, 'hr')}>
                        hr
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
