import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    registerRequest,
    clearLoginError
} from "../../actions/authentificationActions";
import RedirectIfLoggedIn from "../../components/RedirectIfLoggedIn";
import { ReactComponent as Down } from "./Icons/chevron_down.svg";
import { ReactComponent as Up } from "./Icons/chevron_up.svg";
import { ReactComponent as User } from "./Icons/User.svg";
import { ReactComponent as Company } from "./Icons/Company.svg";
import { Route, Switch } from "react-router";
import {
    Button,
    FormGroup,
    Dropdown,
    DropdownMenu,
    DropdownToggle
} from "reactstrap";
import { Link } from "react-router-dom";
import "./Register.css";
import { getCountries } from "../../services/projectsService";
import { FormattedMessage } from 'react-intl';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpenLanguages: false,
            dropdownOpenCountries: false,
            email: "",
            firstName: "",
            lastName: "",
            position: "",
            language: "EN",
            password: "",
            companyName: "",
            companyCountry: "AT",
            companyCity: "",
            companyPostal: "",
            companyAddress: "",
            companyTelephone: "",
            companyEmail: "",
            countries: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.selectLanguage = this.selectLanguage.bind(this);
        this.toggleDropdownLanguages = this.toggleDropdownLanguages.bind(this);
        this.getUserFromState = this.getUserFromState.bind(this);

        getCountries().then(c => this.setState({ countries: c }));
    }

    componentDidMount() {
        this.props.actions.clearLoginError();
    }

    handleChange(field, event) {
        let change = {};
        change[field] = event.target.value;
        this.setState(change);
    }

    toggleDropdownLanguages() {
        this.setState({
            dropdownOpenLanguages: !this.state.dropdownOpenLanguages
        });
    }

    selectLanguage(language) {
        this.setState({ language });
        this.toggleDropdownLanguages();
    }

    selectCountry = countryCode => {
        this.setState({ companyCountry: countryCode });
        this.setState({ dropdownOpenCountries: false });
    };

    getUserFromState = () => {
        return {
            email: this.state.email,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            position: this.state.position,
            language: this.state.language,
            password: this.state.password
        };
    };

    getCompanyFromState = () => {
        return {
            name: this.state.companyName,
            postalCode: this.state.companyPostal,
            city: this.state.companyCity,
            address: this.state.companyAddress,
            country: this.state.companyCountry,
            email: this.state.companyEmail,
            telephone: this.state.companyTelephone
        };
    };

    handleUserSubmit = e => {
        this.props.actions.registerRequest(this.getUserFromState());
    };

    handleCompanySubmit = e => {
        var user = this.getUserFromState();
        user.company = this.getCompanyFromState();
        this.props.actions.registerRequest(user);
    };

    renderSelect = () => (
        <div id="register-select-container">
            <div id="register-select-main">
                <Link to="/Register/User">
                    <div className="register-option">
                        <div className="image-container">
                            <User />
                        </div>
                        <h2><FormattedMessage id="register.iamemp" defaultMessage= "I'm an employee" /></h2>
                        <p>
                            <FormattedMessage id="register.thisoprion" defaultMessage="Use this option if you want to join your company's
                            team" />
                           
                        </p>
                    </div>
                </Link>

                <Link to="/Register/Company">
                    <div className="register-option">
                        <div className="image-container">
                            <Company />
                        </div>
                        <h2><FormattedMessage id="register.iamcompany" defaultMessage="I'm a company" /> </h2>
                        <p>
                            <FormattedMessage id="register.wantcompany" defaultMessage="Use this option if you want your company to start
                            using Eurocode hub" />
                            
                        </p>
                    </div>
                </Link>
            </div>
            <p>
                <FormattedMessage id="register.alreadyhave" defaultMessage="Already have an account?" /><Link to={"/Login"}>Sign in!</Link>
            </p>
        </div>
    );

    renderUserForm = (showPosition = true) => (
        <form className="register-form">
            <FormGroup>
                <label htmlFor="email">Email: *</label>
                <input
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange.bind(null, "email")}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="firstName"><FormattedMessage id="register.firstname" defaultMessage="First name: *" /></label>
                <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={this.state.firstName}
                    onChange={this.handleChange.bind(null, "firstName")}
                />
            </FormGroup>

            <FormGroup>
                <label htmlFor="lastName"><FormattedMessage id="register.lastname" defaultMessage="Last name: *" /></label>
                <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={this.state.lastName}
                    onChange={this.handleChange.bind(null, "lastName")}
                />
            </FormGroup>

            {showPosition ? (
                <FormGroup>
                    <label htmlFor="position"><FormattedMessage id="register.Position" defaultMessage="Position:" /></label>
                    <input
                        type="text"
                        name="position"
                        id="position"
                        value={this.state.position}
                        onChange={this.handleChange.bind(null, "position")}
                    />
                </FormGroup>
            ) : null}

            <FormGroup>
                <label htmlFor="language"><FormattedMessage id="register.Language" defaultMessage="Language:" /></label>
                <Dropdown
                    isOpen={this.state.dropdownOpenLanguages}
                    toggle={this.toggleDropdownLanguages}
                >
                    <DropdownToggle
                        tag="div"
                        onClick={this.toggleDropdownLanguages}
                        data-toggle="dropdown"
                        aria-expanded={this.state.dropdownOpenLanguages}
                        className="lang-selector"
                    >
                        {this.state.language}
                        {this.state.dropdownOpenLanguages ? <Up /> : <Down />}
                    </DropdownToggle>
                    <DropdownMenu>
                        <div
                            onClick={this.selectLanguage.bind(null, "EN")}
                            className="lang-option noselect"
                            key="language-en"
                            value="EN"
                        >
                            <FormattedMessage id="register.English" defaultMessage="English" />
                            
                        </div>
                        <div
                            onClick={this.selectLanguage.bind(null, "DE")}
                            className="lang-option noselect"
                            key="language-de"
                            value="DE"
                        >
                            <FormattedMessage id="register.Deutsch" defaultMessage="Deutsch" />
                            
                        </div>
                        <div
                            onClick={this.selectLanguage.bind(null, "HR")}
                            className="lang-option noselect"
                            key="language-hr"
                            value="HR"
                        >
                            <FormattedMessage id="register.Croatia" defaultMessage="Hrvatski" />
                            
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </FormGroup>

            <FormGroup>
                <label htmlFor="password"><FormattedMessage id="register.Password" defaultMessage="Password: *" /></label>
                <input
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange.bind(null, "password")}
                />
            </FormGroup>
        </form>
    );

    renderUserRegister = () => (
        <div id="register-container">
            <h1>Register</h1>
            {this.renderUserForm(true)}

            <p id="server-error" className="error-text">
                {this.props.error ? this.props.error.toString() : null}
            </p>

            <Button className="register-button" onClick={this.handleUserSubmit}>
                <FormattedMessage id="register.Register" defaultMessage="Register" />
                
            </Button>

            <p>
                <FormattedMessage id="register.Alreadyhavean " defaultMessage="Already have an account?" /> <Link to={"/Login"}>Sign in!</Link>
            </p>
        </div>
    );

    renderCompanyRegister = () => (
        <div id="register-container">
            <div className="register-company-container">
                <div>
                    <h2><FormattedMessage id="register.Userinfo " defaultMessage="User info" /></h2>
                    {this.renderUserForm(false)}
                </div>

                <div>
                    <h2><FormattedMessage id="register.Companyinfo " defaultMessage="Company info" /></h2>
                    <form className="register-form">
                        <FormGroup>
                            <label htmlFor="name">Name: *</label>
                            <input
                                type="text"
                                name="name"
                                value={this.state.companyName}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyName"
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="companyCountry">Country:</label>
                            <Dropdown
                                isOpen={this.state.dropdownOpenCountries}
                                toggle={() =>
                                    this.setState({
                                        dropdownOpenCountries: !this.state
                                            .dropdownOpenCountries
                                    })
                                }
                            >
                                <DropdownToggle
                                    tag="div"
                                    onClick={() =>
                                        this.setState({
                                            dropdownOpenCountries: !this.state
                                                .dropdownOpenCountries
                                        })
                                    }
                                    data-toggle="dropdown"
                                    aria-expanded={
                                        this.state.dropdownOpenCountries
                                    }
                                    className="lang-selector"
                                >
                                    {this.state.companyCountry}
                                    {this.state.dropdownOpenCountries ? (
                                        <Up />
                                    ) : (
                                        <Down />
                                    )}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {Object.entries(this.state.countries).map(
                                        country => (
                                            <div
                                                onClick={this.selectCountry.bind(
                                                    null,
                                                    country[0]
                                                )}
                                                className="lang-option noselect"
                                                key={country[0]}
                                                value={country[0]}
                                            >
                                                {country[1]}
                                            </div>
                                        )
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="city"><FormattedMessage id="register.Cityq " defaultMessage="City: *" /></label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                value={this.state.companyCity}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyCity"
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="postal"><FormattedMessage id="register.Postalcodeq" defaultMessage="Postal code: *" /></label>
                            <input
                                type="text"
                                name="postal"
                                id="postal"
                                value={this.state.companyPostal}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyPostal"
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="address"><FormattedMessage id="register.Addressq" defaultMessage="Address: *" /></label>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                value={this.state.companyAddress}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyAddress"
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="telephone">
                                <FormattedMessage id="register.Contacttelephone" defaultMessage="Contact telephone:" />
                                
                            </label>
                            <input
                                type="text"
                                name="telephone"
                                id="telephone"
                                value={this.state.companyTelephone}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyTelephone"
                                )}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label htmlFor="email"><FormattedMessage id="register.Contactemail" defaultMessage="Contact email:" /></label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={this.state.companyEmail}
                                onChange={this.handleChange.bind(
                                    null,
                                    "companyEmail"
                                )}
                            />
                        </FormGroup>
                    </form>
                </div>
            </div>

            <p id="server-error" className="error-text">
                {this.props.error ? this.props.error.toString() : null}
            </p>

            <Button
                className="register-button"
                onClick={this.handleCompanySubmit}
            >
                <FormattedMessage id="register.Registerq" defaultMessage="Register" />
                
            </Button>

            <p>
                <FormattedMessage id="register.Alreadyhaveanq" defaultMessage="Already have an account?" />
                <Link to={"/Login"}><FormattedMessage id="register.signinq" defaultMessage="Sign in!" /></Link>
            </p>
        </div>
    );

    render() {
        return (
            <div className="container-center">
                <RedirectIfLoggedIn />
                <Switch>
                    <Route
                        exact
                        path="/Register/User"
                        render={this.renderUserRegister.bind(this)}
                    />
                    <Route
                        exact
                        path="/Register/Company"
                        render={this.renderCompanyRegister.bind(this)}
                    />
                    <Route render={this.renderSelect.bind(this)} />
                </Switch>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { error } = state.login;
    return { error };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ registerRequest, clearLoginError }, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
