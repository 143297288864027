import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { selectNavigationItem, toggleSidebar } from "../../actions/sidebarActions";
import { Link } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import SidebarItem from "../../components/SidebarItem/SidebarItem";
import SubnavItem from "../../components/SubnavItem/SubnavItem";
import logo from "./Eurocode.svg";
import logo_white from "./EurocodeWhite.svg";
import Hierarchy from "./sidebarHierarchy"
import "./Sidebar.css";


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: "",
            clicked: "",
            subnavOpen: false,
            subnavItems: [],
        };
    }

    getSidebarItem(id) {
        for (let i = 0; i < Hierarchy.length; i++) {
            if (Hierarchy[i].icon === id) {
                return Hierarchy[i];
            }
        }
    }

    setActive(id) {
        this.setState({ active: id, clicked: id });
    }

    setClicked(id) {
        let subnavState = this.state.subnavOpen;
        let subnavItems = this.getSidebarItem(id).children;

        if (subnavItems) {
            if (this.state.clicked === id) {
                subnavState = !this.state.subnavOpen;
            } else {
                subnavState = true;
            }
        } else {
            subnavItems = [];
            subnavState = false;
            this.setActive(id);
        }

        this.onSubnavClick = this.setActive.bind(this, id);

        this.setState({ clicked: id, subnavOpen: subnavState, subnavItems });
    }

    setSubnavOpen(open) {
        this.setState({ subnavOpen: open });
    }

    toggleSidebar(event) {
        if (event.target === event.currentTarget) {
            event.preventDefault();
            this.props.actions.toggleSidebar();

            document.getElementById("logo-image-container").className = "animated";
            setTimeout(function () {
                document.getElementById("logo-image-container").className = null;
            }, 2000);
        }
    }

    render() {
        let sidebarClassName =
            "sidebar-container" + (this.props.expanded ? " expanded" : " collapsed");
        let subnavClassName = "subnav-container" + (this.state.subnavOpen ? " open" : " closed");
        return (
            <aside className={sidebarClassName}>
                <div
                    className="sidebar-content-container"
                    onClick={this.toggleSidebar.bind(this)}
                    onMouseLeave={this.setSubnavOpen.bind(this, false)}
                >
                    <Link
                        to="/"
                        id="logo-image-container"
                        onClick={this.setActive.bind(this, "home")}
                    >
                        <img
                            id="logo-image"
                            src={this.props.expanded ? logo : logo_white}
                            alt="Logo"
                        />
                    </Link>
                    <hr />
                    <UserAvatar onClick={this.setActive.bind(this, "user")} />
                    <hr />
                    {Hierarchy.map((l, i) => (
                        <SidebarItem
                            {...l}
                            key={i}
                            active={l.icon === this.state.active}
                            expanded={this.props.expanded}
                            onClick={this.setClicked.bind(this)}
                        />
                    ))}
                    <div className={subnavClassName}>
                        <hr className="subnav-divider top-divider" />
                        {this.state.subnavItems.map((l, i) => (
                            <SubnavItem
                                {...l}
                                key={i}
                                active={l.link === this.props.currentPath}
                                onClick={this.onSubnavClick}
                            />
                        ))}
                    </div>
                </div>
            </aside>
        );
    }
}

function mapStateToProps(state) {
    let currentPath = state.router.location.pathname.replace("/", "");
    return {
        expanded: state.sidebar.expanded,
        currentPath,
    };
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ selectNavigationItem, toggleSidebar }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
