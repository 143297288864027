import React from "react";
import CharacteristicSnowLoadValue from "./SpecialInputFields/CharacteristicSnowLoadValue/CharacteristicSnowLoadValue";
import SnowLoadCalculationCase from "./SpecialInputFields/SnowLoadCalculationCase/SnowLoadCalculationCase";
import { PersonField } from "./SpecialInputFields/PersonField/PersonField";
import { MapField } from "./SpecialInputFields/MapField/MapField";
import { ImageInputField } from "./SpecialInputFields/ImageInputField/ImageInputField";
import { CompanyAssociatesField } from "./SpecialInputFields/CompanyAssociatesField/CompanyAssociatesField";
import { StructureTerrainField } from "./SpecialInputFields/StructureTerrainField/StructureTerrainField";
import { CanopyFi } from "./SpecialInputFields/CanopyFi/CanopyFi";
//import { StructureParameters } from "./SpecialInputFields/StructureTerrainField/StructureParameters";
import { AnnexF } from "./SpecialInputFields/AnnexF/AnnexF";

function createSpecialInputField(props) {
    if (props.specialType === "CharacteristicSnowLoadValue") {
        return <CharacteristicSnowLoadValue {...props} />;
    }
    if (props.specialType === "SnowLoadCalculationCase") {
        return <SnowLoadCalculationCase {...props} />;
    }
    if (props.specialType === "PersonField") {
        return <PersonField {...props} />;
    }
    if (props.specialType === "MapField") {
        return <MapField {...props} />;
    }
    if (props.specialType === "ImageInputField") {
        return <ImageInputField {...props} />;
    }
    if (props.specialType === "CompanyAssociatesField") {
        return <CompanyAssociatesField {...props} />;
    }
    if (props.specialType === "StructureTerrainField") {
        return <StructureTerrainField {...props} />;
    }
    if (props.specialType === "CanopyFi") {
        return <CanopyFi {...props} />;
    }
    if (props.specialType === "AnnexF") {
        return <AnnexF {...props} />;
    }
    //if (props.specialType === "StructureParameters") {
    //    return <StructureParameters {...props} />;
    //}


    return null;
}

function SpecialInputField(props) {
    let field = createSpecialInputField(props);

    return (
        <div className="full-width">
            {field ? field : <pre>{JSON.stringify(props, null, 4)}</pre>}
        </div>
    );
}

export default SpecialInputField;
