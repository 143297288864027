import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { getSk } from "../../../../services/recommendedValuesService";
import InfoTooltip from "../../../Tooltip/InfoTooltip";
import { useSelector } from "react-redux";

const CharacteristicSnowLoadValue = (props) => {
    const [value, setValue] = useState();
    const activeProjectId = useSelector((state) => state.activeProject.id);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (activeProjectId) {
            getSk(activeProjectId, user).then((val) => setValue(val));
        }
    }, [user, activeProjectId]);

    return (
        <div style={{ display: "flex" }}>
            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="number"
                name={props.jsonKey}
                defaultValue={
                    props.startValue
                        ? props.startValue
                        : !Number.isNaN(value) && value > 0
                        ? value
                        : null
                }
                lang="en"
                autoComplete="off"
                step="any"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={props.changeHandler ? props.changeHandler : null}
                onWheelCapture={(e) => e.currentTarget.blur()}
            />
            {!Number.isNaN(value) && value > 0 && (
                <InfoTooltip
                    placement="right"
                    content={`Value ${value} is recommended based on your project's location, country, and elevation`}
                />
            )}
        </div>
    );
};

export default CharacteristicSnowLoadValue;
