import React from "react";
import InfoIcon from "../../components/Tooltip/info.svg";
import "./InfoBox.css";

const InfoBox = ({ content }) => {
    return (
        <div className="info-message">
            <img width="30px" src={InfoIcon} alt="Info icon" />
            <p>{content}</p>
        </div>
    );
};

export default InfoBox;
