export const SUBSCRIBE = "SUBSCRIBE";
export const UNSUBSCRIBE = "UNSUBSCRIBE";

export function subscribe(user) {
    return { type: SUBSCRIBE, user }
}

export function unsubscribe(user) {
    return { type: UNSUBSCRIBE, user}
}
