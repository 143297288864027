import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addParagraph } from "../../services/paragraphsService";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import {
    getChapter,
    getChapterContent,
    updateSection,
} from "../../services/chaptersService";
import { getAll } from "../../services/formsService";
import { setForms } from "../../actions/formActions";
import { previewSection } from "../../services/previewService";
import Paragraph from "../../components/Paragraph/Paragraph";
import ChildChapterAvatar from "../../components/ChildChapterAvatar/ChildChapterAvatar";
import CalculationAvatar from "../../components/CalculationAvatar/CalculationAvatar";
import ParagraphEditor from "../../components/ParagraphEditor/ParagraphEditor";
import ActionsBar from "../../components/ActionsBar/ActionsBar";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import SectionEditor from "./SectionEditor";
import "./Section.css";
import Loading from "../../components/Loading/Loading";
import { FormattedMessage } from 'react-intl';

function Section(props) {
    const [chapter, setChapter] = useState([]);
    const [content, setContent] = useState([]);
    const [paragraphModalOpen, setParagraphModalOpen] = useState(false);
    const [calculationModalOpen, setCalculationModalOpen] = useState(false);
    const [actionsBarOpen, setActionsBarOpen] = useState(false);
    const [editSectionOpen, setEditSectionOpen] = useState(false);
    const [newCalculationForm, setNewCalculationForm] = useState(null);
    const user = useSelector((state) => state.user);
    const forms = useSelector((state) => state.forms);
    const guid = useSelector(
        (state) => state.router.location.pathname.split("/")[2]
    );
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    let closeParagraphModal = setParagraphModalOpen.bind(this, false);
    let openParagraphModal = setParagraphModalOpen.bind(this, true);
    let closeCalculationModal = setCalculationModalOpen.bind(this, false);
    let openCalculationModal = setCalculationModalOpen.bind(this, true);
    let openModalForEditSection = setEditSectionOpen.bind(this, true);
    let closeModalForEditSection = setEditSectionOpen.bind(this, false);
    let showActions = setActionsBarOpen.bind(this, true);
    let hideActions = setActionsBarOpen.bind(this, false);

    if (forms.length === 0) {
        getAll(user).then((allForms) => {
            dispatch(setForms(allForms));
        });
    } else if (!newCalculationForm) {
        setNewCalculationForm(forms[0].formId);
    }

    let refreshContent = () =>
        getChapterContent(user, guid).then((p) => setContent(p));

    let refreshChapter = () =>
        getChapter(user, guid).then((p) => setChapter(p));

    useEffect(() => {
        setLoading(true);
        getChapterContent(user, guid).then((p) => setContent(p));
        getChapter(user, guid).then((p) => {
            setChapter(p);
            setLoading(false);
        });
    }, [user, guid]);

    let createNewParagraph = (newData) => {
        let data = {
            chapterId: guid,
            name: newData.name,
            content: newData.content,
        };
        addParagraph(user, data).then(refreshContent);
    };

    let createNewCalculation = () => {
        history.push({
            pathname: `/${newCalculationForm}`,
            state: { targetChapterId: guid },
        });
    };

    let saveChanges = (newData) => {
        let data = {
            name: newData.name,
            sectionNumber: newData.sectionNumber,
            id: guid,
            projectId: chapter.projectId,
        };
        updateSection(user, guid, data).then(refreshChapter);
    };

    let returnToParent = () => {
        history.push(`/section/${chapter.parentChapterId}`);
    };

    let handleFormChange = (event) => {
        setNewCalculationForm(event.target.value);
    };

    return (
        <div className="section-container">
            {loading ? (
                <Loading />
            ) : (
                <Fragment>
                    <div
                        className="section-title-container paper-max-width"
                        onMouseOver={showActions}
                        onMouseLeave={hideActions}
                    >
                        <div className="section-actions-container">
                            <ActionsBar
                                active={actionsBarOpen}
                                onEdit={openModalForEditSection}
                                onBack={
                                    chapter.depth > 1 ? returnToParent : null
                                }
                            />
                        </div>
                        <h3>{`${chapter.sectionNumber} ${chapter.name}`}</h3>
                    </div>

                    {content.map((childElement) => {
                        if (childElement.type === 0) {
                            return (
                                <Paragraph
                                    {...childElement.content}
                                    key={childElement.content.id}
                                    refresh={refreshContent}
                                />
                            );
                        } else if (childElement.type === 1) {
                            return (
                                <CalculationAvatar
                                    calculation={childElement.content}
                                    key={childElement.content.id}
                                    refresh={refreshContent}
                                    chapterType={chapter.chapterType}
                                />
                            );
                        } else if (childElement.type === 2) {
                            return (
                                <ChildChapterAvatar
                                    {...childElement.content}
                                    key={childElement.content.id}
                                    refresh={refreshContent}
                                />
                            );
                        }
                        return null;
                    })}

                    {chapter.chapterType === 0 && (
                        <div className="buttons-container paper-max-width">
                            <div
                                className="section-add-new"
                                onClick={openParagraphModal}
                            >
                                <FormattedMessage id="section.newparagraph" defaultMessage="Add new paragraph" />

                            </div>

                            <div
                                className="section-add-new"
                                onClick={openCalculationModal}
                            >
                                <FormattedMessage id="section.newcalculation" defaultMessage="Add new calculation" />

                            </div>
                        </div>
                    )}

                    <div className="buttons-container">
                        {chapter.projectId ? (
                            <Link to={`/project/${chapter.projectId}`}>
                                <Button><FormattedMessage id="section.Back" defaultMessage="Back" /></Button>
                            </Link>
                        ) : null}
                        <Button onClick={previewSection.bind(this, user, guid)}>
                            <FormattedMessage id="section.Preview" defaultMessage="Preview" />

                        </Button>
                    </div>
                </Fragment>
            )}

            <Modal
                isOpen={paragraphModalOpen}
                modalTitle={<FormattedMessage id="section.addnewparagrapgh" defaultMessage="New paragraph" />}
                closeModal={closeParagraphModal}
            >
                <ParagraphEditor
                    name={<FormattedMessage id="section.addnewparagrapghname" defaultMessage="Enter new paragraph name" />}
                    content=""
                    onSave={createNewParagraph}
                    close={closeParagraphModal}
                />
            </Modal>

            <Modal
                isOpen={calculationModalOpen}
                modalTitle={<FormattedMessage id="section.addNewcalculation" defaultMessage="New calculation" />}
                closeModal={closeCalculationModal}
            >
                <Form id="new-calculation-form">
                    <FormGroup>
                        <Label for="new-calculation-form">
                            <FormattedMessage id="section.Calculationtype" defaultMessage="Calculation type:" />

                        </Label>
                        <Input
                            type="select"
                            name="new-calculation-form"
                            id="new-calculation-form"
                            onChange={handleFormChange.bind(this)}
                        >
                            {forms
                                .filter((f) => f.apiPath === "api/calculations")
                                .map((f) => (
                                    <option key={f.formId} value={f.formId}>
                                        {f.formId}
                                    </option>
                                ))}
                        </Input>
                    </FormGroup>
                    <div className="buttons-container">
                        <Button onClick={createNewCalculation.bind(this)}>
                            <FormattedMessage id="section.Add" defaultMessage="Add" />

                        </Button>
                        <Button onClick={closeCalculationModal}><FormattedMessage id="section.Cancel" defaultMessage="Cancel" /></Button>
                    </div>
                </Form>
            </Modal>

            <Modal
                isOpen={editSectionOpen}
                closeModal={closeModalForEditSection}
            >
                <SectionEditor
                    name={chapter.name}
                    sectionNumber={chapter.sectionNumber}
                    onSave={saveChanges}
                    close={closeModalForEditSection}
                />
            </Modal>
        </div>
    );
}
export default Section;
