import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { getForm } from "../../services/formsService";
import {
    calculate,
    validate,
    updateCalculation,
    postCalculation,
} from "../../services/calculationService";
import { makeInputFields, parseInputField } from "./InputFieldsHelper";
import ListInputField from "./ListInputField";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import CalculationResult from "../CalculationResult/CalculationResult";
import SaveAsForm from "./SaveAsForm";
import InfoTooltip from "../Tooltip/InfoTooltip";
import Tooltip from "../Tooltip/Tooltip";
import Explanation from "./Explanation";
import "./DynamicForm.css";
import Loading from "../Loading/Loading";
import { FormattedMessage } from 'react-intl';

function predicate(key, value, operation) {
    if (operation === "=") {
        return String(value)
            .split(",")
            .some((v) => String(this.state.watchedValues[key]) === v);
    } else if (operation === "!=") {
        return String(value)
            .split(",")
            .every((v) => String(this.state.watchedValues[key]) !== v);
    } else if (operation === ">") {
        return Number(this.state.watchedValues[key]) > Number(value);
    } else if (operation === "<") {
        return Number(this.state.watchedValues[key]) < Number(value);
    }
}

function joinPredicates(predicates, operation) {
    if (predicates.length === 1) {
        return predicates[0]();
    } else {
        let array = [];

        predicates.forEach((predicate) => {
            array.push(predicate());
        });

        if (operation === "||") {
            return array.some((e) => e);
        } else {
            return array.every((e) => e);
        }
    }
}

function createPredicate(conditionString) {
    let separator = null;
    separator = conditionString.includes("&&") ? "&&" : separator;
    separator = conditionString.includes("||") ? "||" : separator;

    let predicates = [];
    let conditions = separator
        ? conditionString.split(separator)
        : [conditionString];

    conditions.forEach((condition) => {
        let operation = null;
        operation = condition.includes("=") ? "=" : operation;
        operation = condition.includes("!=") ? "!=" : operation;
        operation = condition.includes(">") ? ">" : operation;
        operation = condition.includes("<") ? "<" : operation;

        if (operation) {
            let parts = condition.split(operation);

            let field = parts[0];
            let value = parts[1];

            if (!this.watchedFields.includes(field)) {
                this.watchedFields.push(field);
            }

            predicates.push(predicate.bind(this, field, value, operation));
        }
    });

    if (
        predicates.length > 0 &&
        !this.displayPredicates.hasOwnProperty(conditionString)
    ) {
        this.displayPredicates[conditionString] = joinPredicates.bind(
            this,
            predicates,
            separator
        );
    }
}

var deepValue = function (obj, path) {
    try {
        for (var i = 0, p = path.split("."), len = p.length; i < len; i++) {
            obj = obj[p[i]];
        }
        return obj;
    } catch (e) {
        return null;
    }
};

class DynamicForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: null,
            modalOpen: false,
            modalSaveAsOpen: false,
            selectModalOpen: false,
            jsonString: "{}",
            result: {},
            watchedValues: {},
            validationError: {},
            calculationName: props.calculation
                ? props.calculation.name
                : "New calculation",
            startParams:
                props.calculation && props.calculation.parameters === ""
                    ? null
                    : props.calculation
                        ? JSON.parse(props.calculation.parameters)
                        : null,
        };

        this.createForm = this.createForm.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.setValidationError = this.setValidationError.bind(this);
        this.createFormGroup = this.createFormGroup.bind(this);
        this.getStartValue = this.getStartValue.bind(this);
        this.refresh = (id = this.props.id, user = this.props.user) =>
            getForm(id, user)
                .then((form) => this.generateDisplayPredicates(form))
                .then((form) => this.setInitialWatchedFieldsValues(form))
                .then((form) => this.setState({ form }))
                .catch(() => { });
        this.refresh(props.id, props.user);
    }

    watchedFields = [];

    displayPredicates = {};

    componentDidUpdate(prevProps) {
        if (this.props.id !== prevProps.id) {
            this.refresh();
        }
        if (
            JSON.stringify(this.props.calculation) !==
            JSON.stringify(prevProps.calculation)
        ) {
            if (this.props.calculation) {
                this.setState(
                    {
                        startParams: JSON.parse(
                            this.props.calculation.parameters
                        ),
                        form: null,
                    },
                    this.refresh
                );
            } else {
                this.setState({ startParams: null, form: null }, this.refresh);
            }
        }
    }

    watchValue(key, event) {
        this.setWatchedValue(key, event.target.value);
    }

    setWatchedValue(key, value) {
        let watchedValues = this.state.watchedValues;
        watchedValues[key] = value;
        this.setState({ watchedValues });
    }

    getStartValue(key) {
        return this.props.calculation
            ? deepValue(this.state.startParams, key)
            : null;
    }

    generateDisplayPredicates(form) {
        form.formInputs.forEach((fi) => {
            if (fi.displayCondition) {
                createPredicate.call(this, fi.displayCondition);
            }
        });

        form.explanations.forEach((e) => {
            if (e.displayCondition) {
                createPredicate.call(this, e.displayCondition);
            }
        });

        return form;
    }

    setInitialWatchedFieldsValues(form) {
        form.formInputs.forEach((fi) => {
            if (this.watchedFields.includes(fi.key)) {
                if (fi.type === "Dropdown" || fi.type === "ImageSelector") {
                    let value = this.getStartValue(fi.key);
                    value = value ? value : fi.inputOptions[0].key;
                    this.setWatchedValue(fi.key, value);
                } else if (fi.type === "Bool") {
                    let value = this.getStartValue(fi.key);
                    value = value ? String(value) : "false";
                    this.setWatchedValue(fi.key, value);
                } else if (fi.type === "Int" || fi.type === "Float") {
                    let value = this.getStartValue(fi.key);
                    value = value ? value : "0";
                    this.setWatchedValue(fi.key, value);
                } else if (fi.type === "Text" || fi.type === "Special") {
                    let value = this.getStartValue(fi.key);
                    value = value
                        ? value
                        : fi.inputOptions.length > 0
                            ? fi.inputOptions[0].key
                            : null;
                    value = value ? value : "";
                    this.setWatchedValue(fi.key, value);
                }
            }
        });

        return form;
    }

    createFormGroup(input) {
        let label = <Label for={input.key}>{input.description}</Label>;
        if (input.type === "divider") {
            label = input.description ? (
                <Label className="divider-name" for={input.key}>
                    {input.description}
                </Label>
            ) : null;
        }

        let changeHandler = null;
        if (this.watchedFields.includes(input.key)) {
            changeHandler = this.watchValue.bind(this, input.key);
        }

        let fields = [];

        if (input.isList) {
            fields = [
                <ListInputField
                    key={input.key}
                    input={input}
                    savedValues={this.getStartValue(input.key)}
                />,
            ];
        } else {
            fields = makeInputFields(
                input,
                this.getStartValue(input.key),
                this.state.validationError[input.key],
                { onChange: changeHandler, onBlur: this.onBlur }
            );
        }

        let displayPredicate = () => input.type !== "Hidden";
        if (input.displayCondition) {
            displayPredicate = this.displayPredicates[input.displayCondition];
        }

        return (
            <FormGroup
                className={displayPredicate() ? null : "hidden"}
                check={input.type === "Bool" && !input.isList}
                key={`${input.key}.fg`}
            >
                {label}
                <div className="input-fields-container">
                    {fields}
                    {input.tooltipText && (
                        <InfoTooltip
                            content={input.tooltipText}
                            placement="right"
                        />
                    )}
                </div>
                {this.state.validationError[input.key] && (
                    <p className="error-text">
                        {this.state.validationError[input.key]}
                    </p>
                )}
            </FormGroup>
        );
    }

    getParametersJson() {
        let form = document.getElementById(this.state.form.formId);
        const data = new FormData(form);
        let obj = {};
        for (const [key, str] of data.entries()) {
            let keys = key.split(".");

            var targetObject = obj;
            for (let i = 0; i < keys.length; ++i) {
                if (i < keys.length - 1) {
                    if (!targetObject[keys[i]]) {
                        if (
                            keys[i + 1].includes("[") &&
                            keys[i + 1].includes("]")
                        ) {
                            targetObject[keys[i]] = [];
                        } else {
                            targetObject[keys[i]] = {};
                        }
                    }

                    targetObject = targetObject[keys[i]];
                } else {
                    if (str) {
                        let value = parseInputField(str);
                        if (keys[i].includes("[") && keys[i].includes("]")) {
                            targetObject.push(value);
                        } else {
                            targetObject[keys[i]] = value;
                        }
                    }
                }
            }
        }
        return JSON.stringify(obj);
    }

    handleCalculation() {
        let jsonString = this.getParametersJson();
        this.setState({ jsonString });

        validate(jsonString, this.state.form, this.props.user)
            .then(() => calculate(jsonString, this.state.form, this.props.user))
            .then((response) => response.json())
            .then((result) =>
                this.setState({ result, validationError: {}, modalOpen: true })
            )
            .catch((error) => {
                try {
                    let validationError = JSON.parse(error.message);
                    this.setState({ validationError });
                } catch (err) { }
            });
    }

    setValidationError(validationError) {
        this.setState({ validationError });
    }

    handleUpdate() {
        let parametersString = this.getParametersJson();
        console.log(parametersString);
        let data = {
            id: this.props.calculation.id,
            name: this.state.calculationName,
            parameters: parametersString,
        };

        validate(parametersString, this.state.form, this.props.user)
            .then(() =>
                updateCalculation(
                    this.props.user,
                    this.props.calculation.id,
                    this.state.form,
                    JSON.stringify(data)
                )
            )
            .then(() => {
                this.props.history.push(
                    `/section/${this.props.calculation.chapterId}`
                );
            })
            .catch((error) => {
                try {
                    let validationError = JSON.parse(error.message);
                    this.setState({ validationError });
                } catch (err) { }
            });
    }

    handlePost() {
        let parametersString = this.getParametersJson();

        let data = {
            formId: this.state.form.id,
            chapterId: this.props.targetChapterId,
            name: this.state.calculationName,
            parameters: parametersString,
        };

        validate(parametersString, this.state.form, this.props.user)
            .then(() => postCalculation(this.props.user, JSON.stringify(data)))
            .then(() => {
                this.props.history.push({
                    pathname: `/section/${this.props.targetChapterId}`,
                });
            })
            .catch((error) => {
                try {
                    let validationError = JSON.parse(error.message);
                    this.setState({ validationError });
                } catch (err) { }
            });
    }

    onBlur(event) {
        let validationError = this.state.validationError;
        validationError[event.target.name] = null;
        this.setState({ validationError });
    }

    onCalculationNameChange(event) {
        this.setState({ calculationName: event.target.value });
    }

    openSaveAsForm() {
        this.setState({ modalSaveAsOpen: true });
    }

    closeModal() {
        this.setState({ modalOpen: false });
    }

    closeModalSaveAs() {
        this.setState({ modalSaveAsOpen: false });
    }

    createForm() {
        return (
            <div className="form-container">
                <h3>
                    {this.props.calculation
                        ? this.props.calculation.name
                        : this.state.form.name}
                </h3>
                <div className="form-columns-container">
                    <div className="form-content-container">
                        {(this.props.calculation ||
                            this.props.targetChapterId) && (
                                <div className="calculation-name-container">
                                    <Label for="calculationName">
                                        Calculation name:
                                    </Label>
                                    <Input
                                        type="text"
                                        invalid={
                                            !this.state.calculationName
                                                ? true
                                                : false
                                        }
                                        name="calculationName"
                                        id="calculationName"
                                        value={this.state.calculationName}
                                        autoComplete="off"
                                        onChange={this.onCalculationNameChange.bind(
                                            this
                                        )}
                                    />
                                    {!this.state.calculationName && (
                                        <p className="error-text">
                                            Calculation name can not be empty!
                                        </p>
                                    )}
                                    <hr />
                                </div>
                            )}

                        <Form id={this.state.form.formId}>
                            <Input
                                type="hidden"
                                name="projectId"
                                id="projectId"
                                value={this.props.activeProject.id}
                                hidden={true}
                            />
                            {this.state.form.formInputs.map((fi) =>
                                this.createFormGroup(fi)
                            )}
                        </Form>
                    </div>

                    <div className="form-explanations-container">

                        {this.state.form.explanations.map((e) => (
                            <Explanation
                                key={e.id}
                                display={
                                    e.displayCondition
                                        ? this.displayPredicates[
                                            e.displayCondition
                                        ]()
                                        : true
                                }
                                {...e}
                            />))}
                    </div>


                </div>

                <div className="buttons-container">
                    {(this.state.form.apiPath === "api/calculations" ||
                        this.state.form.apiPath === "api/test") && (
                            <Button onClick={this.handleCalculation.bind(this)}>
                                <FormattedMessage id="dynamicForms.calculate" defaultMessage="Calculate" />

                            </Button>
                        )}
                    {this.props.calculation || this.props.targetChapterId ? (
                        <Button
                            disabled={!this.state.calculationName}
                            onClick={
                                this.props.calculation
                                    ? this.handleUpdate.bind(this)
                                    : this.handlePost.bind(this)
                            }
                        >
                            <FormattedMessage id="dynamicForms.Save" defaultMessage="Save" />

                        </Button>
                    ) : (
                        <div className="tooltips">
                            <Button
                                onClick={this.openSaveAsForm.bind(this)}
                                disabled={
                                    Object.keys(this.props.activeProject)
                                        .length === 0
                                }
                            >
                                <FormattedMessage id="dynamicform.saveas" defaultMessage="Spremi kao" />

                            </Button>
                            {Object.keys(this.props.activeProject).length ===
                                0 && (
                                    <Tooltip
                                        text="You must create a project first in order to save this calculation!"
                                        placement="right"
                                    />
                                )}
                        </div>
                    )}
                    {this.props.calculation || this.props.targetChapterId ? (
                        <Button onClick={this.props.history.goBack}>
                            <FormattedMessage id="dynamicForms.Back" defaultMessage="Back" />

                        </Button>
                    ) : null}
                </div>

                <Modal
                    isOpen={this.state.modalOpen}
                    modalTitle={<FormattedMessage id="dynamicform.results" defaultMessage="Results" />}
                    closeModal={this.closeModal.bind(this)}
                >
                    <div className="form-json-container">
                        <pre>
                            {JSON.stringify(
                                JSON.parse(this.state.jsonString),
                                null,
                                2
                            )}
                        </pre>
                    </div>
                    <CalculationResult data={this.state.result} />
                </Modal>

                <Modal
                    isOpen={this.state.modalSaveAsOpen}
                    closeModal={this.closeModalSaveAs.bind(this)}
                    modalTitle={<FormattedMessage id="dynamicform.createcalc" defaultMessage="Create calculation for project" />}
                >
                    <SaveAsForm
                        form={this.state.form}
                        getParams={this.getParametersJson.bind(this)}
                        setValidationError={this.setValidationError}
                        close={this.closeModalSaveAs.bind(this)}
                    />
                </Modal>
            </div>
        );
    }

    render() {
        return this.state.form ? this.createForm() : <Loading />;
    }
}

function mapStateToProps(state) {
    const user = state.user;
    const activeProject = state.activeProject;
    return { user, activeProject };
}

export default withRouter(connect(mapStateToProps)(DynamicForm));
