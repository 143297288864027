import { getAuthorizationProperties } from "./authorizationHelper";

export function getChapters(user, guid) {
    return fetch(`api/chapters?project=${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function postChapter(user, state) {
    return fetch("api/chapters", {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: state,
    }).then((response) => response.json());
}

export function moveChapter(user, state, direction) {
    return fetch(`api/chapters/moveContent?direction=${direction}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(state),
    });
}

export function moveHeadChapter(user, state, direction) {
    return fetch(`api/chapters/move?direction=${direction}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            Accept: "application/json",
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(state),
    });
}

export function updateSection(user, guid, section) {
    return fetch(`api/chapters/${guid}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(section),
    });
}

export function getChapter(user, guid) {
    return fetch(`api/chapters/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function deleteChapter(user, guid) {
    return fetch(`api/chapters/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}

export function getChapterContent(user, guid) {
    return fetch(`api/chapters/${guid}/content`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}
