import { getAuthorizationProperties } from "./authorizationHelper";

export function getCompanies(user) {
    return fetch("api/Companies/all", {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getCompany(user, guid) {
    return fetch(`api/Companies/${guid}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function updateCompanyInfo(user, guid, obj) {
    return fetch(`api/Companies/${guid}`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(obj),
    });
}

export function getCompanyUsers(user, id) {
    return fetch(`api/Companies/${id}/users`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getCompanyProjects(user, id) {
    return fetch(`api/Companies/${id}/projects`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export async function addEmployeeToCompany(user, guid, data) {
    return await fetch(`api/Companies/${guid}/addUser`, {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(data),
    });
}

export async function removeEmployeeFromCompany(user, guid, userGuid) {
    return await fetch(`api/Companies/${guid}/removeUser/${userGuid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}

export async function addAddPaymentInfo(user, guid, paymentMethod) {
    return await fetch(`api/Companies/${guid}/addPaymentInfo`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(paymentMethod),
    });
}

export async function deleteCreditCardInfo(user, guid) {
    return await fetch(`api/Companies/${guid}/deleteCreditCardInfo`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        })
    });
}

export async function subscribe(user, guid) {
    return await fetch(`api/Companies/${guid}/subscribe`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.json();
        }
    });
}

export async function unsubscribe(user, guid) {
    return await fetch(`api/Companies/${guid}/unsubscribe`, {
        method: "PUT",
        ...getAuthorizationProperties(user.token),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}

export async function deleteCompany(user, guid) {
    return await fetch(`api/Companies/${guid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token),
    });
}

export function getAssociates(user, guid, role) {
    if (role)
        return fetch(`api/Companies/${guid}/associates?role=${role}`, {
            method: "GET",
            ...getAuthorizationProperties(user.token, {
                "Content-Type": "application/json",
            }),
        }).then((response) => response.json());
    else
        return fetch(`api/Companies/${guid}/associates`, {
            method: "GET",
            ...getAuthorizationProperties(user.token, {
                "Content-Type": "application/json",
            }),
        }).then((response) => response.json());
}

export function addAssociate(user, guid, data) {
    return fetch(`api/Companies/${guid}/associates`, {
        method: "POST",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
        body: JSON.stringify(data),
    }).then((response) => response.json());
}

export function deleteAssociate(user, guid, associateGuid) {
    return fetch(`api/Companies/${guid}/associates/${associateGuid}`, {
        method: "DELETE",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    });
}

export function getCompanyPayments(user, count) {
    return fetch(`api/Companies/${user.companyId}/payments?count=${count}`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => response.json());
}

export function getCompanyPaymentMethod(user) {
    return fetch(`api/Companies/${user.companyId}/paymentMethod`, {
        method: "GET",
        ...getAuthorizationProperties(user.token, {
            "Content-Type": "application/json",
        }),
    }).then((response) => {
        if (!response.ok) {
            return response.text().then((text) => {
                throw Error(text);
            });
        } else {
            return response.text();
        }
    });
}

export default {
    getCompany,
    updateCompanyInfo,
    getCompanyUsers,
    addEmployeeToCompany,
    removeEmployeeFromCompany,
    addAddPaymentInfo,
    subscribe,
    unsubscribe,
    deleteCompany,
    addAssociate,
    deleteAssociate,
    getCompanyPayments,
    getCompanyPaymentMethod
};
