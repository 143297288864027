import { ADD_IMAGE_GUID, REFRESH_IMAGE } from "../actions/imageAction";

const initialState = { guids: [], timestamps: [] };

function imageReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_IMAGE_GUID:
            if (!state.guids.includes(action.imageGuid)) {
                return {
                    ...state, guids: [...state.guids, action.imageGuid]
                }
            }
            return state;
        case REFRESH_IMAGE:
            let newTimestamps = state.timestamps.filter(t => t.guid !== action.imageGuid);
            newTimestamps.push({
                guid: action.imageGuid,
                timestamp: performance.now()
            })
            return { ...state, timestamps: newTimestamps}
        default:
            return state;
    }
}

export default imageReducer;
