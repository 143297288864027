import React, { useState, useEffect, Fragment } from "react";
import { Input } from "reactstrap";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";

export const MapField = (props) => {
    const [values, setValues] = useState({
        mapName: "",
        companyName: "",
        companyAddress: "",
        td: "",
        designer: "",
    });

    useEffect(() => {
        if (props.startValue) {
            setValues(parseInputField(props.startValue));
        }
    }, [props.startValue]);

    return (
        <Fragment>
            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(values)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 1 : 0}
                type="text"
                placeholder="Map name"
                value={values.mapName}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) =>
                    setValues({ ...values, mapName: e.target.value })
                }
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="text"
                placeholder="Company name"
                value={values.companyName}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) =>
                    setValues({ ...values, companyName: e.target.value })
                }
                style={{ margin: "2px 0" }}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="text"
                placeholder="Company address"
                value={values.companyAddress}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) =>
                    setValues({ ...values, companyAddress: e.target.value })
                }
                style={{ margin: "2px 0" }}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="text"
                placeholder="T.D."
                value={values.td}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) => setValues({ ...values, td: e.target.value })}
                style={{ margin: "2px 0" }}
            />
            <Input
                key={props.jsonKey ? props.jsonKey + 2 : 0}
                type="text"
                placeholder="Designer"
                value={values.designer}
                lang="en"
                autoComplete="off"
                onBlur={props.onBlur}
                invalid={props.invalid}
                onChange={(e) =>
                    setValues({ ...values, designer: e.target.value })
                }
            />
        </Fragment>
    );
};
