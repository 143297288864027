import React from "react";
import "./EventsList.css";
import { ListGroup, ListGroupItem, Spinner } from "reactstrap";
import { timeSince } from "../../services/dashboardService";
import { Link } from "react-router-dom";
import Image from "../EurocodeImage/Image";
import avatar from "../UserAvatar/user-image.jpg";

export const EventsList = ({ events, count, page, loadMore }) => {
    events.forEach((e) => {
        if (typeof e.message == "string") {
            let parts = e.message.split(/{(.*?)}/);
            let userIndex = parts.indexOf("user");
            if (userIndex > -1) {
                parts[userIndex] = (
                    <Link key={e.id + e.user.guid} to="/company">
                        <Image
                            id="user-avatar"
                            placeholder={avatar}
                            imageId={e.user.guid}
                        />
                        {e.user.firstName} {e.user.lastName}
                    </Link>
                );
            }
            let projectIndex = parts.indexOf("project");
            if (projectIndex > -1) {
                parts[projectIndex] = (
                    <Link
                        key={e.id + e.project.id}
                        to={`/project/${e.project.id}`}
                    >
                        {e.project.name}
                    </Link>
                );
            }
            let sectionIndex = parts.indexOf("section");
            if (sectionIndex > -1) {
                parts[sectionIndex] = (
                    <Link
                        key={e.id + e.section.id}
                        to={`/section/${e.section.id}`}
                    >
                        {e.section.name}
                    </Link>
                );
            }
            let calculationIndex = parts.indexOf("calculation");
            if (calculationIndex > -1) {
                parts[calculationIndex] = (
                    <Link
                        key={e.id + e.calculation.id}
                        to={`/section/${e.section.id}`}
                    >
                        {e.calculation.name}
                    </Link>
                );
            }
            e.message = parts;
        }
    });

    return (
        <ListGroup>
            {events.length > 0 ? (
                events.map((event) => (
                    <ListGroupItem key={event.id}>
                        <p className="message">{event.message}</p>
                        <p className="timestamp">
                            {" "}
                            {timeSince(new Date(event.created))}
                        </p>
                    </ListGroupItem>
                ))
            ) : (
                <ListGroupItem className="loading-events">
                    <Spinner />
                </ListGroupItem>
            )}
            {Math.ceil(count / 10) !== page && (
                <ListGroupItem className="load-more" onClick={loadMore}>
                    Load more
                </ListGroupItem>
            )}
        </ListGroup>
    );
};
