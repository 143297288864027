import React, { useState } from "react";
import { Input, Button, Label } from "reactstrap";
import Image from "../EurocodeImage/Image";
import placeholder from "../EurocodeImage/placeholder.jpg";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import "./ImageSelector.css";
import { FormattedMessage } from "react-intl";

export const ImageSelector = ({ input, changeHandler, startValue }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const selectImage = (key, io) => {
        document.getElementsByClassName(`${key}-key`)[0].value = io.key;
        document.getElementsByClassName(`${key}-text`)[0].value = io.text;
        setModalOpen(false);
    };

    return (
        <div className="input-group">
            <Input
                type="number"
                name={input.key}
                key={input.key}
                id={input.key}
                className={input.key + "-key"}
                hidden
                defaultValue={
                    startValue ? startValue : input.inputOptions[0].key
                }
            />
            <Input
                type="text"
                className={input.key + "-text"}
                disabled
                defaultValue={
                    startValue
                        ? input.inputOptions.filter(
                            (x) => x.key === startValue
                        )[0].text
                        : input.inputOptions.filter(
                            (x) => x.key === input.inputOptions[0].key
                        )[0].text
                }
            />
            <Button onClick={() => setModalOpen(true)}> <FormattedMessage id="imageselector.change" defaultMessage="Change" /></Button>

            <Modal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                modalTitle={input.description}
            >
                <div className="modal-images">
                    {input.inputOptions
                        .sort((a, b) =>
                            a.key > b.key ? 1 : b.key > a.key ? -1 : 0
                        )
                        .map((io) => (
                            <div
                                key={input.key + io.key}
                                onClick={() => {
                                    selectImage(input.key, io);
                                    changeHandler && changeHandler({
                                        target: { value: io.key },
                                    });
                                }}
                                className="modal-image-card"
                            >
                                <div className="image-background">
                                    <Image
                                        placeholder={placeholder}
                                        imageId={io.itemGuid}
                                    />
                                </div>
                                <Label>{io.text}</Label>
                            </div>
                        ))}
                </div>
            </Modal>
        </div>
    );
};
