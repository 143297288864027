import { getAuthorizationProperties } from "./authorizationHelper";

export async function getSk(guid, user) {
  const response = await fetch(`/api/recommendedvalues/snow/sk?guid=${guid}`, {
    method: "GET",
    ...getAuthorizationProperties(user.token)
  });
  return Number(await response.text());
}

export async function getCase(guid, user) {
  const response = await fetch(
    `/api/recommendedvalues/snow/case?guid=${guid}`,
    {
      method: "GET",
      ...getAuthorizationProperties(user.token)
    }
  );
  return await response.text();
}
