import { TOGGLE_SIDEBAR, SELECT_NAVIGATION_ITEM } from '../actions/sidebarActions';

const initialState = {
    expanded: false,
    active_item: ''
}

function sidebarReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, expanded: !state.expanded }
        case SELECT_NAVIGATION_ITEM:
            return { ...state, active_item: action.item }
        default:
            return state
    }
}

export default sidebarReducer;
