import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router";
import { Button, FormGroup, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as IDetails } from "./Icons/Ikona_company.svg";
import { ReactComponent as IPayments } from "./Icons/Ikona_credit_card.svg";
import { ReactComponent as IDelete } from "./Icons/Delete.svg";
import EditCompany from "../EditCompany/EditCompany";
import CompanyPayments from "../CompanyPayments/CompanyPayments";
import "./Company.css";
import { getCompany, deleteCompany } from "../../services/companyService";
import { login } from "../../services/autentificationService";
import Modal from "../EurocodeModal/EurocodeModal";
import { logout } from "../../actions/authentificationActions";
import { FormattedMessage } from 'react-intl';

const Company = () => {
    const user = useSelector((state) => state.user);
    const [company, setCompany] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [password, setPassword] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        getCompany(user, user.companyId).then((res) => setCompany(res));
    }, [user]);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setShowErrorMessage(false);
        setPassword("");
    };

    const handleDeleteCompany = async () => {
        try {
            const auth = await login(user.username, password);
            if (Object.keys(auth).length > 0) {
                const res = await deleteCompany(user, user.companyId);
                if (res.status === 200) {
                    dispatch(logout());
                }
            }
        } catch (err) {
            setShowErrorMessage(true);
        }
    };

    return (
        <div className="company-container">
            <h3>{company.name}</h3>
            <hr></hr>
            {user.role.includes("Admin") ? (
                <div className="company-tabs-container">
                    <Link to="/company/details">
                        <Button className="company-tab-button">
                            <IDetails />
                            <FormattedMessage id="company.details" defaultMessage= "Details"/>
                            
                        </Button>
                    </Link>
                    <Link to="/company/payments">
                        <Button className="company-tab-button">
                            <IPayments />
                            <FormattedMessage id="company.payments" defaultMessage="Payments" />
                            
                        </Button>
                    </Link>
                    <Button
                        onClick={() => setModalOpen(true)}
                        className="company-tab-button"
                    >
                        <IDelete />
                        <FormattedMessage id="company.delete" defaultMessage="Delete" />
                        
                    </Button>
                </div>
            ) : null}

            <Switch>
                <Route exact path="/company/details" component={EditCompany} />
                <Route
                    exact
                    path="/company/payments"
                    component={CompanyPayments}
                />
                <Route component={EditCompany} />
            </Switch>

            <Modal
                isOpen={modalOpen}
                modalTitle={<FormattedMessage id="company.sure" defaultMessage="Are you sure you want to delete your company?" />}
                closeModal={handleCloseModal}
            >
                <h5 id="modal-message">
                    <FormattedMessage id="company.irreversibly" defaultMessage="You will irreversibly lose all of your projects and
                    calculations!" />
                   
                </h5>{" "}
                <FormGroup>
                    <p> <FormattedMessage id="company.enterpassword" defaultMessage="Please type in your password to confirm this action:" /></p>
                    <Input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder=""
                    />
                    {showErrorMessage && (
                        <p id="error-message"><FormattedMessage id="company.incorrectpass" defaultMessage="Password incorrect!" /></p>
                    )}
                </FormGroup>
                <div className="modal-buttons">
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleDeleteCompany}>Confirm</Button>
                </div>
            </Modal>
        </div>
    );
};

export default Company;
