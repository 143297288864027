import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { loggedIn, token, companyId, role } = rest;

    let redirectPath = token && loggedIn ? '/ContactAdmin' : '/Login'

    return(
        <Route {...rest} render={props => (
            token && loggedIn && (companyId || role === 'Admin')
                ? <Component {...props} />
                : <Redirect to={{ pathname: redirectPath, state: { from: props.location } }} />
        )} />
    );
}

function mapStateToProps(state) {
    const { token, companyId, role } = state.user;
    const { loggedIn } = state.login;
    return { token, loggedIn, companyId, role };
}

export default connect(mapStateToProps)(PrivateRoute);
