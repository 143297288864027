import React, { useState, useEffect, Fragment } from "react";
import { useDropzone } from "react-dropzone";
import { Input } from "reactstrap";
import "./ImageInputField.css";
import { postImage, deleteImage } from "../../../../services/imageService";
import { useSelector } from "react-redux";
import { ReactComponent as Delete } from "../../../ActionsBar/Icons/Delete.svg";
import { parseInputField, saveJsonInputField } from "../../InputFieldsHelper";

export const ImageInputField = (props) => {
    const user = useSelector((state) => state.user);
    const [images, setImages] = useState([]);

    const onDrop = (accepted) => {
        let promises = [];
        let imagesToAdd = [];
        accepted.forEach((img) => {
            promises.push(
                postImage(user, img).then((res) => {
                    let image = {
                        id: res.id,
                        name: img.name,
                    };
                    imagesToAdd.push(image);
                })
            );
        });
        Promise.all(promises).then(() => setImages(images.concat(imagesToAdd)));
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/*",
        onDrop,
    });

    const removeImage = (id) => {
        deleteImage(user, id).then(() => {
            setImages(images.filter((e) => e.id !== id));
        });
    };

    useEffect(() => {
        if (props.startValue) {
            setImages(parseInputField(props.startValue));
        }
    }, [props.startValue]);

    return (
        <Fragment>
            <Input
                key={props.jsonKey ? props.jsonKey : 0}
                type="hidden"
                name={props.jsonKey}
                value={saveJsonInputField(images)}
                lang="en"
                autoComplete="off"
                readOnly={true}
                hidden={true}
            />
            <div {...getRootProps()} className="dropzone-container">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <h5>Drop the files here ...</h5>
                ) : (
                    <h5>Drag 'n' drop files here or click to select files</h5>
                )}
            </div>
            {images.length > 0 && (
                <div className="images-preview">
                    <h5>Files:</h5>
                    {images.map((image) => (
                        <div key={image.id}>
                            <p>
                                <a
                                    href={`/api/Images/${image.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {image.name}
                                </a>
                            </p>
                            <Delete onClick={() => removeImage(image.id)} />
                        </div>
                    ))}
                </div>
            )}
        </Fragment>
    );
};
