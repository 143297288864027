import React from 'react';
import { useDispatch } from "react-redux";
import { Button } from 'reactstrap';
import { logout } from "../../actions/authentificationActions";
import RedirectIfHasCompany from '../../components/RedirectIfHasCompany';
import './ContactAdmin.css';
import { FormattedMessage } from 'react-intl';

function ContactAdmin(props) {
    const dispatch = useDispatch();

    return (
        <div className="container-center contact-admin-container">
            <RedirectIfHasCompany />
            <h2><FormattedMessage id ="contactadmin.contact" defaultMessage ="Contact your company admin to add your account to your company" /></h2>
            <Button onClick={() => dispatch(logout())}>Try again</Button>
        </div>
    );
}

export default ContactAdmin;