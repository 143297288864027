import React, { useEffect, useState, Fragment } from "react";
import { addAssociate } from "../../../../services/companyService";
import { getAssociates } from "../../../../services/companyService";
import { useSelector } from "react-redux";
import { Input } from "reactstrap";
import { CompanyAssociateForm } from "../../../../containers/EditCompany/CompanyAssociateForm";

export const CompanyAssociatesField = (props) => {
    const user = useSelector((state) => state.user);
    const [associates, setAssociates] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selected, setSelected] = useState("");

    useEffect(() => {
        getAssociates(user, user.companyId, props.jsonKey).then((a) =>
            setAssociates(a)
        );
        if (props.startValue) setSelected(props.startValue);
    }, [props.jsonKey, props.startValue, user]);

    const handleAddAssociate = (a) => {
        addAssociate(user, user.companyId, a).then(() => {
            setAssociates({ ...associates, a });
            setModalOpen(false);
        });
    };

    return (
        <Fragment>
            <Input
                id={props.jsonKey}
                key={props.jsonKey}
                name={props.jsonKey}
                type="select"
                onChange={(e) => setSelected(e.target.value)}
                value={selected}
            >
                <Fragment>
                    {associates.map((associate) => (
                        <option
                            key={associate.guid}
                            value={`${associate.fullName}, ${associate.title}`}
                        >{`${associate.fullName}, ${associate.title}`}</option>
                    ))}
                    <option disabled>────────────</option>
                    <option onClick={() => setModalOpen(true)}>
                        Add new{" "}
                        {props.jsonKey.replace(/([A-Z])/g, " $1").trim()}...
                    </option>
                </Fragment>
            </Input>
            <CompanyAssociateForm
                modalOpen={modalOpen}
                handleCloseModal={() => setModalOpen(false)}
                handleAddAssociate={handleAddAssociate}
                selectedRole={props.jsonKey}
            />
        </Fragment>
    );
};
