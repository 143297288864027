import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

function SectionEditor(props) {
    const [newName, setNewName] = useState(props.name);
    const [newSectionNumber, setNewSectionNumber] = useState(props.sectionNumber);

    let onNameChange = (event) => {
        setNewName(event.target.value);
    }

    let onSectionNumberChange = (event) => {
        setNewSectionNumber(event.target.value);
    }

    let saveChanges = () => {
        let data = { name: newName, sectionNumber: newSectionNumber };
        props.onSave(data);
        props.close();
    }

    return (
        <Form>
            <FormGroup>
                <Label for='section-name'><FormattedMessage id="sectioneditor.name" defaultMessage="Section name:" /></Label>
                <Input type='text' name='section-name' key='section-name' id='section-name' value={newName} onChange={onNameChange} />
            </FormGroup>
            <FormGroup>
                <Label for='section-sectionNumber'><FormattedMessage id="sectioneditor.nameq" defaultMessage="Section name:" /></Label>
                <Input type='text' name='section-sectionNumber' key='sectionNumber' id='section-sectionNumber' value={newSectionNumber} onChange={onSectionNumberChange} />
            </FormGroup>
            <div className='buttons-container'>
                <Button onClick={saveChanges}>Save</Button>
                <Button onClick={props.close}>Cancel</Button>
            </div>
        </Form>
    )
}

export default SectionEditor;