export const CLEAR_ACTIVE_PROJECT = "CLEAR_ACTIVE_PROJECT";
export const SET_ACTIVE_PROJECT = "SET_ACTIVE_PROJECT";

export function SetActiveProject(project) {
    return { type: SET_ACTIVE_PROJECT, project };
}

export function ClearActiveProject() {
    return { type: CLEAR_ACTIVE_PROJECT };
}
