import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import ActionsBar from "../ActionsBar/ActionsBar";
import { useHistory } from "react-router-dom";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import { getFormById } from "../../services/formsService";
import { deleteCalculation } from "../../services/calculationService";
import CalculationResult from "../CalculationResult/CalculationResult";
import { moveChapter } from "../../services/chaptersService";
import "./CalculationAvatar.css";

function CalculationAvatar(props) {
    const [actionsBarOpen, setActionsBarOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [form, setForm] = useState({});
    const history = useHistory();
    const user = useSelector((state) => state.user);

    let showActions = setActionsBarOpen.bind(this, true);
    let hideActions = setActionsBarOpen.bind(this, false);

    let openDeleteDialog = setDeleteDialogOpen.bind(this, true);
    let closeDeleteDialog = setDeleteDialogOpen.bind(this, false);

    let removeCalculation = () =>
        deleteCalculation(user, props.calculation.id).then(props.refresh);

    useEffect(() => {
        getFormById(props.calculation.formId, user).then((f) => setForm(f));
    }, [props.calculation, user]);

    let editCalculation = () => {
        history.push({
            pathname: `/${form.formId}`,
            state: { calculation: props.calculation },
        });
    };

    let moveChapterUp = () => {
        moveChapter(
            user,
            {
                id: props.calculation.id,
                type: 1,
                content: {
                    name: props.calculation.name,
                    chapterId: props.calculation.id,
                    formId: props.calculation.formId,
                    parameters: props.calculation.parameters,
                },
            },
            "up"
        ).then(props.refresh);
    };

    let moveChapterDown = () => {
        moveChapter(
            user,
            {
                id: props.calculation.id,
                type: 1,
                content: {
                    name: props.calculation.name,
                    chapterId: props.calculation.id,
                    formId: props.calculation.formId,
                    parameters: props.calculation.parameters,
                },
            },
            "down"
        ).then(props.refresh);
    };

    return (
        <div
            className="calculation-avatar-container"
            onMouseOver={showActions}
            onMouseLeave={hideActions}
        >
            <p>{props.calculation.name}:</p>
            <div className="calculation-content-container">
                <div className="calculation-actions-container">
                    <ActionsBar
                        active={actionsBarOpen}
                        onDelete={
                            props.chapterType === 0 ? openDeleteDialog : null
                        }
                        onEdit={editCalculation}
                        onMoveUp={
                            props.chapterType === 0 ? moveChapterUp : null
                        }
                        onMoveDown={
                            props.chapterType === 0 ? moveChapterDown : null
                        }
                    />
                </div>
                <strong>{form.name}</strong>
                {form.apiPath === 'api/calculations' && <p className="calculation-content-results">Results:</p>}
                {props.calculation.results ? (
                    <CalculationResult
                        data={JSON.parse(props.calculation.results)}
                    />
                ) : null}
            </div>

            <Modal isOpen={deleteDialogOpen} closeModal={closeDeleteDialog}>
                <p>{`Are you sure you want to delete calculation ${props.calculation.name}`}</p>
                <div className="buttons-container">
                    <Button onClick={removeCalculation}>Yes</Button>
                    <Button onClick={closeDeleteDialog}>No</Button>
                </div>
            </Modal>
        </div>
    );
}
export default CalculationAvatar;
