import { SENDING_LOGIN_REQUEST, SENDING_REGISTER_REQUEST, REQUEST_LOGIN_ERROR, SET_AUTH, CLEAR_LOGIN_ERROR, SET_SUBSCRIBING, CLEAR_SUBSCRIBING } from '../actions/authentificationActions';

var savedState = sessionStorage.getItem('loggedIn');
// The initial application state
let initialState = {
    error: null,
    currentlySending: false,
    loggedIn: savedState ? JSON.parse(savedState) : false,
    currentlySubscribing: false,
}

// Takes care of changing the application state
function loginReducer(state = initialState, action) {
    switch (action.type) {
        case SET_AUTH:
            return { ...state, loggedIn: action.newAuthState }
        case SENDING_LOGIN_REQUEST:
            return { ...state, currentlySending: action.sending }
        case SENDING_REGISTER_REQUEST:
            return { ...state, currentlySending: action.sending }
        case REQUEST_LOGIN_ERROR:
            return { ...state, error: action.error }
        case CLEAR_LOGIN_ERROR:
            return { ...state, error: null }
        case SET_SUBSCRIBING:
            return { ...state, currentlySubscribing: true }
        case CLEAR_SUBSCRIBING:
            return { ...state, currentlySubscribing: false }
        default:
            return state
    }
}

export default loginReducer;
