import React from "react";

export const DisableProjectDropdown = () => {
    return (
        <style
            dangerouslySetInnerHTML={{
                __html:
                    ".dropbtn-disabled { pointer-events: none; opacity: 0.3; }",
            }}
        />
    );
};
