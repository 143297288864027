import React from "react";
import { Table } from "reactstrap";
import { FormattedMessage } from 'react-intl';

export const SnowLoadCalculationCaseTable = ({ snowLoadCase }) => {
    if (snowLoadCase === "a") {
        return (
            <Table bordered>
                <tbody>
                    <tr>
                        <th scope="row"><FormattedMessage id="snow.casea" defaultMessage="Case A" /></th>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage id="snow.Noexceptionalfalls" defaultMessage="No exceptional falls" />
                            <br />
                            <FormattedMessage id="snow.Noexceptionaldrift" defaultMessage="No exceptional drift" />

                        </td>
                    </tr>
                    <tr>
                        <td>3.2(1)</td>
                    </tr>
                    <tr>
                        <td>
                            <em><FormattedMessage id="snow.PersistentDesign" defaultMessage="Persistent/transient design situation" /></em>
                            <br />
                            <FormattedMessage id="snow.1undrifted" defaultMessage="[1] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <FormattedMessage id="snow.2drifted" defaultMessage="[2] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    } else if (snowLoadCase === "b1") {
        return (
            <Table bordered>
                <tbody>
                    <tr>
                        <th scope="row"><FormattedMessage id="snow.CaseB1" defaultMessage="Slučaj B1" /></th>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage id="snow.1bExceptionalfalls" defaultMessage="Exceptional falls" />
                            <br />
                            <FormattedMessage id="snow.1bNoexceptionaldrift" defaultMessage="No exceptional drift" />

                        </td>
                    </tr>
                    <tr>
                        <td>3.3(1)</td>
                    </tr>
                    <tr>
                        <td>
                            <em><FormattedMessage id="snow.1bPersistentdesign" defaultMessage="Persistent/transient design situation" /></em>
                            <br />
                            <FormattedMessage id="snow.1bundrifted" defaultMessage="[1] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <FormattedMessage id="snow.1bdrifted" defaultMessage="[2] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <br />
                            <em>
                                <FormattedMessage id="snow.1bAccidental" defaultMessage="Accidental design situation (where snow is the
                                accidental situation)" />

                            </em>
                            <br />
                            <FormattedMessage id="snow.1bundrifted3" defaultMessage="[3] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                C<sub>esl</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            [4] drifted{" "}
                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                C<sub>esl</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    } else if (snowLoadCase === "b2") {
        return (
            <Table bordered>
                <tbody>
                    <tr>
                        <th scope="row"><FormattedMessage id="snow.CaseB2" defaultMessage="Case B2" /></th>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage id="snow.2bNoexceptionalfalls" defaultMessage="No exceptional falls" />

                            <br />
                            <FormattedMessage id="snow.2bExceptionaldrift" defaultMessage="Exceptional drift" />

                        </td>
                    </tr>
                    <tr>
                        <td>3.3(2)</td>
                    </tr>
                    <tr>
                        <td>
                            <em><FormattedMessage id="snow.2bPersistentdesign" defaultMessage="Persistent/transient design situation" /></em>
                            <br />
                            <FormattedMessage id="snow.2bundrifted" defaultMessage="[1] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <FormattedMessage id="snow.2bdrifted" defaultMessage="[2] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>{" "}
                            <FormattedMessage id="snow.2bexceptAnnexB" defaultMessage="(except for roof shapes in AnnexB)" />

                            <br />
                            <br />
                            <em>
                                <FormattedMessage id="snow.2bAccidentaldesign" defaultMessage="Accidental design situation (where snow is the
                                accidental situation)" />

                            </em>
                            <br />
                            <FormattedMessage id="snow.2bdrifted3" defaultMessage="[3] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>{" "}
                            <FormattedMessage id="snow.roofshapesinAnnexB" defaultMessage="(for roof shapes in AnnexB)" />

                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    } else if (snowLoadCase === "b3") {
        return (
            <Table bordered>
                <tbody>
                    <tr>
                        <th scope="row"><FormattedMessage id="snow.CaseB3" defaultMessage="Case B3" /></th>
                    </tr>
                    <tr>
                        <td>
                            <FormattedMessage id="snow.3bExceptionalfalls" defaultMessage="Exceptional falls" />

                            <br />
                            <FormattedMessage id="snow.3bExceptionaldrift" defaultMessage="Exceptional drift" />

                        </td>
                    </tr>
                    <tr>
                        <td>3.3(3)</td>
                    </tr>
                    <tr>
                        <td>
                            <em><FormattedMessage id="snow.3bPersistenttransientdesign" defaultMessage="Persistent/transient design situation" /></em>
                            <br />
                            <FormattedMessage id="snow.3bundrifted" defaultMessage="[1] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <FormattedMessage id="snow.3bdrifted2" defaultMessage="[2] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>{" "}
                            <FormattedMessage id="snow.3broofshapesinAnnexB" defaultMessage="(except for roof shapes in AnnexB)" />

                            <br />
                            <br />
                            <em>
                                <FormattedMessage id="snow.3bAccidentaldesignsituation" defaultMessage="Accidental design situation (where snow is the
                                accidental situation)" />

                            </em>
                            <br />
                            <FormattedMessage id="snow.3bundrifted3" defaultMessage="[3] undrifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                C<sub>e</sub>
                            </em>{" "}
                            <em>
                                C<sub>t</sub>
                            </em>{" "}
                            <em>
                                C<sub>esl</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>
                            <br />
                            <FormattedMessage id="snow.3bdrifted4" defaultMessage="[4] drifted" />{" "}

                            <em>
                                μ<sub>i</sub>
                            </em>{" "}
                            <em>
                                s<sub>k</sub>
                            </em>{" "}
                            <FormattedMessage id="snow.3broofshapesinAnnexB4" defaultMessage="(for roof shapes in AnnexB)" />

                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    }
};
