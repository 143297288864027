import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import {
    getCompany,
    updateCompanyInfo,
    getCompanyUsers,
    addEmployeeToCompany,
    removeEmployeeFromCompany,
    addAssociate,
    getAssociates,
    deleteAssociate,
} from "../../services/companyService";
import { getCountries } from "../../services/projectsService";
import { useSelector, useDispatch } from "react-redux";
import CompanyUserItem from "../EditCompany/CompanyUserItem";
import Modal from "../../containers/EurocodeModal/EurocodeModal";
import "./EditCompany.css";
import Tooltip from "../../components/Tooltip/Tooltip";
import InfoBox from "../../components/InfoBox/InfoBox";
import defaultPicture from "../Company/Company.png";
import Dropzone from "../Dropzone/Dropzone";
import { putImage } from "../../services/imageService";
import { ReactComponent as Delete } from "../../components/ActionsBar/Icons/Delete.svg";
import { CompanyAssociateForm } from "./CompanyAssociateForm";
import { CompanyDetails } from "./CompanyDetails";
import { RefreshImage } from "../../actions/imageAction";
import Loading from "../../components/Loading/Loading";
import { FormattedMessage } from 'react-intl';

function getCroppedImg(crop, fileName) {
    let image = document.querySelector("img.ReactCrop__image");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image",
            1
        );
    });
}

const EditCompany = () => {
    const [company, setCompany] = useState({
        name: "",
        postalCode: "",
        city: "",
        country: "",
        email: "",
        telephone: "",
        address: "",
        mobilePhone: "",
        fax: "",
        vat: "",
        iban: "",
        website: "",
    });
    const user = useSelector((state) => state.user);
    const [editMode, setEditMode] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employeesModalOpen, setEmployeesModalOpen] = useState(false);
    const [associatesModalOpen, setAssociatesModalOpen] = useState(false);
    const [employeeEmail, setEmployeeEmail] = useState(false);
    const [addToAllProjects, setAddToAllProjects] = useState(true);
    const [errorVisible, setErrorVisible] = useState(false);
    const [countriesData, setCountriesData] = useState({});
    const [imageDropped, setImageDropped] = useState(false);
    const [refreshThumb, setRefreshThumb] = useState(false);
    const [crop, setCrop] = useState(false);
    const [associates, setAssociates] = useState([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getCountries().then((cd) => setCountriesData(cd));
        getCompany(user, user.companyId).then((c) => setCompany(c));
        getCompanyUsers(user, user.companyId).then((e) => setEmployees(e));
        getAssociates(user, user.companyId).then((a) => {
            setAssociates(a);
            setLoading(false);
        });
    }, [user]);

    const handleAddEmployeeClick = () => {
        setEmployeesModalOpen(true);
        setErrorVisible(false);
    };

    const handleUpdate = () => {
        updateCompanyInfo(user, user.companyId, company);
        if (imageDropped) {
            getCroppedImg(crop, user.companyId).then((imageData) => {
                putImage(user, imageData, user.companyId).then(() => {
                    dispatch(RefreshImage(user.companyId));
                });
            });
        }
        setEditMode(false);
    };

    const handleRemoveEmployee = (guid) => {
        removeEmployeeFromCompany(user, user.companyId, guid).then(() =>
            setEmployees(employees.filter((u) => u.guid !== guid))
        );
    };

    const handleAddEmployee = () => {
        let data = {
            username: employeeEmail,
            addToAllProjects: addToAllProjects,
        };
        addEmployeeToCompany(user, user.companyId, data).then((res) => {
            if (res.status === 404) setErrorVisible(true);
            if (res.status === 200) {
                setEmployeesModalOpen(false);
                getCompanyUsers(user, user.companyId).then((e) =>
                    setEmployees(e)
                );
            }
        });
    };

    const handleAddAssociate = (associate) => {
        addAssociate(user, user.companyId, associate).then((res) => {
            setAssociates(associates.concat(res));
            setAssociatesModalOpen(false);
        });
    };

    const handleDeleteAssociate = (guid) => {
        deleteAssociate(user, user.companyId, guid).then(() =>
            setAssociates(associates.filter((x) => x.guid !== guid))
        );
    };

    return (
        <div>
            {editMode ? (
                <div>
                    <div className="edit-company-container">
                        <Dropzone
                            text={<FormattedMessage id="editcompany.dragndrop" defaultMessage="Drag 'n' drop project picture, or click to select it" />}
                            imagePlaceholder={defaultPicture}
                            crop={crop}
                            setCrop={(newCrop) => setCrop(newCrop)}
                            onDrop={() => setImageDropped(true)}
                            refreshThumb={refreshThumb}
                            clearRefreshThumb={() => setRefreshThumb(false)}
                            image={user.companyId}
                        />
                        <div className="edit-company-details">
                            <h4>Edit company info</h4>
                            <Form>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.name" defaultMessage="Company name:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyName"
                                        id="companyName"
                                        placeholder=""
                                        value={company.name}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Address" defaultMessage="Address:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyAdress"
                                        id="companyAdress"
                                        placeholder=""
                                        value={company.address}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Postal" defaultMessage="Postal code:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyPostalCode"
                                        id="companyPostalCode"
                                        placeholder=""
                                        value={company.postalCode}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                postalCode: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.City" defaultMessage="City:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyCity"
                                        id="companyCity"
                                        placeholder=""
                                        value={company.city}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                city: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Country" defaultMessage="Country:" /></Label>
                                    <Input
                                        type="select"
                                        name="country"
                                        id="country"
                                        value={company.country}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                country: e.target.value,
                                            })
                                        }
                                    >
                                        {Object.entries(countriesData).map(
                                            (item) => (
                                                <option
                                                    key={item[0]}
                                                    value={item[0]}
                                                >
                                                    {item[1]}
                                                </option>
                                            )
                                        )}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Email" defaultMessage="Email:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyEmail"
                                        id="companyEmail"
                                        placeholder=""
                                        value={company.email}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Telephone" defaultMessage="Telephone:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyTelephone"
                                        id="companyTelephone"
                                        placeholder=""
                                        value={company.telephone}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                telephone: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.mobile" defaultMessage="Mobile phone:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyMobilePhone"
                                        id="companyMobilePhone"
                                        placeholder=""
                                        value={company.mobilePhone}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                mobilePhone: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Fax" defaultMessage="Fax:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyFax"
                                        id="companyFax"
                                        placeholder=""
                                        value={company.fax}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                fax: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.Website" defaultMessage="Website:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyWebsite"
                                        id="companyWebsite"
                                        placeholder=""
                                        value={company.website}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                website: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.vat" defaultMessage="VAT:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyVat"
                                        id="companyVat"
                                        placeholder=""
                                        value={company.vat}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                vat: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label><FormattedMessage id="editcompany.IBAN" defaultMessage="IBAN:" /></Label>
                                    <Input
                                        type="text"
                                        name="companyIban"
                                        id="companyIban"
                                        placeholder=""
                                        value={company.iban}
                                        onChange={(e) =>
                                            setCompany({
                                                ...company,
                                                iban: e.target.value,
                                            })
                                        }
                                    />
                                </FormGroup>
                            </Form>
                        </div>
                        <div className="edit-company-employees">
                            <h4><FormattedMessage id="editcompany.edit" defaultMessage="Edit company employees" /></h4>
                            <div className="company-users-container">
                                {employees.map((item) => (
                                    <div
                                        style={{ marginRight: 35 }}
                                        key={item.guid}
                                    >
                                        <CompanyUserItem
                                            {...item}
                                            editMode={true}
                                            handleRemoveUser={() =>
                                                handleRemoveEmployee(item.guid)
                                            }
                                        />
                                        <br />
                                    </div>
                                ))}
                            </div>
                            <div className="add-employye-button-container tooltips">
                                <Button
                                    onClick={handleAddEmployeeClick}
                                    disabled={!user || !user.hasFullAccess}
                                >
                                    <FormattedMessage id="editcompany.addemployee" defaultMessage="Add employee" />

                                </Button>
                                {(!user || !user.hasFullAccess) && (
                                    <Tooltip
                                        text="Full access required in order to add users"
                                        placement="right"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="edit-company-employees">
                            <h4><FormattedMessage id="editcompany.editcompanyasso" defaultMessage="Edit company associates" /></h4>
                            {associates.map((associate) => (
                                <div
                                    key={associate.fullName}
                                    style={{ display: "flex" }}
                                >
                                    <p>
                                        {associate.fullName},{" "}
                                        <em>{associate.title}</em> -{" "}
                                        {associate.role
                                            .replace(/([A-Z])/g, " $1")
                                            .trim()}
                                    </p>
                                    <Delete
                                        style={{ marginLeft: "5px" }}
                                        onClick={() =>
                                            handleDeleteAssociate(
                                                associate.guid
                                            )
                                        }
                                    />
                                </div>
                            ))}
                            <Button
                                onClick={() => setAssociatesModalOpen(true)}
                                disabled={!user || !user.hasFullAccess}
                            >
                                <FormattedMessage id="editcompany.addasso" defaultMessage="Add associate" />

                            </Button>
                        </div>
                    </div>
                    <div
                        className="edit-company-buttons"
                        style={{ textAlign: "center" }}
                    >
                        <Button
                            onClick={() => setEditMode(false)}
                            style={{ marginRight: "30px" }}
                        >
                            <FormattedMessage id="editcompany.Back" defaultMessage="Back" />

                        </Button>
                        <Button
                            onClick={handleUpdate}
                            style={{ marginRight: "30px" }}
                        >
                            <FormattedMessage id="editcompany.updateasso" defaultMessage="Update" />

                        </Button>
                    </div>
                </div>
            ) : loading ? (
                <Loading />
            ) : (
                <CompanyDetails
                    imageId={user.companyId}
                    placeholder={defaultPicture}
                    hasFullAccess={user.hasFullAccess}
                    company={company}
                    employees={employees}
                    associates={associates}
                />
            )}

            {!editMode && user.role === "CompanyAdmin" ? (
                <div className="buttons-container" style={{ width: "100%" }}>
                    <Button onClick={() => setEditMode(true)}>Edit</Button>
                </div>
            ) : null}

            <Modal
                isOpen={employeesModalOpen}
                modalTitle={<FormattedMessage id="editcompany.addemployee1" defaultMessage="Add an employee to your company" />}
                closeModal={() => setEmployeesModalOpen(false)}
            >
                <Form>
                    <FormGroup>
                        <p><FormattedMessage id="editcompany.empemail" defaultMessage="Enter the email of the employee:" /></p>
                        <Input
                            type="text"
                            name="employeeEmail"
                            id="employeeEmail"
                            placeholder=""
                            value={employeeEmail}
                            onChange={(e) => setEmployeeEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            type="checkbox"
                            name="addToAllProjects"
                            id="addToAllProjects"
                            checked={addToAllProjects}
                            readOnly={true}
                            onChange={() =>
                                setAddToAllProjects(!addToAllProjects)
                            }
                        />{" "}
                        <p style={{ marginBottom: 0 }}>
                            <FormattedMessage id="editcompany.addempproject" defaultMessage="Add employee to all existing projects" />

                        </p>
                    </FormGroup>
                    <InfoBox content="You can add users to individual projects later via the projects edit menu" />
                    <p hidden={!errorVisible} className="error-message">
                        <FormattedMessage id="editcompany.empnotfound" defaultMessage="Employee not found or already added!" />
                    </p>
                    <div className="buttons-container">
                        <Button onClick={handleAddEmployee}><FormattedMessage id="editcompany.Add" defaultMessage="Add" /></Button>
                        <Button onClick={() => setEmployeesModalOpen(false)}>
                            <FormattedMessage id="editcompany.Cancel" defaultMessage="Cancel" />

                        </Button>
                    </div>
                </Form>
            </Modal>

            <CompanyAssociateForm
                modalOpen={associatesModalOpen}
                handleCloseModal={() => setAssociatesModalOpen(false)}
                handleAddAssociate={handleAddAssociate}
            />
        </div>
    );
};

export default EditCompany;
